import { reactive } from "vue"
import { API_ADS } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import notifyResponseError from "@/utils/notifyResponseError"
import Cookies from "js-cookie"

export const taskIntervals = reactive({})

const getFileChangeResult = async ({ url }) => {
  try {
    window.open(url, "_blank")

    notification.success({ message: i18n.t("massiveChangeWithFileSuccess") })
  } catch {
    notification.error({
      message: i18n.t("fileLoadingError")
    })
  }
}

export const fetchMerchantData = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `v1/admin/offers/stats/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    {
      signal
    }
  )
}

export const fetchSellerById = (sellerId) => {
  return API_ADS.get(`v1/admin/sellers/${sellerId}/`)
}

export const bulkChangeMerchantOffers = (payload) => {
  return API_ADS.patch("v1/admin/offers/bulk/", payload)
}

export const searchCustomLabel = ({ params, signal }) => {
  return API_ADS.get(`v1/admin/labels/?q=${params.search}`, {
    signal
  })
}

export const uploadGoogleLabelsFile = (data) => {
  return API_ADS.post("v1/admin/offers/imports/ga-custom-label/", data)
}

export const uploadFacebookLabelsFile = (data) => {
  return API_ADS.post("v1/admin/offers/imports/fb-custom-label/", data)
}

export const uploadFacebookActivityFile = (formData) => {
  return API_ADS.post("v1/admin/offers/imports/facebook-marks/", formData)
}

export const uploadEkatalogActivityFile = (formData) => {
  return API_ADS.post("v1/admin/offers/imports/ekatalog-marks/", formData)
}

export const checkFileChangeTask = async (task_id) => {
  taskIntervals[task_id] = setInterval(async () => {
    try {
      const { data } = await API_ADS.get(`v1/admin/offers/imports/results/${task_id}/`)

      if (data.state === "SUCCESS") {
        getFileChangeResult(data)
        clearInterval(taskIntervals[task_id])
        taskIntervals[task_id] = undefined
      }
    } catch (error) {
      clearInterval(taskIntervals[task_id])
      notifyResponseError({ error })
    }
  }, 5000)
}

export const checkSessionCookieExpired = () => !Boolean(Cookies.get("sessionid")) // If we will need to use it with API_V2 instance, we will to check refresh_token_mpa
