<template>
  <div
    ref="messagesRef"
    style="max-height: 80vh; overflow: auto"
  >
    <a-list>
      <a-list-item>
        <a-list-item-meta
          :description="$t('lastOption')"
          :title="props.message.body"
        />
      </a-list-item>
    </a-list>

    <a-list
      :dataSource="editHistory"
      :loading="isFetching"
    >
      <template #renderItem="item">
        <a-list-item>
          <a-list-item-meta
            :description="getDynamicDateFormat(item.edited_at)"
            :title="item.body"
          />
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, shallowRef } from "vue"

import { getMessageHistory } from "@/modules/Messenger/messengerService.js"
import { getDynamicDateFormat } from "@/utils/getFormatedDateTime.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  message: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["close", "getNextPage"])

const messagesRef = ref()
const onScrollBottom = () => {
  if (isFetching.value) return
  if (historyCount.value && editHistory.value.length >= historyCount.value) return
  const { scrollTop, scrollHeight, clientHeight } = messagesRef.value
  const isNotScrolled = scrollTop === 0
  const scrolledToBottom = scrollTop >= scrollHeight - clientHeight - 50

  if (isNotScrolled || !scrolledToBottom) return
  getEditHistoryNextPage()
}

const isFetching = shallowRef(false)
const editHistory = ref([])
const historyCount = ref()

const getEditHistoryNextPage = async () => {
  if (!props.message.uuid) return

  try {
    isFetching.value = true

    const { data } = await getMessageHistory({
      messageUuid: props.message.uuid,
      limit: 15,
      offset: editHistory.value.length
    })

    editHistory.value.push(...data.results)
    historyCount.value = data.count
  } catch (error) {
    notifyResponseError(error)
  } finally {
    isFetching.value = false
  }
}

onMounted(() => {
  messagesRef.value.addEventListener("scroll", onScrollBottom, false)

  getEditHistoryNextPage()
})

onBeforeUnmount(() => {
  messagesRef.value?.removeEventListener("scroll", onScrollBottom, false)
})
</script>
