import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const fetchSellerReviews = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/sellers-reviews${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchSellerReviewById = (id) => {
  return API.get(`/mp-admin/sellers-reviews/${id}`)
}

export const fetchSellerReviewsMeta = () => {
  return API.get("/mp-admin/sellers-reviews/meta")
}

export const updateSellerReview = (data, id) => {
  return API.patch(`/mp-admin/sellers-reviews/${id}`, data)
}
