import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const fetchBuyers = ({ queryParams, requestParams, signal }) => {
  const params = getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery).replace(
    "is_confirmed_email=false",
    "has_email=true"
  )

  return API.get(`/mp-admin/customers${params}`, { signal })
}

export const updateBuyer = (id, payload) => {
  return API.patch(`/mp-admin/customers/${id}`, payload)
}
