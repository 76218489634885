<template>
  <div>
    <a-alert
      type="info"
      showIcon
    >
      <template #message>
        {{ $t("allowedFormats") }}: {{ allowedFormatsString }}. {{ $t("maxSize30") }}
      </template>
    </a-alert>

    <a-tabs v-model="activeLanguage">
      <a-tab-pane
        v-for="lang in allowed_locales"
        :key="lang"
        :tab="lang.toUpperCase()"
      >
        <div
          v-for="device in allowed_devices"
          class="device"
          :key="device"
        >
          <div class="device__title">
            {{ device }}
          </div>

          <div
            class="device__item"
            v-for="size in allowed_image_sizes"
            :key="size"
            :bordered="false"
          >
            <SingleImageLoader
              :image="{
                url:
                  prototype &&
                  prototype?.images_by_lang &&
                  prototype?.images_by_lang[lang][device][size]
                    ? prototype.images_by_lang[lang][device][size]
                    : null
              }"
              :responsive="true"
              :allowedFormats="allowedFormats"
              :parseCDN="true"
              :options="{
                path: `images_by_lang.${lang}.${device}.${size}`,
                lang,
                device,
                size
              }"
              :uploadPromise="uploadPromise"
              :removingPromise="removingPromise"
            />
            <a-card-meta class="device__item__info">
              <template #description>
                {{ size }}
                <template v-if="device === 'computer'">
                  {{ size === "1x" ? "(1220x435px)" : "(2440x870px)" }}
                </template>
              </template>
            </a-card-meta>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import SingleImageLoader from "@/components/SingleImageLoader/index.vue"

import {
  fetchPromotionsActionsData,
  updatePromotionImage
} from "@/modules/MPAdmin/services/mainPromotionsService.js"

import usePromotionsConstants from "../usePromotionsConstants"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["updateImages"])

const { allowedFormats, allowedFormatsString } = usePromotionsConstants()

const activeLanguage = ref("uk")

const allowed_locales = ref([])
const allowed_image_sizes = ref([])
const allowed_devices = ref([])

const removingPromise = ({ options }) => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData()

    formData.append(options.path, "")

    try {
      const { data } = await updatePromotionImage(formData, props.prototype.id)

      emit("updateImages", data)
      resolve()
      notification.success({ message: i18n.t("imageDeleted") })
    } catch (error) {
      reject(error)
      notifyResponseError({ error })
    }
  })
}

const uploadPromise = ({ target, options }) => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData()

    formData.append(options.path, target)

    try {
      const { data } = await updatePromotionImage(formData, props.prototype.id)

      emit("updateImages", data)
      resolve()

      notification.success({ message: i18n.t("imageUploaded") })
    } catch (error) {
      reject(error)
      notifyResponseError({ error })
    }
  })
}

const setupImages = async () => {
  const { data: metaData } = await fetchPromotionsActionsData()

  allowed_locales.value = metaData.allowed_locales
  allowed_image_sizes.value = metaData.allowed_image_sizes
  allowed_devices.value = metaData.allowed_devices
  activeLanguage.value = metaData.allowed_locales[0]
}

onMounted(setupImages)
</script>

<style lang="scss" scoped>
.device {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &__title {
    width: 100%;

    font-size: 18px;
    line-height: 36px;
    color: $font-default;
    text-transform: uppercase;
  }

  &__item {
    width: 48%;
    height: 240px;
    padding-bottom: 24px;
    margin-bottom: 16px;

    &__info {
      margin-top: 4px;
      font-size: 12px;
    }
  }
}
</style>
