import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const fetchPages = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/pages${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const updatePage = ({ payload, id }) => {
  return API.put(`/mp-admin/pages/${id}`, payload)
}

export const createPage = ({ payload }) => {
  return API.post(`/mp-admin/pages`, payload)
}

export const fetchPagesActionsData = () => {
  return API.get("/mp-admin/pages/actions-data")
}
export const uploadPageImages = (fd) => {
  return API.post("/mp-admin/pages/images", fd)
}
export const fetchPageCategoryItems = () => {
  return API.get("/mp-admin/page-categories")
}
export const createPageCategoryItem = (payload) => {
  return API.post("/mp-admin/page-categories", payload)
}
export const deletePageCategoryItem = (id) => {
  return API.delete(`/mp-admin/page-categories/${id}`)
}
export const updatePageCategoryItem = (data, id) => {
  return API.patch(`/mp-admin/page-categories/${id}`, data)
}
