import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const fetchReturnReasons = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/return-reasons/${getFormatedQueryParams(queryParams)}`, { signal })
}

export const createReturnReason = (data) => {
  return API.post("/mp-admin/return-reasons/", data)
}

export const updateReturnReason = (data, uuid) => {
  return API.put(`/mp-admin/return-reasons/${uuid}`, data)
}

export const deleteReturnReason = (uuid) => {
  return API.delete(`/mp-admin/return-reasons/${uuid}`)
}
