import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { moderationPrefix, getLocalizedHeaders } from "./servicesUtils.js"
import { API_V2 } from "@/utils/HttpUtils"

export const fetchProductBrands = ({ queryParams, requestParams }) => {
  return API_V2.get(
    `${moderationPrefix}/brands/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    getLocalizedHeaders(requestParams?.language?.value)
  )
}

export const fetchBrand = ({ code, language }) => {
  return API_V2.get(`${moderationPrefix}/brands/${code}/`, getLocalizedHeaders(language))
}

export const fetchLocalizedBrand = async ({ code }) => {
  try {
    const ruResponse = await fetchBrand({ code, language: "ru" })
    const ukResponse = await fetchBrand({ code, language: "uk" })
    const enResponse = await fetchBrand({ code, language: "en" })

    const data = {
      ...ruResponse.data,
      name: {
        ru: ruResponse.data.name,
        uk: ukResponse.data.name,
        en: enResponse.data.name
      }
    }

    return Promise.resolve({ data })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createBrand = (brand) => {
  return API_V2.post(`${moderationPrefix}/brands/`, brand)
}

export const updateBrand = ({ payload, code, language }) => {
  return API_V2.put(`${moderationPrefix}/brands/${code}/`, payload, getLocalizedHeaders(language))
}

export const createLocalizeBrand = async ({ payload }) => {
  try {
    const ruResponse = await createBrand({
      name: payload.name.ru,
      trust: payload.trust
    })
    const ukResponse = await updateBrand({
      payload: { name: payload.name.uk },
      code: ruResponse.data.code,
      language: "uk"
    })
    const enResponse = await updateBrand({
      payload: { name: payload.name.en },
      code: ruResponse.data.code,
      language: "en"
    })

    const { data } = ruResponse

    data.name = {
      ru: data.name,
      uk: ukResponse.data.name,
      en: enResponse.data.name
    }

    return Promise.resolve({ data })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateLocalizedBrand = async ({ payload, code }) => {
  try {
    const ruResponse = await updateBrand({
      payload: {
        name: payload.name.ru,
        trust: payload.trust
      },
      code,
      language: "ru"
    })
    await updateBrand({ payload: { name: payload.name.uk }, code, language: "uk" })
    await updateBrand({ payload: { name: payload.name.en }, code, language: "en" })

    return Promise.resolve(ruResponse)
  } catch (error) {
    return Promise.reject(error)
  }
}
