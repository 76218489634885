import { computed, ref, shallowRef } from "vue"
import router from "@/router/index.js"
import buildTableQueryParams from "@/utils/buildTableQueryParams"
import notifyResponseError from "@/utils/notifyResponseError"
import useCancellableRequest from "./useCancellableRequest"

export default ({ queryFunction, requestParams = {} }) => {
  const { makeRequest, cancelRequest } = useCancellableRequest(queryFunction)

  // Table data
  const tableData = ref([])
  const dataSource = computed(() => tableData.value)
  const setTableData = (newData) => {
    tableData.value.length = 0
    tableData.value.push(...newData)
    onEndFetching()
  }
  const updateTableDataRecord = ({ payload, identifier }) => {
    tableData.value = tableData.value.map((item) => {
      if (item[identifier] !== payload[identifier]) return item
      return { ...item, ...payload }
    })
  }

  // Loading state
  const isFetching = shallowRef(false)
  const onStartFetching = () => {
    isFetching.value = true
  }
  const onEndFetching = () => {
    isFetching.value = false
  }

  // Filters
  const filteredInfo = ref({})
  const setFilteredInfo = (value) => {
    const filters = {}

    Object.keys(value).forEach((key) => {
      if (value[key] === undefined) return
      filters[key] = value[key]
    })

    filteredInfo.value = filters
  }

  const handleTableFilterChange = () => {
    setPaginationInfo({ ...paginationInfo.value, current: 1 })
    fetchTableInfo({})
  }

  const handleTableFiltersReset = (resetTableData = false) => {
    setFilteredInfo({})

    if (!resetTableData) {
      setPaginationInfo({ ...paginationInfo.value, current: 1 })
      return fetchTableInfo({})
    }

    setPaginationInfo({ ...paginationInfo.value, current: 1, total: 0 })
    cancelRequest()
    setTableData([])

    if (!Object.keys(router.currentRoute.query).length) return
    router.push({ query: {} })
  }

  // Pagination
  const paginationInfo = ref({
    current: 1,
    total: 0,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: false,
    pageSizeOptions: ["10", "25", "50", "100"]
  })
  const setPaginationInfo = (value) => {
    paginationInfo.value = value

    const tableHeadEl = document.getElementsByClassName("ant-table-thead")[0]

    if (tableHeadEl) {
      const tableHeadPosition = tableHeadEl.getBoundingClientRect()

      if (tableHeadPosition.top < 0) {
        tableHeadEl.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  // Sorting
  const sortedInfo = ref({})
  const sorterKey = shallowRef("ordering")
  const setSortedInfo = (value) => {
    sortedInfo.value = value
  }

  // Set up (use if using router or default params)
  const setupTable = (defaultParams = {}) => {
    const { ordering, order_by, page, pageSize, ...filters } = router.currentRoute.query
    const {
      defaultPagination = {},
      defaultFilters = {},
      defaultSorter = {},
      sortKey
    } = defaultParams

    // Set sort key that uses in request (ordering/order_by)
    if (sorterKey) {
      sorterKey.value = sortKey
    }

    // Set sorter
    const newSortedInfo = { ...defaultSorter }

    if (ordering) {
      newSortedInfo.columnKey = ordering.indexOf("-") === 0 ? ordering.replace("-", "") : ordering
      newSortedInfo.order = ordering.indexOf("-") === 0 ? "ascend" : "descend"
    } else if (order_by) {
      newSortedInfo.columnKey = order_by.indexOf("-") === 0 ? order_by.replace("-", "") : order_by
      newSortedInfo.order = order_by.indexOf("-") === 0 ? "ascend" : "descend"
    }

    setSortedInfo(newSortedInfo)

    // Add default filters
    const correctFilters = { ...defaultFilters }

    Object.keys(filters).forEach((filterKey) => {
      if (
        filterKey.endsWith("_at_max") ||
        filterKey.endsWith("_to") ||
        filterKey.endsWith("_at__max")
      ) {
        return // ignore duplicate keys
      }
      if (filterKey.endsWith("_at_min")) {
        correctFilters[filterKey.replace("_min", "")] = [
          filters[filterKey],
          filters[filterKey.replace("_min", "_max")]
        ]
      } else if (filterKey.endsWith("_from")) {
        correctFilters[filterKey.replace("_from", "_range")] = [
          filters[filterKey],
          filters[filterKey.replace("_from", "_to")]
        ]
      } else if (filterKey.endsWith("_at__min")) {
        correctFilters[filterKey.replace("__min", "__interval")] = [
          filters[filterKey],
          filters[filterKey.replace("__min", "__max")]
        ]
      } else if (filters[filterKey].includes(",")) {
        correctFilters[filterKey] = filters[filterKey].split(",")
      } else {
        correctFilters[filterKey] = filters[filterKey]
      }
    })

    // Set added filters
    setFilteredInfo(correctFilters)

    // Set pagination
    setPaginationInfo({
      ...paginationInfo.value,
      ...defaultPagination,
      current: page ? +page : 1
    })

    if (pageSize) {
      setPaginationInfo({
        ...paginationInfo.value,
        pageSize: +pageSize
      })
    }
  }

  // Fetch table data
  const fetchTableInfo = async ({
    pagination = paginationInfo.value,
    filters = filteredInfo.value,
    sorter = sortedInfo.value
  }) => {
    try {
      onStartFetching()

      const queryParams = buildTableQueryParams({
        pagination,
        filters,
        sorter,
        sorterKey: sorterKey.value
      })

      const { data } = await makeRequest({ queryParams, requestParams })

      setTableData(data.results)
      setPaginationInfo({ ...pagination, total: data.count })
      setSortedInfo(sorter)
    } catch (error) {
      if (error.message === "canceled") return // ignore axios abortController

      notifyResponseError({ error })
      onEndFetching()
    }
  }

  return {
    dataSource,
    isFetching,
    paginationInfo,
    filteredInfo,
    sortedInfo,

    setPaginationInfo,
    setFilteredInfo,
    setSortedInfo,
    setupTable,
    setTableData,
    fetchTableInfo,
    handleTableFilterChange,
    handleTableFiltersReset,
    updateTableDataRecord,
    notifyResponseError
  }
}
