<template>
  <div class="chosen-tariff-info">
    <a-form-model-item :label="$t('newTariff')">
      <InfiniteScrollSelect
        ref="tarifSelectRef"
        :value="selectedNewTariff?.id"
        :customRequest="fetchTariffs"
        valueKey="id"
        labelKey="title"
        onFocusFetch
        :showSearch="false"
        style="width: 100%"
        @change="handleSelectNewTariff"
      >
        <template #option="{ option }">{{ option.title.ru }}</template>
      </InfiniteScrollSelect>
    </a-form-model-item>
    <a-form-model-item :label="$t('validity')">
      <a-select
        style="width: 100%"
        :value="selectedTariffConfig?.id"
        @change="handleSelectTariffConfig"
      >
        <a-select-option
          v-for="value in tariffConfigValues"
          :key="value.id"
          :value="value.id"
        >
          {{ value.expire_in_days }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <div
      v-if="
        selectedTariffConfig &&
        selectedTariffConfig.price === 0 &&
        !isTariffExpired &&
        tariff_config.price !== 0
      "
      class="alert"
    >
      <span>{{ $t("chosingTariffError") }} {{ getFormatedDateTime(tariff_expire_at) }}</span>
    </div>
    <template v-else-if="selectedTariffConfig && selectedTariffConfig.price === 0">
      <span>{{ $t("expirationDate") }}</span>

      <a-date-picker
        show-time
        v-model="dateFinish"
        format="DD.MM.YYYY"
        :disabled-date="disabledDate"
      />
    </template>
    <template v-else-if="selectedTariffConfig">
      <a-descriptions
        bordered
        :column="1"
        size="small"
      >
        <a-descriptions-item :label="$t('tariffPrice')">
          {{ selectedTariffConfig?.price ? selectedTariffConfig.price : 0 }}
          {{ $t("uah") }}
        </a-descriptions-item>

        <a-descriptions-item :label="`${$t('currentBalance')} ${$t('ofSeller')}`">
          {{ bill?.balance || 0 }}
          {{ $t("uah") }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('returnOfBalance')">
          {{ tariff_rest || 0 }} {{ $t("uah") }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('saldoAfterOperation')">
          <span :class="{ alert: saldo < 0 }">{{ saldo ? saldo : 0 }} {{ $t("uah") }}</span>
        </a-descriptions-item>
      </a-descriptions>

      <a-button
        type="primary"
        style="margin-left: auto; width: fit-content"
        @click="openConfirmChange"
        :disabled="saldo < 0 || !selectedTariffConfig || !selectedTariffConfig.price"
      >
        {{ $t("change") }}
      </a-button>
    </template>
  </div>
</template>

<script setup>
import { computed, ref } from "vue"
import { Modal, notification } from "ant-design-vue"
import moment from "moment"
import i18n from "@/i18n"

import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"
import { sellerChangeTariff, fetchTariffs } from "@/modules/MPAdmin/services/billingService.js"

import { getFormatedDateTime } from "@/utils/getFormatedDateTime.js"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  sellerUuid: {
    type: String,
    required: true
  },
  bill: {
    type: Number | String,
    default: 0
  },
  tariff_config: {
    type: Number | String,
    default: 0
  },
  tariff_rest: {
    type: Number | String,
    default: 0
  },
  tariff_expire_at: {
    type: Number | String,
    default: 0
  }
})
const emit = defineEmits(["handleUpdateBillingInfo"])

const tarifSelectRef = ref()

const selectedNewTariff = ref()
const selectedTariffConfig = ref()
const dateFinish = ref()

const isTariffExpired = computed(() => {
  const today = new Date().getTime()
  const tariffExpire = new Date(props.tariff_expire_at).getTime()

  return today > tariffExpire
})

const tariffConfigValues = computed(() => {
  if (!selectedNewTariff.value?.configs) return []
  return selectedNewTariff.value.configs.filter((item) => item.is_active)
})

const saldo = computed(() => {
  if (!selectedTariffConfig.value) return 0
  return props.bill?.balance + props.tariff_rest - selectedTariffConfig.value?.price
})

const openConfirmChange = async () => {
  Modal.confirm({
    content: `${i18n.t("questionToChangeTariff")} ${selectedNewTariff.value?.title?.ru} / ${
      selectedTariffConfig.value?.expire_in_days || 0
    } ${i18n.t("dayShorted")} ${i18n.t("costOf")} ${selectedTariffConfig.value.price} ${i18n.t(
      "uah"
    )}?`,
    onOk: handleChangeTariff
  })
}

const handleChangeTariff = async () => {
  if (!selectedTariffConfig.value.is_active) {
    return notification.error({ message: i18n.t("tariffConfigNotActive") })
  }

  const sendObj = {
    new_tariff_config: selectedTariffConfig.value.id
  }
  if (dateFinish.value) {
    sendObj.expired_at_custom = dateFinish.value.toISOString()
  }

  try {
    await sellerChangeTariff(props.sellerUuid, sendObj)

    handleResetForm()
    notification.success({ message: i18n.t("success") })
    emit("handleUpdateBillingInfo")
  } catch (error) {
    notifyResponseError({ error })
  }
}
const handleResetForm = () => {
  handleSelectNewTariff(undefined)
}

const handleSelectNewTariff = (value) => {
  selectedTariffConfig.value = undefined
  if (value) {
    selectedNewTariff.value = tarifSelectRef.value?.selectOptions?.find((item) => item.id === value)
  } else {
    selectedNewTariff.value = undefined
  }
}

const handleSelectTariffConfig = (value) => {
  selectedTariffConfig.value = selectedNewTariff.value?.configs?.find((item) => item.id === value)

  const today = new Date()

  dateFinish.value = value.price === 0 ? today.setDate(today.getDate() + value.expire_in_days) : ""
  value.price === 0 ? (dateFinish.value = new Date(dateFinish.value)) : ""
}

const disabledDate = (current) => {
  return current && current < moment().endOf("day")
}
</script>

<style lang="scss">
.alert {
  color: $red-color;
}
.chosen-tariff-info {
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  font-size: 14px;
  padding-left: 32px;

  & label {
    line-height: 30px;
  }
  & > * {
    width: 33%;
  }
  & > :nth-child(3) {
    width: 100%;
  }
}
</style>
