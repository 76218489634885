import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const fetchPropositions = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/propositions${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchPropositionsMeta = () => {
  return API.get(`/mp-admin/propositions/meta`)
}

export const fetchPropositionsActionsData = () => {
  return API.get("/mp-admin/propositions/meta")
}

export const updateProposition = (data, id) => {
  return API.patch(`/mp-admin/propositions/${id}`, data)
}
export const getDeliveryPolitics = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-seller/delivery-politics/${requestParams.id.value}${getFormatedQueryParams(queryParams)}`,
    { signal }
  )
}
