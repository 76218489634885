export default (params) => {
  const query = Object.entries(params)
    .filter(([_, value]) =>
      Array.isArray(value) ? value.length > 0 : value !== null && value !== ""
    )
    .map(([key, value]) => `${key}=${value}`)
    .join("&")

  return query ? `?${query}` : ""
}
