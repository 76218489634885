import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const columns = computed(() => [
    {
      key: "code",
      title: "MPC-code",
      dataIndex: "code",
      width: 170,
      fixed: "left"
    },
    {
      key: "p_code",
      title: "P-code",
      dataIndex: "p_code",
      width: 170,
      fixed: "left"
    },
    {
      key: "public_title",
      title: i18n.t("titleForPublication"),
      width: 180,
      dataIndex: "public_title",
      ellipsis: true
    },
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("photo"),
      scopedSlots: {
        customRender: "photoRenderer"
      },
      fixed: "right",
      width: 80
    },
    {
      key: "status",
      title: i18n.t("status"),
      dataIndex: "status",
      width: 160,
      fixed: "right"
    },
    {
      key: "actions",
      width: 65,
      fixed: "right",
      scopedSlots: { customRender: "actionRenderer" }
    }
  ])

  return columns
}
