import { API } from "@/utils/HttpUtils"
import { mpaPrefixLegacy } from "./servicesUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"

export const fetchOrders = ({ queryParams, requestParams, signal }) => {
  queryParams.in_cart = false

  return API.get(
    `${mpaPrefixLegacy}/orders${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchOrdersMeta = () => {
  return API.get(`${mpaPrefixLegacy}/orders/meta`)
}

export const fetchOrderById = (orderCode) => {
  return API.get(`${mpaPrefixLegacy}/orders/${orderCode}`)
}

export const updateOrder = ({ code, payload }) => {
  return API.patch(`${mpaPrefixLegacy}/orders/${code}`, payload)
}

export const fetchOrderHistory = (orderCode) => {
  return API.get(`${mpaPrefixLegacy}/orders/${orderCode}/history`)
}

export const fetchOrderNotes = (orderCode) => {
  return API.get(`${mpaPrefixLegacy}/orders/${orderCode}/notes`)
}

export const fetchOrderReturns = (orderCode) => {
  return API.get(`${mpaPrefixLegacy}/orders/${orderCode}/returns`)
}

export const fetchOrderTransactions = (orderCode) => {
  return API.get(`${mpaPrefixLegacy}/orders/${orderCode}/transactions`)
}
