<template>
  <div>
    <PageHeader :title="$t('exportPageTitle')">
      <template #filters>
        <a-input-search
          v-model="filteredInfo.code"
          placeholder="MPC-code"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.p_code"
          placeholder="P-code"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.meta_xml_seller_slug"
          :placeholder="$t('exportSellerSlug')"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <InfiniteScrollSelect
          :value="filteredInfo.type"
          :placeholder="$t('productType')"
          :customRequest="fetchProductTypes"
          labelKey="full_name"
          onFocusFetch
          @change="onTypeChange"
        />

        <InfiniteScrollSelect
          :value="filteredInfo.brand"
          :placeholder="$t('brand')"
          :customRequest="fetchProductBrands"
          onFocusFetch
          @change="onBrandChange"
        />

        <a-input-search
          v-model="filteredInfo.public_title"
          :placeholder="$t('nameForPublish')"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-form-item :help="$t('creatingDate')">
          <a-range-picker
            v-model="filteredInfo.created_at"
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            allowClear
            @change="handleTableFilterChange"
          />
        </a-form-item>

        <a-select
          :placeholder="$t('exportProductStatus')"
          v-model="filteredInfo.status"
          mode="multiple"
          allowClear
          style="margin-top: 1px"
          @change="handleTableFilterChange"
        >
          <a-select-option key="VERIFIED"> VERIFIED </a-select-option>
          <a-select-option key="TEMP"> TEMP </a-select-option>
          <a-select-option key="TRANSFERRED"> TRANSFERRED </a-select-option>
        </a-select>
      </template>

      <template #actions>
        <a-form-item>
          <a-button
            icon="undo"
            @click="handleTableFiltersReset()"
          >
            {{ $t("clearFilters") }}
          </a-button>
        </a-form-item>

        <a-form-item>
          <a-popconfirm
            :title="$t('exportStartConfirm')"
            :okText="$t('yes')"
            :cancelText="$t('no')"
            placement="topRight"
            @confirm="handleExportConfirm"
          >
            <a-button
              type="primary"
              icon="play-circle"
              :disabled="!paginationInfo?.total"
              :loading="isExporting"
            >
              Export ({{ paginationInfo.total }})
            </a-button>
          </a-popconfirm>
        </a-form-item>
      </template>
    </PageHeader>

    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :loading="isFetching"
      :pagination="paginationInfo"
      rowKey="code"
      @change="onTableChange"
    >
      <template #dateRenderer="text">
        <TableRendererDateTime :date="text" />
      </template>

      <template #metaRenderer="meta">
        <div
          v-if="meta"
          v-for="item in meta?.xml"
          :key="item.xml_id"
        >
          {{ item.seller_slug }}
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import PageHeader from "@/ant-components/PageHeader"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

import useExportColumns from "./useExportColumns"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

import { exportProducts, fetchProductsForExport } from "../services/exportService"
import { fetchProductBrands, fetchProductTypes } from "../services/moderationProductsService"
import notifyResponseError from "@/utils/notifyResponseError"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  setFilteredInfo
} = useAntTableQuery({
  queryFunction: fetchProductsForExport,
  requestParams: { changeRouterQuery: true }
})
const { columns } = useExportColumns()

const isExporting = ref(false)

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const handleExportConfirm = async () => {
  try {
    isExporting.value = true

    await exportProducts({
      pagination: paginationInfo.value,
      filters: filteredInfo.value,
      sorter: sortedInfo.value
    })

    notification.success({ message: i18n.t("exportStartSuccess") })
  } catch (e) {
    notifyResponseError({ message: i18n.t("exportStartError") })
  } finally {
    isExporting.value = false
  }
}

const onTypeChange = (value) => {
  setFilteredInfo({ ...filteredInfo.value, type: value })
  handleTableFilterChange()
}

const onBrandChange = (value) => {
  setFilteredInfo({ ...filteredInfo.value, brand: value })
  handleTableFilterChange()
}

onMounted(() => {
  setupTable({
    sortKey: "order_by"
  })
  onTableChange()
})
</script>
