import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const getUsers = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/users-management/users${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const createUser = ({ email, username, password, passwordConfirm }) => {
  return API.post("/users-management/users", {
    structure_units: [],
    // Потрібно видалити поле structure_units у зв'язку з видаленням структур
    // наразі це поле обов'язове у запиті, тому залишається невидаленим
    is_active: true,
    roles: [],
    is_staff: true,
    email,
    username,
    password,
    confirm_password: passwordConfirm
  })
}

export const updateUser = ({ userId, userParams }) => {
  return API.put(`/users-management/users/${userId}`, userParams)
}
