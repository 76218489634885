<template>
  <a-form-model class="import-values">
    <a-button
      type="dashed"
      icon="download"
      block
      style="margin-bottom: 16px"
      :disabled="isFetching"
      @click="downloadExample"
    >
      {{ $t("downloadTagsImportExample") }}
    </a-button>

    <!-- <a-form-model-item label="RU"> -->
    <a-upload-dragger
      :beforeUpload="(e) => onChooseFile(e, 'ru')"
      accept="text/csv"
      :fileList="currentFiles.ru"
      :remove="() => (currentFiles = [])"
      :disabled="isFetching"
    >
      <div class="upload-field">
        <a-icon
          type="inbox"
          class="upload-field__icon"
        />

        {{ $t("chooseOrDragForUpload") }}
      </div>
    </a-upload-dragger>
    <!-- </a-form-model-item> -->

    <!-- <a-form-model-item label="UA">
      <a-upload-dragger
        :beforeUpload="(e) => onChooseFile(e, 'uk')"
        accept="text/csv"
        :fileList="currentFiles.uk"
        :remove="() => (currentFiles = [])"
        :disabled="isFetching"
      >
        <div class="upload-field">
          <a-icon
            type="inbox"
            class="upload-field__icon"
          />

          {{ $t("chooseOrDragForUpload") }}
        </div>
      </a-upload-dragger>
    </a-form-model-item>

    <a-form-model-item label="EN">
      <a-upload-dragger
        :beforeUpload="(e) => onChooseFile(e, 'en')"
        accept="text/csv"
        :fileList="currentFiles.uk"
        :remove="() => (currentFiles = [])"
        :disabled="isFetching"
      >
        <div class="upload-field">
          <a-icon
            type="inbox"
            class="upload-field__icon"
          />

          {{ $t("chooseOrDragForUpload") }}
        </div>
      </a-upload-dragger>
    </a-form-model-item> -->

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="$emit('close')"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        :disabled="disableSubmit"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import notifyResponseError from "@/utils/notifyResponseError.js"
import { importLocalizedAttributeValues } from "../../../services/attributeValuesService.js"

const emit = defineEmits(["close"])

const currentFiles = reactive({
  ru: [],
  uk: [],
  en: []
})
const disableSubmit = computed(() => {
  if (currentFiles.ru.length) return false
  if (currentFiles.uk.length) return false
  if (currentFiles.en.length) return false
  return true
})

const onChooseFile = (file, locale = "ru") => {
  if (file.name.split(".")?.at(-1) !== "csv") {
    notification.error({ message: `${i18n.t("formatError")}: .csv` })
    return false
  }

  currentFiles[locale] = [file]
  return false
}

const isFetching = shallowRef(false)
const onSubmit = async () => {
  try {
    isFetching.value = true

    const payload = {
      uk: currentFiles.uk[0],
      ru: currentFiles.ru[0],
      en: currentFiles.en[0]
    }

    await importLocalizedAttributeValues({ payload })

    notification.info({ message: i18n.t("importStarted") })
    emit("close")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const downloadExample = () => {
  const content = [
    ["attribute", "value", "unit"],
    ["AND-00002", "1080x1920", ""],
    ["AND-00003", "120", "AUD-00003"]
  ]
    .map((item) => item.join(","))
    .join("\n")

  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" })
  const url = URL.createObjectURL(blob)

  const link = document.createElement("a")
  link.href = url
  link.download = "values-import-example.csv"
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
}
</script>

<style lang="scss" scoped>
.import-values {
  padding-bottom: 50px;
}
.upload-field {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 20px;

  font-size: 16px;

  &__icon {
    color: $primary-color;
    font-size: 36px;
  }
}
</style>
