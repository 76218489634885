import { API_MESSENGER, API_V2 } from "@/utils/HttpUtils"

export const getModerationToken = () => {
  return API_V2.get("/proxy/msg/support-auth")
}

export const getUnreadMessagesCount = () => {
  return API_MESSENGER.get(`/chats/messages/unread-count/?sender=moderator`)
}

export const globalChatSearch = (payload) => {
  return API_MESSENGER.get("admin/chats/", {
    params: { sender: "moderator", ...payload }
  })
}

export const getChatMessages = ({ chatUuid, limit, offset }) => {
  return API_MESSENGER.get(`admin/messages/`, {
    params: { sender: "moderator", limit, offset, chat: chatUuid }
  })
}

export const getMessageHistory = ({ messageUuid, limit, offset }) => {
  return API_MESSENGER.get(`admin/messages/${messageUuid}/history/`, {
    params: { sender: "moderator", limit, offset }
  })
}
