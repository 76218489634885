import { API } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"

export const fetchLocations = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/locations${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const fetchAllCountryLocations = () => {
  return API.get(`/mp-admin/locations?type=COUNTRY&limit=1&offset=0`) // fetch only Ukraine
}

export const fetchAllStateLocations = () => {
  return API.get(`/mp-admin/locations?type=STATE&limit=25&offset=0`) // set limit to ukrainian states total count
}

export const fetchAllDistrictLocations = () => {
  return API.get(`/mp-admin/locations?type=DISTRICT&limit=136&offset=0`) // set limit to ukrainian districts total count
}

export const fetchLocationById = (id) => {
  return API.get(`/mp-admin/locations/${id}`)
}

export const createLocation = (payload) => {
  return API.post("/mp-admin/locations", payload)
}

export const updateLocation = ({ uuid, payload }) => {
  return API.patch(`/mp-admin/locations/${uuid}`, payload)
}

export const fetchPostOffices = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `mp-admin/post-offices/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchPostOfficesMeta = async () => {
  const { data } = await API.get(`mp-admin/post-offices/?limit=1&offset=0`)

  return { data: data.meta }
}

export const createPostOffice = (payload) => {
  return API.post(`mp-admin/post-offices/`, payload)
}

export const updatePostOffice = ({ uuid, payload }) => {
  return API.patch(`mp-admin/post-offices/${uuid}`, payload)
}
