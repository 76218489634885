<template>
  <router-view />
</template>

<script>
export default {
  name: "Moderation"
}
</script>

<style lang="scss">
.TRUSTED {
  color: $green-color;
  border-color: $green-color;
}

.UNTRUSTED {
  color: $dark-yellow-color;
  border-color: $dark-yellow-color;
}
</style>
