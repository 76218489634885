import { API } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"

export const fetchBillingTariffs = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/billing/tariffs/${getFormatedQueryParams(queryParams)}`, { signal })
}

export const addTariffConfigs = (id, data) => {
  return API.post(`/mp-admin/billing/tariffs/${id}/configs`, data)
}

export const updateTariffConfig = async (id, idConfig, data) => {
  return await API.put(`/mp-admin/billing/tariffs/${id}/configs/${idConfig}`, data)
}

export const createTariff = (data) => {
  return API.post("/mp-admin/billing/tariffs/", data)
}

export const updateTariff = (data, id) => {
  return API.put(`/mp-admin/billing/tariffs/${id}`, data)
}

export const setNewOrdering = (list, offset) => {
  const sendList = list.map((item, index) => {
    return {
      id: item.id,
      ordering: index + offset
    }
  })

  return API.put("/mp-admin/billing/tariffs/bulk", sendList)
}
