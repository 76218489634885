import { API_V2 } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { moderationPrefix, getLocalizedHeaders } from "./servicesUtils.js"

export const fetchUnits = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/attribute-units/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchUnit = ({ code, language, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/attribute-units/${code}/`,
    getLocalizedHeaders(language, signal)
  )
}

export const fetchLocalizedUnit = async ({ code }) => {
  try {
    const ruResponse = await fetchUnit({ code, language: "ru" })
    const ukResponse = await fetchUnit({ code, language: "uk" })
    const enResponse = await fetchUnit({ code, language: "en" })

    const localizedFields = {
      name: {
        ru: ruResponse.data.name,
        uk: ukResponse.data.name,
        en: enResponse.data.name
      }
    }

    return Promise.resolve({ ...ruResponse.data, ...localizedFields })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createUnit = ({ payload }) => {
  return API_V2.post(`${moderationPrefix}/attribute-units/`, payload, getLocalizedHeaders("ru"))
}

export const updateUnit = ({ payload, code, language }) => {
  return API_V2.put(
    `${moderationPrefix}/attribute-units/${code}/`,
    payload,
    getLocalizedHeaders(language)
  )
}

export const updateLocalizedUnit = async ({ payload, code }) => {
  try {
    const ruPayload = {
      ...payload,
      description: payload.description,
      name: payload.name.ru,
      synonyms: payload.synonyms.split(/,|, | , |\n/gm).filter(Boolean)
    }
    const ukPayload = {
      name: payload.name.uk
    }
    const enPayload = {
      name: payload.name.en
    }

    const ruResponse = await updateUnit({ payload: ruPayload, code, language: "ru" })
    await updateUnit({ payload: ukPayload, code, language: "uk" })
    await updateUnit({ payload: enPayload, code, language: "en" })
    return Promise.resolve(ruResponse)
  } catch (error) {
    return Promise.reject(error)
  }
}
