import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils.js"
import i18n from "@/i18n"
import { notification, Progress } from "ant-design-vue"
import { h, ref } from "vue"
import notifyResponseError from "@/utils/notifyResponseError.js"

let syncIntervals = {}

const approveSellersInfo = ref({})

export const fetchSynchronizations = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/synchronizations${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const activateSynchronizations = (list) => {
  const formatedList = list.map(({ slug }) => slug)

  return API.post(`/mp-admin/synchronizations/activate`, { sellers: formatedList })
}

export const deactivateSynchronizations = (list) => {
  const formatedList = list.map(({ slug }) => slug)

  return API.post(`/mp-admin/synchronizations/deactivate`, { sellers: formatedList })
}

export const checkSyncTask = (id) => {
  return API.get(`/mp-admin/synchronizations/tasks/${id}`)
}

export const matchSynchronizations = async (list) => {
  const formatedList = list.map(({ slug }) => ({ type: "synthetic_meta", seller: slug }))

  try {
    const { data } = await API.post(`/mp-admin/synchronizations/match`, formatedList)

    notification.info({ message: i18n.t("syncMatchStarted") })

    Object.entries(data).forEach(([seller, task]) => {
      syncIntervals[task] = setInterval(async () => {
        try {
          const { data: syncData } = await checkSyncTask(task)

          if (syncData.state === "SUCCESS") {
            handleClearInterval(task)
            notifySyncMatchSuccess(seller)
          }
        } catch (syncError) {
          handleClearInterval(task)
          notifyResponseError({ error: syncError, message: i18n.t("syncMatchError", { seller }) })
        }
      }, 5000)
    })
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const approveSynchronizations = async (list) => {
  const formatedList = list.map(({ slug }) => slug)

  try {
    const { data } = await API.post(`/mp-admin/synchronizations/approve`, { sellers: formatedList })
    notification.info({ message: i18n.t("syncApproveStarted") })

    Object.entries(data).forEach(([seller, { task_ids, task_count }]) => {
      approveSellersInfo.value[seller] = { total: task_count, errors: 0, success: 0 }
      if (task_ids && task_ids.length) {
        task_ids.forEach((task) => {
          syncIntervals[task] = setInterval(async () => {
            try {
              const { data: syncData } = await checkSyncTask(task)

              if (syncData.state === "SUCCESS") {
                handleClearInterval(task)
                approveSellersInfo.value[seller].success += 1
              }
            } catch (e) {
              approveSellersInfo.value[seller].errors += 1
            } finally {
              notifySyncApproveSuccess(seller)
            }
          }, 5000)
        })
      } else {
        notifySyncApproveSuccess(seller)
      }
    })
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const getSyncReport = async (slug) => {
  try {
    const { data } = await API.post("/mp-admin/synchronizations/report", { seller: slug })

    syncIntervals[slug] = setInterval(() => {
      fetchAndDownloadSyncReport(data, slug)
    }, 1000)
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const deleteSynchronization = (uuid) => {
  return API.delete(`/mp-admin/synchronizations/${uuid}`)
}

export const updateSynchronization = async (data, seller) => {
  try {
    const {
      data: { task }
    } = await API.post(`/mp-admin/synchronizations/start`, data)

    notification.info({ message: i18n.t("syncUpdateStarted", { seller }) })

    syncIntervals[task] = setInterval(async () => {
      try {
        const { data: syncData } = await checkSyncTask(task)

        if (syncData.state === "SUCCESS") {
          handleClearInterval(task)
          notifySyncUpdateSuccess(seller)
        }
      } catch (syncError) {
        handleClearInterval(task)
        notifyResponseError({
          error: syncError,
          message: i18n.t("syncUpdateError", { seller })
        })
      }
    }, 5000)
  } catch (error) {
    notifyResponseError({ error })
  }
}

const fetchAndDownloadSyncReport = async (data, slug) => {
  const { data: taskData } = await checkSyncTask(data.task)

  if (taskData.state === "SUCCESS") {
    handleClearInterval(slug)

    const { data: reportData } = await API.get(`/mp-admin/synchronizations/report/${taskData.uuid}`)

    try {
      window.open(reportData.url, "_blank")
    } catch (error) {
      notifyResponseError({ error })
    }
  }
}

const handleClearInterval = (key) => {
  clearInterval(syncIntervals[key])
  delete syncIntervals[key]
}

const notifySyncApproveSuccess = (seller) => {
  const { total, success, errors } = approveSellersInfo.value[seller]
  const countMessage = `${i18n.t("total")}: ${total},
    ${i18n.t("success")}: ${success}, ${i18n.t("failed")}: ${errors}`

  notification.open({
    key: seller,
    message: i18n.t("syncApproveProgress", { seller }),
    description: () =>
      h("div", [
        h(Progress, {
          props: {
            percent: total ? ((success + errors) / total) * 100 : 100,
            successPercent: total ? (success / total) * 100 : 100,
            strokeColor: "#f5222d",
            status: "normal"
          }
        }),
        total ? h("div", countMessage) : undefined
      ]),
    duration: null
  })
  if (total === success + errors) {
    approveSellersInfo.value[seller] = undefined
  }
}

const notifySyncMatchSuccess = (seller) => {
  notification.success({
    message: i18n.t("syncMatchSuccess", {
      seller
    }),
    duration: null
  })
}

const notifySyncUpdateSuccess = (seller) => {
  notification.success({
    message: i18n.t("syncUpdateSuccess", {
      seller
    }),
    duration: null
  })
}
