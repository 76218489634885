export default {
  condition_new: "Новий",
  condition_p_discount: "Уцінка",
  condition_refurbished: "Refurbished",
  condition_used_5: "Б/У ідеальний",
  condition_used_4: "Б/У хороший",
  condition_used_3: "Б/У задовільний",
  condition_used_2: "Б/У returnware",
  condition_used_1: "Б/У неробочий",
  condition_used: "Б/У",
  condition_w_undetermined: "Без стану",
  toCopy: "Скопіювати",
  copied: "Скопійовано",
  upload: "Завантажити",
  uploadFile: "Завантажити файл",
  all: "Усі",
  yes: "Так",
  no: "Ні",
  without: "Без",
  any: "Будь-який",
  notSet: "Немає",
  from: "Від",
  to: "До",
  total: "Всього",
  you: "Ви",
  recommendedSize: "Рекомендований розмір {size}",
  notFoundTitle: "Вибачте, сторінка, яку ви відвідали, не існує.",
  //  statuses
  status: "Статус",
  aim: "Ціль",
  condition: "Стан",
  state: "Стан",
  active: "Активно",
  inactive: "Не активно",
  disabled: "Вимкнено",
  paused: "Призупинено",
  allHealthValues: "Усі рівні здоров'я продавця",
  critical: "Критичне",
  low: "Низьке",
  good: "Добре",
  normal: "Нормальне",
  negative: "Негативний",
  hidden: "Прихований",
  showed: "Показані",
  onAudit: "На перевірці",
  canceled: "Скасованих | Скасована | Скасовані | Скасованих",
  canceledStatus: "Скасовано",
  deleted: "Видалено",
  declined: "Відхилено",
  success: "Успішно",
  approved: "Підтверджено",
  error: "Помилка",
  withErrors: "З помилками",
  failed: "Невдало",
  isOver: "Закінчився",
  willOver: "Закінчиться",
  setted: "Встановлений",
  unsupportedFormat: "Непідтримуваний формат",
  selling: "Продається",
  notSelling: "Не продається",
  reserved: "Очікується",
  blocked: "Заблокований",
  notSpecified: "Не вказано",
  used: "Б/В",
  confirmed: "Підтверджений",
  added: "Додано",
  notFound: "Не знайдено",
  refurbished: "Refurbished",
  p_discount: "Уцінка",
  noData: "Немає даних",
  noDataShorted: "Н/Д",
  loading: "Завантаження",
  //  inStock
  inStock: "В наявності",
  inStockShort: "В н-ті",
  outOfStock: "Немає в наявності",
  // product
  buyer: "Покупець",
  name: "Назва",
  shortName: "Назва (коротка)",
  nameFull: "Назва (повна)",
  ofSeller: "Продавця",
  payer: "Платник",
  owner: "Власник",
  nickName: "Псевдонім",
  shop: "Магазин",
  code: "Код",
  offer: "Пропозицій | Пропозиція | Пропозиції | Пропозицій",
  offerCount: "Пропозиції",
  offerCountShorted: "Пропоз.",
  cs_code: "CS-код",
  p_code: "P-код",
  category: "Категорія",
  categoryState: "Стан категорії",
  category_uuid: "Категорія",
  categoriesUpdatedSuccess: "Список категорій успішно змінено",
  editCustomRangeFilterWarning: "Збережіть зміни перед редагуванням діапазонів",
  product_name: "Назва офферу",
  brand: "Бренд",
  logo: "Лого",
  number: "Номер",
  limit: "Лімітів | Ліміт | Ліміти | Лімітів",
  editLimit: "Редагувати ліміт",
  validity: "Термін дії",
  count: "Кількість",
  countShorted: "К-сть.",
  pieces: "шт.",
  existing: "Існуючий",
  health: "Здоров'я",
  updatedAt: "Дата оновлення",
  updatedAtShort: "Дата онов.",
  actions: "Дії",
  spent: "Витрачено",
  sold: "Продано",
  soldSum: "Продано (сума)",
  soldWithoutCancel: "Продано (без відхилень, сума)",
  sum: "Сума",
  price: "Ціна",
  date: "Дата",
  time: "Час",
  course: "Курс",
  phoneNumber: "Моб. телефон",
  purpose: "Призначення",
  day: "Днів | День | Дні | Днів",
  dayShorted: "д.",
  uah: "грн",
  month: "міс.",
  monthFull: "{count} місяців | {count} місяць | {count} місяці | {count} місяців",
  commision: "Комісія",
  balance: "Баланс",
  avaliableForWriteOff: "Доступно до списання",
  writeOff: "Списати",
  remain: "Залишок",
  position: "Позицій | Позиція | Позиції | Позицій",
  canceledPositions:
    "Скасованих позицій | Скасована позиція | Скасовані позиції | Скасованих позицій",
  sales: "Продажі",
  order: "Замовлення",
  orders: "Замовлення",
  orderShorted: "Зам.",
  ordersLeft: "Замовлень | Замовлення | Замовлення | Замовлень",
  hold: "Холд",
  cancellation: "Відмін | Відміна | Відмін",
  note: "Замітка",
  commentFromManager: "Замітка менеджера",
  advertising: "Реклама",
  dateAdded: "Дата додав.",
  main: "Основні",
  general: "Загальне",
  attribute: "Атрибут",
  attributes: "Атрибути",
  value: "Значення",
  filter: "Фільтр",
  filters: "Фільтри",
  filterName: "Назва фільтру",
  filterType: "Тип фільтру",
  rangesCount: "К-ть діапазонів",
  range: "Діапазон",
  notInFilter: "Не потрапили у фільтр",
  notInAllFilters: "Не потрапили у жоден діапазон",
  image: "Картинка",
  allImages: "Всі зображення",
  gallery: "Галерея",
  changeImagesOrder: "Змінити порядок зображень",
  photo: "Фото",
  addPhoto: "Додати фото",
  selectOrDragPhoto: "Виберіть або перетягніть фотографію",
  selectFiles: "Виберіть файли",
  icon: "Іконка",
  description: "Опис",
  type: "Тип",
  types: "Типи",
  text: "Текст",
  hint: "Підказка",
  help: "Підказка",
  sender: "Відправник",
  receiver: "Отримувач",
  url: "Посилання",
  model: "Модель",
  products: "Товари",
  withOffers: "З пропозиціями",
  changeHistory: "Історія змін",
  dateCreated: "Дата створення",
  clearFilters: "Скинути фільтри",
  size: "Розмір",
  aspectRatio: "Співвідношення сторін",
  aspectRatioOfUploadedImage: "Співвідношення сторін у завантажуваного зображення",
  matches: "Збігів",
  noMatchesFound: "Збігів не знайдено",
  language: "Мова",
  coefficient: "Коефіцієнт",
  coefficientShorted: "Коеф.",
  price_from: "Ціна від",
  price_to: "Ціна до",
  rating_from: "Рейтинг від",
  rating_to: "Рейтинг до",
  result: "Результат",
  // actions
  add: "Додати",
  addTo: "Додати в",
  removeFrom: "Видалити з",
  deletingConfirmation: "Бажаєте видалити?",
  update: "Оновити",
  updated: "Оновлено",
  notUpdated: "Не оновлено",
  search: "Пошук",
  searchSeller: "Пошук продавця",
  toSearch: "Шукати",
  create: "Створити",
  created: "Створено",
  edit: "Редагувати",
  edited: "Редаговано",
  rotate: "Повернути",
  change: "Змінити",
  choose: "Вибрати",
  chooseFromList: "Виберіть зі списку",
  appoint: "Призначити",
  delete: "Видалити",
  new: "Новий",
  debit: "Списати",
  apply: "Підтвердити",
  applying: "Підтвердження",
  confirmation: "Підтвердження",
  continue: "Продовжити",
  continueWithoutSaving: "Продовжити без збереження",
  toReturn: "Повернутись",
  cancel: "Скасувати",
  reject: "Відхилити",
  reindex: "Проіндексувати",
  reset: "Скинути",
  save: "Зберегти",
  coppied: "Скопійовано",
  send: "Надіслати",
  read: "Прочитати",
  download: "Завантажити",
  loadOnCDN: "Завантажити на CDN",
  transfer: "Перевести",
  arrange: "Розташувати",
  watch: "Дивитись",
  changesNotSaved: "Зміни не збережені.",
  changesSaved: "Зміни успішно збережені.",
  leavePage: "Вийти зі сторінки?",
  fieldMustNotBeBlank: "Це поле має бути заповненим",
  invalidDelimiter: "Виявлено не правильний розділовий знак",
  invalidCsCode: "Виявлено неправильно введений CS код",
  close: "Закрити",
  viewResult: "Перегляд результату",
  restore: "Відновити",
  select: "Вибрати",
  export: "Експорт",
  cancelChanges: "Скасувати зміни",
  enterCSCodes: "Введіть CS-коди",
  enterCLabel: "Введіть Custom label",
  findedDuplicates: "Знайдено повторення: {duplicates}",
  tryAgain: "Спробуйте ще раз",
  activate: "Активувати",
  stop: "Зупинити",
  show: "Показати",
  crop: "Обрізати",
  calculate: "Розрахувати",
  test: "Тест",
  finded: "Знайдено",
  // tools
  slider: "Слайдер",
  checkbox: "Чекбокс",
  rangeInput: "Рендж інпут",
  tile: "Плитка",
  attention: "Увага",
  resetFilters: "Скинути фільтри",
  currentValues: "Поточні значення",
  newValues: "Нові значення",
  background_color: "Колір заливки",
  font_color: "Колір шрифту",
  // user
  user: "Користувач",
  gender: "Стать",
  birthDate: "Дата народження",
  placeOfResidence: "Місце проживання",
  referalPartner: "Реферальний партнер",
  female: "Жіночий",
  male: "Чоловічий",
  contacts: "Контакти",
  parent: "Батько",
  firstName: "Ім'я",
  session: "Сесія",
  sessions: "Сесії",
  cartCount: "К-сть корзин",
  checkoutStartCount: "К-сть чекаут старт",
  checkoutSubmitCount: "К-сть чекаут сабміт",
  sessionsCount: "К-сть сесій",
  eventsCount: "К-сть івентів",
  start: "Старт",
  userEvents: {
    click: "Клік",
    close: "Закриття",
    swipe: "Свайп",
    focus: "Фокус",
    input: "Інпут",
    submit: "Сабміт",
    "focus-leave": "Анфокус",
    focus_leave: "Анфокус",
    page_enter: "Вхід на сторінку",
    page_focus: "Фокус на сторінці",
    page_focus_leave: "Анфокус сторінки",
    page_leave: "Вихід зі сторінки",
    view: "Перегляд"
  },
  trafficSource: "Джерело трафіку",
  null_sessions_count: "Кількість нульових сесій",
  null_sessions_count_shorted: "Сесії 0",
  non_relevant_sessions_count: "Кількість нерелевантних через ціну",
  non_relevant_sessions_count_shorted: "Сесії н.ц.",
  unique_pages_shorted: "Унік. сторінки",
  unique_pages: "Унікальні сторінки",
  sessionCharacterization: {
    NON_RELEVANT_SESSION: "Нерелевантна через ціну",
    NON_RELEVANT_SESSION_TITLE: "Нерелевантні через ціну",
    NULL_SESSION: "Нульова сесія",
    NULL_SESSION_TITLE: "Нульові сесії"
  },
  sessionExpired: "Сесія закінчилась, будь ласка, увійдіть знову",
  // errors
  lengthError: "Кількість символів перевищено",
  maxLengthError: "Не може перевищувати {length} символів",
  minLengthError: "Повинно бути не менше {length} символів",
  wantedLengthError: "Повинно бути {length} символів",
  mustBe: "Повинно бути",
  orLowerCased: "або",
  symbolsLowerCased: "символів",
  emptyError: "Значення є обов'язковим",
  fieldRequire: "Поле є обов'язковим",
  alreadyInDataBaseError: "Значення вже є в базі",
  valueQuantityError: "Кількість значень перевищено",
  maxValueQuantityError: "Максимальна кількість значень: {quantity}",
  repetativeValuesError: "Одне зі значень повторюється",
  urlError: "Повинен бути формат посилання",
  floatError: "Повинен бути формат числового значення",
  numberError: "Потрібне ціле значення",
  cyrillicError: "Не можна містити кирилицю",
  formatEan13Error: "Повинен бути формат EAN-13",
  phoneFormatError: "Повинен бути формат телефону",
  wrongGtinError: "Недопустимі значення, що починаються на: ",
  mustBeSameError: "Значення повинні збігатися",
  mustBePositivError: "Значення має бути додатнім",
  nameOrModelRequiredError: 'Обов\'язково заповніть "Назва товару (серії)" або "Модель товару"',
  dateError: "Недійсна дата",
  timeError: "Недійсний час",
  categoryNotLast: "Категорія не є останньою",
  cancelEditingWarning: "Внесені зміни не будуть збережені.",
  incorrectSumError: "Значення не може бути менше 0.01",
  errorOnlyNumbers: "Допустимі тільки цифри",
  creatingError: "Не вдалось створити",
  updatingError: "Не вдалось оновити",
  filterNamesDuplicateError: "Назви різних фільтрів мають бути унікальними",
  filterNamesEmptyError: "Назви фільтрів мають бути заповнені",
  rangeNamesDuplicateError: "Назви різних діапазонів мають бути унікальними",
  rangeValuesIncorrect: "Дані діапазону некоректні",
  calculatingFailed: "Під час розрахунку сталась помилка",
  minNumberError: "Значення має бути більше за {min}",
  maxNumberError: "Значення має бути менше {max}",
  createListingError: "{code} вже використовується в іншому продукті як обкладинка",
  invalidEmailError: "Вказано не дійсний e-mail",
  passwordsNotMatchError: "Паролі не збігаються",
  attributeNotFound: "Атрибут не знайдено",
  imageNotSupported: "Зображення не підтримується",
  imageLoadingError: "Сталась помилка при завантаженні зображення",
  fileLoadingError: "Сталась помилка при завантаженні файлу",
  dataNotSaved: "Дані не збережені",
  changesWillBeLost: "Зміни будуть втрачені",
  // pagination
  recordsFrom: "Записи з",
  fromRecords: "з {count} записів",
  // roles
  manager: "Менеджер",
  customer: "Покупець",
  seller: "Продавець",
  moderator: "Модератор",
  // notifications
  emailCopied: "E-mail скопійовано",
  // Components
  messenger: "Мессенджер",
  messengerModeration: "Месенджер модерація повідомлень",
  usersManagement: "Керування користувачами",
  generalMP: "МП Загальне",
  catalogMP: "МП Каталог",
  reviewsMP: "МП Відгуки",
  sellsMP: "МП Продажі",
  productCatalog: "Продуктовий",
  ADS: "ADS"
}
