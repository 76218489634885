<template>
  <a-menu
    mode="horizontal"
    class="menu"
    :defaultSelectedKeys="defaultSelectedKeys"
  >
    <a-sub-menu
      v-for="application in commonAplications"
      v-if="showApplication(application)"
      :title="application.title"
      :key="application.key"
    >
      <a-menu-item
        v-for="child in application.components"
        v-if="
          checkComponentPermission({ code: child.permissionCode, permissions }) ||
          child.withoutChecking
        "
        :key="child.code"
      >
        <router-link :to="pathForComponents[child.code]">
          {{ child.title }}
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-menu-item v-if="checkComponentPermission({ code: '00MS01/00MS01', permissions })">
      <router-link to="/messenger">
        <a-badge
          :count="unreadMessages"
          :numberStyle="{ backgroundColor: '#52c41a', marginRight: '-10px' }"
        >
          {{ $t("messenger") }}
        </a-badge>
      </router-link>
    </a-menu-item>

    <a-sub-menu
      v-if="checkApplicationPermission({ code: '00ADS', permissions })"
      title="ADS"
    >
      <a-menu-item
        v-for="child in adsComponents"
        v-if="checkComponentPermission({ code: child.permissionCode, permissions })"
        :key="child.code"
      >
        <router-link :to="pathForComponents[child.code]">
          {{ child.title }}
        </router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>

<script>
import usePermissions from "@/composables/usePermissions"
import { mapGetters, mapState } from "vuex"
import { adsComponents, pathForComponents, commonAplications } from "./navigationComponents"

export default {
  name: "Navigation",

  setup() {
    const { checkApplicationPermission, checkComponentPermission } = usePermissions()

    return {
      adsComponents,
      pathForComponents,
      commonAplications,
      checkComponentPermission,
      checkApplicationPermission
    }
  },

  computed: {
    ...mapGetters("Auth", ["permissions"]),
    ...mapState("Messenger", {
      unreadMessages: (state) => state.unreadMessages
    }),

    defaultSelectedKeys() {
      const code = this.$route.meta.code

      if (!code) return []
      return [code]
    }
  },

  methods: {
    showApplication(application) {
      const showApplicationComponents = application.components.some((item) =>
        this.checkComponentPermission({
          code: item.permissionCode,
          permissions: this.permissions
        })
      )

      if (!showApplicationComponents) return false

      return this.checkApplicationPermission({
        code: application.applicationCode,
        permissions: this.permissions
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  line-height: "64px";
  border-bottom: none;
}

.menu-link {
  position: relative;
}
</style>

<style lang="scss">
.menu {
  & .ant-menu-submenu-title,
  & .ant-menu-item {
    padding: 0;
  }

  & .ant-menu-submenu,
  & .ant-menu-item {
    margin-right: 40px;
  }
}
</style>
