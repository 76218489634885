export default {
  directoryOfGoods: "Directory of goods",
  createProduct: "Create product",
  verification: "Verification",
  searchByTitleForPublication: "Search by title for publication",
  productType: "Product type",
  fullProductType: "Product type (full)",
  titleForPublication: "Title for public.",
  editingProductLocalization: "Editing product localization",
  editingProductLocalizationDescription:
    "Adding localization is possible only after the product passes verification (status 'VERIFIED' and 'TRANSFERRED')",
  makeDuplicate: "Make a duplicate",
  productEditing: "Product editing",
  productCreating: "New product creating",
  productCreatingWarning: "You want to cancel product creating?",
  productEditingWarning: "You want to cancel product editing?",
  exportSuccessWithErrors: "Export success but with errors",
  exportSuccess: "Export successfully added to queue",
  exportFailed: "Export is not successful",
  exportError: "Export error",
  errorInTask: "Error in task",
  loadAttributesByTypeError: "Loading attributes error by product type",
  formFilledWithErrors: 'Form in "General" tab is filled with errors',
  createProductSuccess: "Product successfully created",
  creationProductFail: "Product not created",
  productAlreadyExistWithThisName: "Product with same name or model is already exist",
  productLoadError: "Product loading error",
  productsLoadError: "Products loading error",
  loadAttributesError: "Attributes loading error",
  loadMainAttributesError: "Main attributes loading error",
  mainAttributesIsEmpty: "Main attributes is empty in this type",
  typeAttributesIsEmpty: "Type attributes is empty in this type",
  duplicateAttributeError: "This attribute is already added",
  fullProductTitle: "Full product name",
  fullType: "Type (full)",
  shortType: "Type (short)",
  enterProductType: "Enter product type",
  seriesName: "Name (series)",
  enterProductBrand: "Ented product brand",
  publicationTitle: "Title for publication",
  currentCategory: "Current category",
  newCategory: "New category",
  categoryId: "Category ID",
  categoryCanNotContainProducts: "This category cannot contain products",
  categoryNotFound: "Category not found",
  shortProductDescription: "Short product description",
  productDescription: "Product description",
  fillTitleOrModel: "Fill in the field 'Name' or 'Model'",
  fillModelOrTitle: "Fill in the field 'Model' or 'Name'",
  gtinLengthError: "Must be 8, 12, 13 or 14 numerical values",
  saveOrdering: "Save ordering",
  orderingFull: "Ordering",
  imagesChangesSaved: "Image changes are saved",
  imagesChangesFailed: "Updating images error",
  imageEditingFailed: "Editing images error",
  imagesAdded: "Images are attached",
  imageEditing: "Image editing",
  preview: "Preview",
  imageUpdated: "Image is updated",
  addAttribute: "Add the attribute",
  errorInAttributes: "Errors in attributes",
  copyFromProduct: "Copy from product",
  orderSameAsType: "Sort as in type",
  addMains: "Add main ones",
  addAllAttributesByType: "Add all attributes by type",
  deleteBlank: "Delete blanks",
  unit: "Unit of measurement",
  units: "Units",
  characteristics: "Characteristics",
  listValues: "List values",
  valueProcessing: "Value processing",
  unitShorted: "Unit",
  nameForPublish: "Title for publication",
  deleteAttributeConfirm: "You want to remove an attribute",
  contextSearchByAttribute: "Contextual search by attribute name",
  attributeSearch: "Attribute search",
  attributeValue: "Attribute value",
  productLocalizationEditing: "Editing product localization",
  translationTitleForPublish: "Translation title for publication",
  nameTranslation: "Translation of the name",
  setValueInMainLocale: "Set a value for this field in the primary locale (ru)",
  descriptionTranslate: "Description translation",
  publicTitleUpdated: "Value public_title for {code} successfully updated",
  valueUpdated: "Value 'value' for {code} successfully updated",
  publicTitleNotUpdated: "Value public_title not updated",
  valueNotUpdated: "Value 'value' not updated",
  localizationEditingWarning: "You want to cancel editing the product localization?",
  shortDescriptionTranslation: "Translation of a brief description of the product",
  productDublicateError: "Product duplication error",
  naming: "Naming",
  fillAttributesValueError: "Please fill in all attribute values",
  closeAttributesValueError: "Please submit all finish editing attributes",
  public_title: {
    ru: "Name (RU)",
    uk: "Name (UA)"
  },
  moderationTrustStatus: {
    TRUSTED: "Trusted",
    UNTRUSTED: "Untrusted"
  },
  productQualityMark: {
    PERFECT: "Perfect quality",
    GOOD: "Good quality",
    AVERAGE: "Average quality",
    FAIR: "Fair quality",
    POOR: "Poor quality",
    VERY_POOR: "Very poor quality"
  },
  productModerationStatuses: {
    TEMP: "Temporary",
    NEW: "New",
    EDITED: "Edited",
    VERIFIED: "Verified",
    NOT_VERIFIED: "Not verified",
    TRANSFERRED: "Transferred",
    MODERATION_REQUIRED: "Moderation required"
  },
  originOptions: {
    SELLER_MANUAL: "Manual creation",
    SYNTHETIC_DEFAULT: "Other"
  },
  unitIsEmptyPlaceholder: "Empty",
  postModerationStatus: "Post-moderation status",
  origin: "Origin",
  addNewValue: "Add new value",

  // Export
  exportPageTitle: "Products export",
  exportHistoryTitle: "Export history",
  exportProductsToDBTitle: "Export products to central DB",
  exportSellerSlug: "Seller slug",
  exportExecutor: "User",
  exportTotalProducts: "Products count",
  exportSuccessProducts: "Exported successfully",
  exportErrorProducts: "An error occurred while exporting",
  exportDateEnd: "End date",
  exportStatusStarted: "In processing",
  exportStatusSuccess: "Finished",
  exportStatusRevorked: "Revoked",
  exportStatusFailute: "Error",
  exportStatusReceived: "In queue",
  exportStatusPending: "In queue (closer)",
  exportStatusFilter: "Export status",
  exportProductStatus: "Product status",
  exportStartConfirm: "Start export of chosen products?",
  exportStartSuccess: "Export started successfully",
  exportStartError: "Export start error",
  exportFailedStoped: "Export not stopped",
  exportSuccessStoped: "Export stopped",
  exportErrors: "Export errors",

  // Units
  unitEditing: "Unit editing",
  baseUnit: "Base unit",
  synomymsExample: "Example: GigaByte, gigabyte",
  unitExample: "Example: Gb",
  descriptionExample: "Example: Digital Information Repository",
  possibleSynonymsList: "Possible synonyms list",
  unitBaseValue: "Unit value",
  coefAndBaseError:
    "The coefficient and the base value must be filled in at the same time, or not filled in at all",
  productListValue: {
    uk: "Value (UA)",
    ru: "Value (RU)",
    multiple: "Values list"
  },
  creatingProductValue: "Values creating",
  editingProductValue: "Values editing",
  vld_status: {
    APPROVED: "Approved",
    NOT_APPROVED: "Not approver",
    NOT_VALID: "Not valid",
    notValidCount: "Not valid"
  },
  addedLists: "Added lists",
  approveVLDMessage: 'Are you sure that you want to chenage statut to "APPROVED"?',
  chosenAttribute: "Chosen attribute",
  addUnit: "Add unit",
  addUnitShorted: "Add unit",
  editUnit: "Edit unit",
  withMainUnit: "With main unit",

  // List values
  valueDeleted: "Value is deleted",
  editList: "Edit the list",
  deletionWarning: "Confirm deletion",
  chosenList: "Chosen list",
  deletionValue: "Value for deletion",
  deletionValueProductsWarning:
    "In the database, products are found in which the selected value is used. To delete a value, you'll need to select a new value for the following products",
  deletionLastValueWarning:
    "The deleted value is the only one and cannot be replaced by another from this list. Change the attribute parameters or add a replacement value to the list",
  productsThatUsesValue: "Products that uses value",
  creatingNewValues: "Creating new values",
  newValuesCreate: "New values created",
  searchByValue: "Search by value",
  searchListsThatContainsValue: "Search all lists that contain value",
  untieAttributeFromList: "Untie attribute from list",
  attributesThatUseList: "Attributes that use list",
  typesByAttribute: "Types by attribute",
  addAttributeToList: "Add attribute to list",
  exportErrors: "Export errors",
  otherValuesDetectedWarning: "Attention! Other values detected",
  otherValuesInOtherProductsWarning:
    "Products were found with values for the selected attribute that are not in the list. First you need to change the values in the products or add them to the list manually.",

  // Attributes
  attributeEditing: "Attribute editing",
  toTether: "Tether",
  toTetherAttribute: "Tether attribute",
  tethered: "Tethered",
  toUntie: "Untie",
  existedAttributesList: "Existed attributes list",
  chooseList: "Choose list",
  attributeIsMain: "Main attribute",
  attributeIsRequired: "Required attribute",
  chosenListValues: "Value of chosen list",
  listNotChosen: "List is not chosen",
  addingNewAttribute: "Adding new attribute",
  availableValues: "Available values",
  attributeValuesList: "Values list",
  attributesByType: "Attributes by type",
  updateAttributeError: "Attribute update error",
  updateAttributeSuccess: "Attribute updated successfully",
  updateAttributesOrderSuccess: "Attributes order updated successfully",
  updateAttributesOrderError: "Attributes order update error",
  chooseType: "Choose type",
  chosenList: "Chosen list",
  addingNewValues: "Adding new values",
  listSeparatedByComa: "List of values separated by comma",
  typesTetheredToAttribute: "Tethered types to attribute",
  editAttribute: "Edit attribute",
  attributeCode: "Attribute code",
  workName: "Work name",
  publicName: "Public name",
  valueType: "Value type",
  attributeValueApproveStatuses: {
    TRUSTED: "Approved",
    UNTRUSTED: "Not approved"
  },
  addValue: "Add value",
  allowMultipleValues: "Allow multiple values",
  attachedTypes: "Attached types",
  editAttributes: "Edit attributes",
  valuesList: "Values list",
  confirmToDeleteAttributeValue: "Are you sure that want to delete value?",
  confirmToUnattachType: "Are you sure that want to untie type?",
  primarySettings: "Primary settings",
  importStarted: "Import started",
  productListWithValue: "Products with value",

  // Types
  existedTypesList: "Types dictionary",
  typeEditing: "Type editing",
  typeName: "Type name",
  attributeName: "Attribute name",
  attachedAttributesCount: "Attached attrs count",
  valuesCountInAttribute: "Values count",
  editType: "Edit type",
  removeAttributeFromTypeConfirmation: "Are you sure that want untie: {attribute}?",
  removeAttributeFromTypeSuccess: "Attribute untied successfully",
  removeAttributeFromTypeError: "Warnaing, other values finded",
  atrributeAndTypeIsUsedByProductsError:
    "Attention! To delete, first edit the product cards that have a relationship between the values of this attribute and the type",
  createTypeError: "Create type error",
  chooseTypeForCopy: "Choose type for copy",
  typeCopying: "Type copying",
  tetherAttributeToType: "Tethering attribute to type",
  chooseAttribute: "Choose attribute",
  newAttribute: "New attribute",
  tetheredAttributes: "Tethered attributes",
  creatingNewType: "Create type",
  typeNoteLengthExceeded: "Error when saving, some types have a note length longer than allowed",

  // Brands
  creatingBrand: "New brand creating",
  editingBrand: "Brand editing",
  editBrand: "Edit brand",
  chosenBrand: "Chosen brand",
  findedInTypes: "Finded in types",
  searchBrandTypes: "Search for product types by brand",
  brandName: "Brand name",
  brandCode: "Brand code",
  addBrand: "Add brand"
}
