<template>
  <div>
    <PageHeader :title="$t('bonusesAndAdministration')" />

    <div class="bonuses">
      <div
        class="bonuses_block"
        v-if="!fetching"
      >
        <h3>{{ $t("adminBalance") }}: {{ superBill.admin_balance }}</h3>
        <div>
          {{ $t("totalCreated") }}: {{ superBill.total_created }}
          <span class="bonus_item" />
        </div>
        <div>
          {{ $t("totalUserBalance") }}: {{ superBill.total_user_balance }}
          <span class="bonus_item" />
        </div>
        <div>
          {{ $t("totalReserved") }}: {{ superBill.total_reserved }}
          <span class="bonus_item" />
        </div>
        <div class="bonuses_block_buttons">
          <a-button
            type="primary"
            @click="toggleCreateBonusModal()"
          >
            {{ $t("create") }}
          </a-button>
          <a-button
            type="primary"
            @click="toggleTransactBonusModal()"
          >
            {{ $t("transfer") }}
          </a-button>
        </div>
      </div>
      <a-spin
        class="bonuses_block spin"
        v-else
      />

      <div
        class="bonuses_block"
        v-if="!fetching"
      >
        <h3>{{ $t("currentCourse") }}</h3>

        <div>
          100 <span class="bonus_item"></span> =
          {{ getFormatedSum(currencyRateCurrent.rate, true) }}
          {{ $t("uah") }}
        </div>

        <p>{{ $t("setted") }}: {{ currencyDate }}</p>

        <div class="bonuses_block_buttons">
          <a-button
            type="primary"
            @click="toggleChangeRateModal()"
          >
            {{ $t("edit") }}
          </a-button>
          <a-button
            type="primary"
            @click="toggleHistoryRateModal()"
          >
            {{ $t("courseHistory") }}
          </a-button>
        </div>
      </div>
      <a-spin
        class="bonuses_block spin"
        v-else
      />
    </div>

    <BonusesTransactionsHistory
      style="padding-left: 8px"
      ref="bonusesTransactionsHistory"
    />

    <CreateBonusModal
      :showModal="showCreateBonusModal"
      @handleRefreshData="handleRefreshData"
      @close="toggleCreateBonusModal()"
    />

    <ChangeRateModal
      :showModal="showChangeRateModal"
      :currentRate="currencyRateCurrent.rate"
      @handleRefreshData="handleRefreshData"
      @close="toggleChangeRateModal()"
    />

    <HistoryRateModal
      :showModal="showHistoryRateModal"
      @close="toggleHistoryRateModal()"
    />

    <TransactBonusModal
      :showModal="showTransactBonusModal"
      @handleRefreshData="handleRefreshData"
      @close="toggleTransactBonusModal()"
    />
  </div>
</template>

<script>
import PageHeader from "@/ant-components/PageHeader"

import BonusesTransactionsHistory from "./BonusesTransactionsHistory"
import CreateBonusModal from "./components/CreateBonus"
import ChangeRateModal from "./components/ChangeBonusRate"
import HistoryRateModal from "./components/HistoryRate"
import TransactBonusModal from "./components/TransactBonus"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import { getSuperBill, getBonusRate } from "@/modules/MPAdmin/services/bonusesService.js"
import getFormatedSum from "@/utils/getFormatedSum"

export default {
  name: "Bonuses",

  data() {
    return {
      fetching: true,

      showCreateBonusModal: false,
      showChangeRateModal: false,
      showHistoryRateModal: false,
      showTransactBonusModal: false,

      superBill: {
        admin_balance: 0,
        total_user_balance: 0,
        total_reserved: 0,
        total_created: 0
      },

      currencyRateCurrent: {
        rate: 0,
        created_at: "",
        uuid: ""
      }
    }
  },

  components: {
    PageHeader,
    BonusesTransactionsHistory,
    CreateBonusModal,
    ChangeRateModal,
    HistoryRateModal,
    TransactBonusModal
  },

  computed: {
    currencyDate() {
      return this.currencyRateCurrent.created_at
        ? getFormatedDateTime(this.currencyRateCurrent.created_at)
        : ""
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    getFormatedSum,

    async getData() {
      this.fetching = true

      try {
        const superBillResponse = await getSuperBill()
        this.superBill = superBillResponse.data

        const currencyResponse = await getBonusRate()
        this.currencyRateCurrent = currencyResponse.data
      } catch (e) {
      } finally {
        this.fetching = false
      }
    },

    handleRefreshData() {
      this.getData()
      this.$refs.bonusesTransactionsHistory.onTableChange()
    },

    toggleCreateBonusModal() {
      this.showCreateBonusModal = !this.showCreateBonusModal
    },

    toggleTransactBonusModal() {
      this.showTransactBonusModal = !this.showTransactBonusModal
    },
    toggleChangeRateModal() {
      this.showChangeRateModal = !this.showChangeRateModal
    },
    toggleHistoryRateModal() {
      this.showHistoryRateModal = !this.showHistoryRateModal
    }
  }
}
</script>

<style lang="scss" scoped>
.bonuses {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 190px;
  &_block {
    border: 1px solid #eaeaea;
    border-radius: 3px;
    padding: 15px;
    width: 33%;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_buttons {
      display: flex;
      justify-content: space-evenly;
      margin-top: 20px;
      button {
        width: 48%;
      }
    }
  }
}
.spin {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bonus_item {
  background-image: url("~@/assets/images/bonus_item.png");
  background-size: 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
}
</style>
