import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const fetchPaymentMethods = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/payment-methods/${getFormatedQueryParams(queryParams)}`, { signal })
}

export const createPaymentMethod = (data) => {
  return API.post("/mp-admin/payment-methods/", data)
}

export const updatePaymentMethod = (data, id) => {
  return API.put(`/mp-admin/payment-methods/${id}`, data)
}
