import { API_V2 } from "@/utils/HttpUtils.js"
import { getLocalizedHeaders, moderationPrefix } from "./servicesUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"

export const fetchExportHistory = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/export-jobs/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const stopExportingJob = ({ requestParams }) => {
  return API_V2.post(
    `${moderationPrefix}/export-jobs/${requestParams.job_oid}/stop/`,
    {
      job_oid: requestParams.job_oid
    },
    getLocalizedHeaders(requestParams?.language?.value)
  )
}

export const fetchExportReport = (oid) => {
  return API_V2.get(`${moderationPrefix}/export-jobs/${oid}/report/`, getLocalizedHeaders("ru"))
}

export const fetchExportErrors = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/export-jobs/${requestParams.oid}/errors/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}
