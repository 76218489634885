export default {
  lowerCaseError: 'Значення повинне бути у верхньому регістрі',
  whiteSpaseError: 'Значення не повинне мати пробілів',
  changeRating: "Змінити рейтинг (файл)",
  products_rating_successfully_changed: "Рейтинг товарів успішно змінено",
  //
  billing: "Білінг",
  creatingBonuses: "Створення бонусів на адмін рахунок",
  transferBonusesToUser: "Переказ бонусів на бонусний рахунок користувача",
  sellerDebit: "Поповнення балансу продавця",
  cashBack: "Винагорода за покупку у продавця",
  orderCancelled: "Замовлення скасоване",
  tariff: "Тарифів | Тариф | Тарифа | Тарифи",
  newTariff: "Новий тариф",
  addNote: "Додати замітку",
  orderLimitExceeded: "Ліміт замовлень перевищено",
  ordersCount: "К-ть всіх замов.",
  amountFreeOrders: "Ліміт замов. і дата",
  tariffPlan: "Тарифний план і дата закінчення",
  chooseTariff: "Виберіть тариф",
  chooseTariffDate: "Виберіть термін дії тарифу",
  lastOperationInfo: "Сума і дата ост. оплати",
  currentBalance: "Поточний баланс",
  currentTariff: "Поточний тариф",
  noCurrentTariff: "Немає поточного тарифу",
  offersVisibilityTitle: "Пропозиції",
  licenseExpireDate: "Дата закінчення ліцензії",
  freeOrdersLimit: "Ліміт безкоштовних замовлень",
  newOrderLimit: "Новий ліміт замовлень",
  cancelTariff: "Скасувати тариф",
  changingTariffPlan: "Зміна тарифного плану",
  chosingTariffError: "Вибраний тариф встановити неможливо, у продавця активний платний тариф до",
  expirationDate: "Дата закінчення",
  tariffPrice: "Вартість тарифного плану",
  tariffPrices: "Вартість тарифних планів",
  returnOfBalance: "Повернення балансу",
  saldoAfterOperation: "Сальдо після операції",
  sellerOrders: "Замовлення продавця",
  lastWeek: "За останні сім днів",
  activeOrSelling: "Активних / продається",
  tariffPlanBalance: "Залишок по тарифному плану",
  topUp: "Поповнити",
  writeOff: "Списати",
  balanceRefill: "Поповнення балансу",
  applySum: "Підтвердити суму",
  comment: "Коментар",
  writeOffingCommission: "Списання комісії",
  reason: "Причина",
  withdrawForService: "Зняти кошти за послугу",
  questionToChangeTariff: "Ви дійсно хочете змінити тариф на",
  questionToCancelTariff: "Ви дійсно хочете скасувати тариф",
  costOf: "вартістю",
  operationType: "Тип операції",
  operationSum: "Сума операції",
  saldo: "Сальдо",
  tariffConfigNotActive: "Конфігурації тарифу не активна",
  courseChange: "Зміна курсу",
  bonusTitle: "Бонуси",
  promoCodeTitle: "Промокод",
  bonus: "Бонусів | Бонус | Бонуси | Бонусів",
  applyNeeded: "Потрібно підтвердження",
  bonusCourseAlert: "Увага! Курс бонусів буде змінено на 100 бонусів = {value} грн",
  bonusCreating: "Створення бонусів",
  enterSum: "Введіть суму",
  courseHistory: "Історія курсу",
  transferBonuses: "Перевести бонуси",
  recipientSearch: "Пошук отримувача",
  recipient: "Отримувач",
  adminTransactionsHistory: "Історія транзакцій Admin рахунку",
  bonusesAndAdministration: "Бонуси. Адміністрування",
  adminBalance: "Рахунок адміністратора",
  totalCreated: "Всього створено",
  totalUserBalance: "В роботі у користувачів",
  totalReserved: "З них в очікуванні",
  currentCourse: "Поточний курс",
  balanceReplenished: "Баланс успішно поповнено",
  tariffCanceled: "Тариф скасовано",
  commissionDebited: "Комісія успішно списана",
  freeOrdersLimitChanged: "Ліміт безкоштовних замовлень змінено",
  bonusRateChanged: "Курс бонусів успішно змінено",
  someBonusesCreated:
    "бонусів успішно створено | бонус успішно створено | бонуси успішно створено | бонусів успішно створено",
  someBonusesTransfered:
    "бонусів успішно переведено вибраному користувачу | бонус успішно переведено вибраному користувачу | бонуси успішно переведено вибраному користувачу | бонусів успішно переведено вибраному користувачу",
  categoryCreated: "Категорія успішно створена",
  addCategory: "Додати Категорію",
  catalogModeration: "Модерація каталогу",
  categoryEditing: "Редагування категорії",
  categoryCreating: "Створення категорії",
  startPrint: "Почніть вводити текст",
  parentCategory: "Батьківська категорія",
  offersInCategory: "Пропозицій в категорії",
  showValuesForThisCategory: "Відображати значення для товарів цієї категорії",
  productCondition: "Стан товару",
  guaranteePeriod: "Гарантійний термін",
  purchaseReturns: "Повернення товару",
  noSettings: "Немає налаштувань",
  deleteCategory: "Видалити категорію",
  deleteCategoryAlert: "Впевнені, що хочете видалити категорію?",
  commaSeparated: "Через кому",
  indicateNameAlert: "Вкажіть назву категорії на всіх мовах, від 2 до 100 символів",
  descriptionLengthAlert: "Опис не повинен перевищувати 1000 символів",
  uniqFilterNameAlert: "Ім'я фільтрів має бути унікальним",
  adminTransactionHistory: "Історія транзакцій адміністратора",
  editingCustomRangeFilter: "Редагування діапазону фільтра",
  filterCondition: "Умова фільтра",
  valueInTheBaseUnit: "Значення умови в базовій одиниці виміру",
  baseUnitError: `Не вдалось отримати одиницю виміру, скоріш за все тип фільтру не "numeric", в такому випадку діапазони не будуть відображатись на маркетплейсі, будь ласка, перевірте налаштування атрибуту`,
  getBaseUnit: "Отримати одиницю виміру",
  baseUnitFetched: "Одиниця виміру успішно отримана",
  baseUnitFetchError: "Не вдалось отримати одиницю виміру",
  offersInRange: "Товари в діапазоні",
  downloadFileWithOffers: "Завантажити файл з товарами",
  calculateCount: "Розрахувати кількість",
  baseValueInAttribute: 'Базова одиниця виміру в атрибуті "{name}" - ',
  clickToFindOut: "Натисніть щоб дізнатись",
  calculatingIsSuccess: "Вітаємо! Ваш розрахунок пройшов успішно",
  reindexSuccess: "Індексація запущена",
  reindexFailure: "Індексування не вдалось",
  downloadCategoryReportWillStart: "Завантаження звіту скоро почнеться",
  // general settings
  generalSettings: "Загальні налаштування",
  tariffPlanSettings: "Налаштування тарифного плану",
  addTariffPlan: "Додати тарифний план",
  configurations: "Конфігурації",
  addConfiguration: "Додати конфігурацію",
  autoRenewTariff: "Автопродовження тарифного плану",
  avaliableSellerToBuy: "Доступно продавцю до придбання",
  activeOffersCount: "К-ть активних пропозицій в тарифному плані",
  fillName: "Заповніть назву",
  fillExpire: "Заповніть термін дії",
  expireInDays: "Термін дії (днів)",
  fullPrice: "Повна вартість (грн)",
  pricePerMonth: "Ціна за місяць",
  tariffName: "Назва тарифу",
  confAndPrice: "Конфігурація і вартість",
  reasonRefuse: "Причина відмови замовлення",
  editingReasonRefuse: "Редагування причин відмови",
  creatingReasonRefuse: "Створення причин відмови",
  avaliableFor: "Кому доступна",
  ordersToNull: "Обнуляти залишок товарів по замовленню",
  refuseNameAlert: "Вкажіть назву причини відмови, від 2 до 200 символів",
  avaliableRefuseForAlert: "Виберіть кому доступна причина відмови",
  editingDeliveryMethods: "Редагування методів доставки",
  creatingDeliveryMethods: "Створення методів доставки",
  chooseType: "Виберіть тип",
  deliveryType: "Тип доставки",
  deliveryService: "Служба доставки",
  chooseDeliveryType: "Виберіть тип доставки",
  deliveryServiceActivity: "Активність служби доставки",
  chooseDeliveryService: "Виберіть службу достаки",
  outputSortOrder: "Порядок сортування виводу",
  takeFilteringBySellers: "Враховувати у фільтрації за продавцями",
  supportedPayments: "Підтримувані методи оплати",
  indicateMethodNameAlert: "Вкажіть назву методу, від 2 до 100 символів",
  enterIntegerValue: "Введіть цілочисельне значення",
  deliveryMethod: "Метод доставки",
  deliveryMethodActivity: "Відображення методу доставки",
  deliveryMethods: "Методи доставки",
  sellerFilter: "Фільтр. по продавцям",
  paymentMethodActivity: "Активність методу оплати",
  paymentMethods: "Методи оплати",
  editingDeliveryService: "Редагування служби доставки",
  creatingDeliveryService: "Створення служби доставки",
  chooseLogo: "Виберіть лого",
  apiIntegration: "API інтеграція",
  paymentMethodNotSetted: "Метод оплати не вибраний",
  categoryModeration: "Управління категоріями",
  notLimited: "Не обмежено",
  deleteLabelAlert: "Впевнені, що хочете видалити лейбл?",
  // labels
  label: "Лейбл",
  labels: "Лейбли",
  editingLabels: "Редагування лейблів",
  creatingLabels: "Створення лейблів",
  colorFill: "Заливка",
  fontColor: "Колір шрифту",
  example: "Приклад",
  enterLabelText: "Введіть текст лейб. Від 2 до 40 символів",
  labelSettings: "Налаштування лейблів товарів",
  offersLabelSettings: "Налаштування лейблів оферів",
  colorOfFill: "Колір заливки",
  labelDeleted: "Лейба видалена",
  // payment methods
  editingPaymentMethods: "Редагування методів оплати",
  creatingPaymentMethods: "Створення метода оплати",
  // return reasons
  returnReasons: "Причини повернення",
  editingReturnReasons: "Редагування причини повернення",
  creatingReturnReasons: "Додати причину повернення",
  deletionApply: "Підтвердження видалення",
  returnReasonWillBeDeleted: "Причина повернення буде видалена",
  // tags
  tags: "Теги",
  offerTags: "Теги пропозицій",
  editingTags: "Редагування тегів",
  creatingTags: "Створення тегів",
  tagNameAlert: "Введіть назву характеристики. Від 2 до 100 символів",
  tagDeleted: "Тег видалено",
  // invoices
  sumWithVat: "Сума з ПДВ, грн",
  sumWithoutVat: "Сума без ПДВ, грн",
  vat: "ПДВ, грн",
  account: "Рахунків | Рахунок | Рахунка | Рахунків",
  invoiceFormating: "Формування рахунку",
  managerComment: "Коментар менеджера",
  adminComment: "Коментар адміністратора",
  createInvoice: "Створити рахунок",
  invoiceCreated: "Рахунок створено",
  changeInvoiceOn: "Змінити рахунок на суму",
  currentStatus: "Поточний статус",
  changeStatus: "Змінити статус",
  dateAndTimePayment: "Дата і час оплати",
  invoiceNumber: "Номер рахунку",
  payerFactual: "Платник (факт.)",
  payerPDF: "Платник PDF",
  fillFields: "Заповніть обов'язкові поля",
  paid: "Оплачено",
  sellerAccounts: "Рахунки продавців",
  searchInvoicesFactual: "Пошук по рахунку",
  creatingDate: "Дата створення",
  finishingDate: "Дата закінчення",
  dateInPdf: "Дата, вказана у PDF",
  invoiceNumberWithSymbol: "№ рахунку",
  invoiceStatus: "Статус рахунку",
  paymentDate: "Дата оплати",

  payerInformation: "Інформація про платника",
  enterSellerName: "Введіть назву продавця",
  sellerTypePlaceholder: "ПІБ / ФОП / ТОВ",
  sellerTypePib: "ПІБ",
  sellerTypeFop: "ФОП",
  sellerTypeTov: "ТОВ",
  payerHelpText: "Введіть перевірену назву платника з вказанням ФОП або ТОВ",
  invoiceSumDivider: "Сума оплати",
  invoiceSumFull: "Сума з ПДВ / без ПДВ / ПДВ",
  isVatPayer: "Є платником ПДВ?",
  isVatPayerFull: "Чи є платником податків?",
  edrpou: "Код ЄДРПОУ",
  ipn: "ІПН",
  edrpou_ipn: "Код ЄДРПОУ / ІПН",
  invoiceAddressDivider: "Юридична адреса платника",
  postCode: "Поштовий індекс",
  region: "Область",
  district: "Район",
  city: "Місто",
  settlement: "Населений пункт",
  settlementType: "Тип населеного пункту",
  streetName: "Вулиця",
  houseNumber: "Будинок",
  cabinet: "Кабінет",
  apartment: "Квартира",
  apartmentShort: "Кв.",
  dateInPdf: "Дата в PDF",
  invoiceCreatingSuccess: "Рахунок успішно створено",
  invoiceCreatingError: "Рахунок не створено",
  invoiceUpdateSuccess: "Рахунок успішно оновлено",
  invoiceUpdateError: "Рахунок не оновлено",
  invoiceCreating: "Створення рахунку",
  editInvoiceModalTitle: "Змінити рахунок {number}",
  invoiceEditInvoiceDataTitle: "Дані, вказані при створенні рахунку",
  payer: "Платник",
  invoiceAdressFull: "Юридична адреса",
  invoicePaidSum: "Сума оплати",
  invoicePaymentDateTime: "Дата і час оплати",
  errorInvoiceDateTime: "Коректний формат DD.MM.YYYY hh:mm",
  invoiceManagerDataDivider: "Дані, вказані менеджером при зарахуванні оплати",
  invoiceDetails: "Реквізити",
  invoiceDateValidUntil: "Рахунок дійсний до",
  invoiceSearch: "Пошук по продавцю, платнику, платнику (факт.), коментар, номер рахунку",
  invoiceOverdue: "Рахунок протерміновано",
  // moderation buyers
  moderationBuyers: "Покупці",
  sellerManager: "Менеджер продавця",
  searchInMB: "Пошук по псевдоніму, ПІП, телефону, e-mail",
  userProfile: "Профіль користувача",
  userFullName: "ПІП користувача",
  photoAllowed: "Фото профілю видиме",
  photoHidden: "Фото профілю приховане",
  activateUser: "Активувати аккаунт",
  blockUser: "Заблокувати аккаунт",
  userIsActive: "Активний аккаунт",
  userIsBlocked: "Аккаунт заблокований",
  avatarShowAlert: "Ви дійсно бажаєте показати фото даного користувача?",
  avatarHideAlert: "Ви дійсно бажаєте приховати фото даного користувача?",
  userIsActiveAlert: "Ви впевнені, що хочете розблокувати користувача?",
  userIsBlockedAlert: "Ви впевнені, що хочете заблокувати користувача?",
  isReferalPartnerAlert:
    "Ви впевнені, що хочете дати можливість користувачеві бачити і заходити на сторінку реферальної програми?",
  isNotReferalPartnerAlert:
    "Ви впевнені, що хочете забрати можливість користувачеві бачити і заходити на сторінку реферальної програми?",
  registerDate: "Дата реєстр.",
  lastLogin: "Ост. вхід",
  lastName: "Псевдонім, ПІП",
  bonusAccount: "Бонусний рахунок",
  reviewsTP: "Відгуки Т/П",
  // moderation propositions
  moderationPropositions: "Модерація пропозицій",
  deliveryPolicy: "Політика доставки",
  shipmentTime: "Час відвантаження",
  deliveryTimeMin: "Час доставки, від",
  deliveryTimeMax: "Час доставки, до",
  shipmentCity: "Місто відвантаження",
  moderationStatus: "Статус модерації",
  moderationStatusShorted: "Статус модер.",
  chooseStatus: "Виберіть статус",
  moderComment: "Коментар модератора",
  moderCommentShorted: "Ком. модератора",
  offerImages: "Фото проп.",
  offerVideo: "Відео проп.",
  productImages: "Фото товару",
  priceOld: "Стара ціна",
  warranty: "Гарантія",
  offerStatus: "Стан проп.",
  offerName: "Назва товару",
  cantGetShippingPolicy: "Не вдалось отримати політику доставки",
  // orders moderation
  buyerInformation: "Дані покупця",
  receiverInformation: "Дані отримувача",
  infoAboutOrder: "Інформація про замовлення",
  sellerNotes: "Нотатки продавця",
  sumToPayment: "Сума до оплати",
  orderProductInfo: "Інформація про товар (Назва, категорія, стан, гарантія)",
  viewOrder: "Перегляд замовлення",
  filtersCollection: "Набір фільтрів",
  filtersCollectionOverdueNew: 'Протерміновані "Нові"',
  filtersCollectionOverdueInProcessing: 'Протерміновані "В обробці"',
  filtersCollectionOverdueInApproved: 'Протерміновані "Підтверджені"',
  filtersCollectionOverdueInShipped: 'Протерміновані "Відвантажені"',
  sellerName: "Назва продавця",
  customerPhoneNumber: "Номер покупця",
  customerFilterPlaceholder: "ПІБ покупця",
  receiverTitle: "Дані отримувача",
  sellerAndSlug: "Продавець / Slug",
  orderStatus: "Статус замовлення",
  sellerRefuseReason: "Причина відхилення продавцем",
  buyerRefuseReason: "Причина відхилення покупцем",
  adminRefuseReason: "Причина відхилення адміністрацією",
  orderDetails: "Деталі замовлення",
  ttn: "ТТН",
  confirmTtn: "Підтвердіть вказаний ТТН",
  invalidTtnWarnign: "ТТН не відповідає форматам служб доставки. Продовжити?",
  recipientData: "Дані отримувача",
  dontCallMe: "Не передзвонювати",
  buyerComment: "Коментар покупця",
  sellerNotes: "Замітки продавця",
  bonusTransactions: "Бонусні транзакції",
  ordersReturns: "Повернення",
  orderOnSum: "товар на суму",
  changesHistory: "Історія змін",
  failedCall: "Не вдалося зв'язатися з покупцем",
  phoneShorted: "Тел.",
  productRefunds: "Повернення товару або коштів",
  exchangeProduct: "Обмін товару",
  repair: "Ремонт",
  editDate: "Дата зміни",
  editAuthor: "Автор змін",
  editObject: "Об'єкт змін",
  stateNameWarranty: "Стан, гарантія і найменування товару",
  ordering: "Сорт.",
  bonusesAmount: "Вик. бонусів",
  placedOrders: "Оформлені замовлення",
  orderTime: "Час замовлення",
  orderedDate: "Дата замовлення",
  orderedDateShort: "Дата замов.",
  paymentStatus: "Статус оплати",
  paidOnline: "Оплачений онлайн",
  paidWaiting: "Очікує оплату",
  paidCanceled: "Оплата скасована",
  paymentFailed: "Оплата не створена",
  rejectReason: "Причина скасування",
  moderation: "Модерація",
  unOrdered: "Неоформлені замовлення в кошику",
  orderCode: "Код замовлення",
  inBucketNotOrdered: "В кошику, не оформлений",
  order_status: "Статус",
  receiver_data: "Дані користувача",
  payment_method: "Метод оплати",
  comment_customer: "Коментар покупця",
  // page builder
  pageBuilder: "Інформаційні сторінки",
  categoryEditor: "Редактор категорій",
  sorting: "Сорт.",
  categoryName: "Назва категорії",
  enterReturnName: "Введіть назву повернення. Від 2 до 250 символів",
  pageEditor: "Редактор сторінок",
  urlName: "Ім'я посилання",
  setPhoneNumber: "Вкажіть номер телефону",
  setContentLocation: "Вкажіть розташування контенту",
  setUrl: "Вкажіть посилання",
  setSlug: "Вкажіть slug",
  addPage: "Додати сторінку",
  enterContentLocation: "Вкажіть розташування контенту",
  socialMedia: "Соціальні медіа",
  pageCategory: "Категорія сторінки",
  location: "Розташування",
  // products
  listing: "Лістинг",
  labelsAndTags: "Лейбли і теги",
  differentLabelsAlert: "У вибраних продуктів встановлені різні лейбли",
  differentTagsAlert: "У вибраних продуктів встановлені різні теги",
  listingAndVariations: "Лістинг і варіації",
  productSearchByCategory: "Пошук товарів в категорії {category}",
  listingNumber: "Номер лістингу",
  variations: "Варіації",
  chooseProductCover: "Виберіть товар-кавер",
  chooseCharacteristic: "Виберіть мінімум 1 характеристику для варіації",
  chosenCharForVar: "Вибрані характеристики для варіації",
  createListing: "Створити лістинг",
  editListing: "Редагувати лістинг",
  editListingAndVariations: "Редагувати/додати лістинг і варіації",
  variationIsAdded: "Варіація вже додана",
  productIsChosen: "Товар вже вибраний",
  inNextProducts: "У наступних товарів: {0} немає характеристики",
  enterListingNumber: "Введіть номер лістингу",
  listingSearch: "Пошук лістингів",
  productAdding: "Додавання продуктів",
  chosenProducts: "Вибрані продукти",
  searchResult: "Результат пошуку",
  addChosen: "Додати вибрані",
  addToChosen: "Додати в вибрані",
  routeEditor: "Редактор маршруту в каталозі",
  differentCategoriesAlert: "Вибрані продукти розташовані в різних категоріях",
  chosenCategoryIsNotChild: "Вибрана категорія не є останньою дочірньою",
  chooseCategory: "Виберіть категорію",
  setCategory: "Визначити категорію",
  fullRoute: "Повний шлях",
  ACTIVE: "Активний",
  moderationIsSuccess: "Модерація успішна",
  MODERATION_SUCCESS: "Модерація успішна",
  moderationIsFailed: "Модерація не пройдена",
  MODERATION_FAILED: "Модерація не пройдена",
  moderationIsExpected: "Очікує на модерацію",
  EXPECTS_MODERATION: "Очікує на модерацію",
  moderationInProgress: "В процесі модерації",
  MODERATION_IN_PROCESS: "В процесі модерації",
  ON_MODERATION: "На модерації",
  NEW: "Новий",
  IN_PROCESSING: "В обробці",
  SELLER_APPROVED: "Підтверджено продавцем",
  SHIPPED: "Відвантажено",
  SUCCESSFULLY_CLOSED: "Успішно закритий",
  CANCELED_BY_SELLER: "Скасовано продавцем",
  CANCELED_BY_CUSTOMER: "Скасовано покупцем",
  CANCELED_BY_SUPERVISOR: "Скасовано адміністрацією",
  editRatingFactor: "Редагувати фактор рейтингу",
  editRating: "Редагувати рейтинг",
  rating: "Рейтинг",
  addGoods: "Додати товари",
  sync: "Синхронізація",
  article: "Артикул",
  ratingShorted: "Р-г",
  finalRating: "ФР",
  moderationOfProducts: "Товари",
  cover: "Кавер",
  updateProductFail: "Не вдалось обновити продукт",
  alreadyAdded: "Вже додано",
  createProductFail: "Помилка. Не вдалось створити",
  syncSuccess: "Синхронізація пройшла успішно",
  // promotions
  sliderCreating: "Створення слайдеру",
  sliderEditing: "Редагування слайдеру",
  promoText: "Промо текст",
  sliderCreatingAlert:
    "Додавання зображень до слайдеру и налаштування відображення, доступні після його створення",
  bannersControl: "Керування банерами",
  allowedFormats: "Підтримувані формати",
  allowedRatio: "Підтримувані співвідношення сторін",
  maxFileSize: "Максимальний розмір файлу - {size} мб.",
  maxQuantity: "Максимальна кількість",
  maxUploadedFilesNumber: "Максимальна кількість завантажених файлів",
  maxSize30: "Максимальний розмір - 30 мб",
  bannerForLang: "Банер для мови",
  device: "Пристрій",
  ofDevice: "девайсу",
  ofSize: "розміру",
  mainSlider: "Слайдер на головній",
  addBanner: "Додати банер",
  imageUploaded: "Зображення завантажено",
  imageDeleted: "Зображення видалено",
  banners: "Банери",
  bannerShorted: "Бан.",
  lastShorted: "Остан.",
  advertisingOptions: "Рекламні опції",
  // returns
  returnProductCountWithSum:
    "{count} товарів на суму {sum} грн | {count} товар на суму {sum} грн | {count} торари на суму {sum} грн | {count} товарів на суму {sum} грн",
  returnFrom: "Повернення {0} від {1}",
  returnStatus: "Статус повернення",
  returnPoint: "Ціль повернення",
  returnDetails: "Деталі повернення",
  contactPerson: "Контактна особа",
  returnsByOrder: "Повернення по замовленню",
  orderClosed: "Замовлення виконане",
  attachedPhotos: "Фото, прикріплені до замовлення",
  returnProductsRefunds: "Повернення товару або коштів",
  imagesOfProduct: "Фото товару",
  warrantyMonth: "Гар. міс.",
  imagesOfOffer: "Фото проп.",
  oldValue: "Старе значення",
  newValue: "Нове значення",
  sellerComment: "Коментар продавця",
  returnModeration: "Модерація повернень",
  returnNumber: "Номер повернення",
  orderNumber: "Номер замовлення",
  aimReasonReturn: "Ціль і причина повернення",
  goodsQuantity: "К-ть товарів",
  anyReturnStatus: "Будь-який статус повернення",
  // products rewiews
  parentReview: "Батьківський відгук",
  grade: "Оцінка",
  advantages: "Переваги",
  flaws: "Недоліки",
  photos: "Фотографії",
  reviewDate: "Дата відгуку",
  authorID: "ID автора",
  author: "Автор",
  customerContacts: "Імейл / Телефон",
  reviewPhoto: "Фото. відгуку",
  reviewVideo: "Відео",
  isBought: "Вже купив",
  productsReviews: "Відгуки. Товари",
  // search settings
  brandSynonyms: "Синоніми брендів",
  enterBrandName: "Введіть назву бренду...",
  enterSynonym: "Введіть синоніми...",
  synonym: "Синонімів | Синонім | Синоніми | Синонімів",
  synonyms: "Синоніми",
  addSynonyms: "Додати синоніми",
  synonymCreating: "Створення синонімів",
  synonymSeparated: "Синоніми (через кому)",
  sameSynonymExist: "Такі синоніми уже існують",
  searchSynonyms: "Пошукові синоніми",
  searchSettings: "Налаштування пошуку",
  index: "Індекс",
  key: "Ключ",
  weight: "Вага",
  import: "Імпорт",
  searchWeights: {
    keywords_all: "Вага All",
    keywords_6: "Вага 6",
    keywords_8: "Вага 8",
    keywords_12: "Вага 12",
    keywords_18: "Вага 18"
  },
  addKey: "Додати ключ",
  enterSearchQuery: "Введіть пошуковий запит",
  keywordsIsEmpty: "Немає ключів",
  testSearchQuery: "Тест пошукового запиту",
  productWontShowInSample: "Товар не потрапить у вибірку при пошуку",
  updateIsStarting: "Оновлення успішно запущено",
  filterSortedScoreHelp: "Бал / бал сортування",
  // seller reviews
  bad: "Погано",
  norm: "Норм.",
  good: "Добре",
  ratingOverall: "Загальна оцінка",
  authorFullname: "Автор коммент.",
  reviewAddedPhotos: "Фото, додані до відгуку",
  sellersReviews: "Відгуки. Продавці",
  rating_product_description_fits: "Товар відповідає опису",
  rating_delivery: "Якість упаковки і доставки",
  rating_service: "Рівень обслуговування",
  minKeywordsScore: "Мін. бал",
  // seller
  unboundAlert:
    "Вибраний користувач прив'язаний до {seller}. Ви впевнені, що хочете відв'язати його і прив'язати до нового?",
  generalInformation: "Загальна інформація",
  companyName: "Назва компанії",
  companyManagers: "Менеджери компанії",
  enterPhoneNumber: "Введіть номер телефону",
  publicPhoneNumber: "Публічний номер телефону",
  notifyEmail: "Email для розсилки",
  notifyEmailPlaceholder: "Введіть email",
  sellerLogo: "Логотип компанії",
  sellerInfo: "Інформація про компанію",
  accessRestrictions: "Доступ і обмеження",
  offersBlock: "Блок пропозицій на маркетплейсі",
  textOnSellerPage: "Текст повідомлення на сторінці продавця",
  blockSyncSum: "Заборонити синхронізацію цін і залишків",
  allowAccessToSeller: "Дозволити доступ в кабінет продавця",
  sellerIsActivated: "Продавець активований",
  additionally: "Додатково",
  sellerHealth: "Здоров'я аккаунту продавця",
  participationInTheBonusProgram: "Участь у бонусній програмі",
  allowGetBonuses: "Дозволити приймати бонуси",
  allow_synthetic_delivery_method: "Дозволити метод доставки в точки видачі Synthetic",
  maxBonuses: "по обмеженню прийому бонусів",
  createSeller: "Створити продавця",
  sellerDescription: "Опис продавця",
  sellerPayments: "Оплата",
  sellerEditing: "Редагування продавця",
  sellerCreating: "Створення продавця",
  sellerCreatingError: "Помилка створення продавця",
  sellerUpdatingError: "Помилка оновлення продавця",
  sellerCreatingSuccess: "Продавець успішно створений",
  sellerUpdatingSuccess: "Продавець успішно оновлений",
  tokenSaved: "Токен збережено",
  tokenDeleted: "Токен видалено",
  monoPartPaymentsSaved: "Покупка частинами monobank збережена",
  monoPartPaymentsDeleted: "Покупка частинами monobank видалена",
  privatPartPaymentsDeleted: "Покупка частинами PrivatBank видалена",
  sellerPhone: "Телефон продавця (власник)",
  positiveReviews: "Позитивних відгуків",
  reviews: "Відгуки",
  cancelledOrders: "Відхилених замовлень",
  closedOrders: "Закритих замовлень",
  product: "Продукт",
  productCount: "Продуктів",
  sellerControl: "Керування продавцями",
  showAllOffers: "Відображати всі пропозиції",
  showMore: "Показати більше",
  hideOffers: "Приховати пропозиції, показати текст",
  sellerPaymentsCard: "Оплата картою",
  sellerPaymentsParts: "Покупка частинами {bank}",
  sellerPaymentsPartsAllow: "Покупка частинами {bank} дозволена",
  // Sync
  syncManagment: "Менеджмент синхронізацій",
  synchronizations: "Синхронізації",
  syncState: "Стан синхронізації",
  syncStatus: "Статус синхронізації",
  compare: "Зіставити",
  processManagement: "Керування процесом",
  totalCountShorted: "Загальна к-сть",
  validCount: "Валідні",
  invalidCount: "Невалідні",
  newCount: "Нові",
  comparedCount: "Зіставлені",
  approvedCount: "Підтверджені",
  activeCount: "Активні",
  lastSyncShorted: "Остання синх-ція",
  syncIsActive: "Активна",
  syncIsDisabled: "Вимкнена",
  sync_status_ERROR: "З помилками",
  sync_status_FAILURE: "Не відбулась",
  sync_status_SUCCESS: "Успішно",
  syncDeletion: "Видалення синхронізації",
  matchWarning: 'Зіставлення недоступне для синхронізацій зі статусом "Не відбулась"',
  syncMatchStarted: "Зіставлення продавців запущено",
  syncMatchSuccess: "Зіставлення продавця {seller} успішно виконано",
  syncMatchError: "Виникла помилка при зіставленні продавця {seller}",
  approveWarning: 'Підтверження недоступне для синхронізацій зі статусом "Не відбулась"',
  syncUpdateStarted: "Cинхронізація продавця {seller} почалась",
  syncUpdateSuccess: "Cинхронізація продавця {seller} успішно оновлена",
  syncUpdateError: "Виникла помилка при оновлення синхронізації продавця {seller}",
  syncApproveStarted: "Підтверження продавців запущено",
  syncApproveSuccess: "Підтверження продавця {seller} успішно виконано",
  syncApproveProgress: "Підтвердження продавця {seller}",
  syncApproveError: "Виникла помилка при підтвердженні продавця {seller}",
  syncReport: "Звіт синхронізації",
  activated: "Активовано",
  deactivated: "Призупинено",
  syncTableTooltips: {
    last_sync_total: "Загальна к-сть товарів у файлі з унікальним offer_id",
    last_sync_count: "К-сть товарів, за якими можливе (доступне) створення пропозиції",
    last_sync_failure_count:
      "К-сть товарів, за якими не можливе створення пропозиції, та % відносно загальної к-сті товарів",
    last_sync_new_count: "К-сть валідних не зіставлених пропозицій",
    matched: "К-сть пропозицій, які вже прив’язані до товару",
    confirmed: "К-сть зіставлених та підтверджених пропозицій",
    active: "К-сть пропозицій, що є в наявності та продаються"
  },
  downloadReport: "Завантажити звіт",
  downloadReportWillStart: "Завантаження звіту скоро почнеться",
  sellerActivity: "Активність продавця",
  waitForMatching: "Очікують зіст.",
  syncXmlLink: "Посилання на xml-файл",
  sellerIsActive: "Активний",
  sellerIsInactive: "Неактивний",
  // Locations
  locationsSettings: "Міста",
  addRecord: "Додати запис",
  lookUp: "Переглянути",
  postOffice: "Відділень | Відділення | Відділення | Відділень",
  deletionWarningTitle: "Ви впевнені, що хочете видалити?",
  deletionWarningMessage: "Ця дія незворотня",
  chooseTypeForEdit: "Виберіть тип для редагування",
  koatuuLabel: "КОАТУУ (необов'язкове)",
  chooseCountry: "Виберіть країну",
  chooseState: "Виберіть область",
  chooseDistrict: "Виберіть район",
  fastenTo: "Закріпити до",
  postOfficeOrBox: "Відділення / Поштомат",
  choosePostOfficeOrBox: "Виберіть номер відділення / поштомату",
  address: "Адреса",
  workSchedule: "Графік роботи",
  weightTo: "Вага до, кг",
  maxCost: "Максимальна вартість",
  sizeLimitsBySend: "Ліміти на розміри відправки, см",
  sizeLimitsByReceive: "Ліміти на розміри отримання, см",

  mondayShorted: "Пн",
  tuesdayShorted: "Вт",
  wednesdayShorted: "Ср",
  thursdayShorted: "Чт",
  fridayShorted: "Пт",
  saturdayShorted: "Сб",
  sundayShorted: "Нд",
  withoutLimits: "Без обмежень",
  width: "Ширина",
  height: "Висота",
  length: "Довжина",
  widthShorted: "Ш",
  heightShorted: "В",
  lengthShorted: "Д",
  poshta: "Пошта",
  nova_poshta: "Нова пошта",
  ukr_poshta: "Укрпошта",
  totalPostOfficesCount: "Всього відділень та поштоматів",
  digitalAddress: "Цифрова адреса",
  postOfficeAdding: "Додання відділення",

  locationTypes: {
    COUNTRY: "Країна",
    STATE: "Область",
    DISTRICT: "Район",
    CITY: "Місто",
    SPECIAL_CITY: "Місто особливого значення",
    URBAN: "Селище міського типу",
    SETTLEMENT: "Селище",
    VILLAGE: "Село",
    STREET: "Вулиця"
  },
  postOfficesSettings: "Налаштування поштових відділень",
  index: "Індекс",
  postOffices: "Відділення",
  currentLocationDisabled: "Ця локація деактивована",

  // promo campaigns
  promoCampaigns: "Акції",
  promoCampaignsList: "Список акцій",
  isPromoActive: "Активна",
  isPromoInactive: "Не активна",
  technicalName: "Технічна назва",
  offersCount: "Кількість оферів",
  createPromoCampaign: "Створити акцію",
  promoCampaignActivity: "Активність акції",
  promoCampaignEditing: "Редагування акції",
  addOffers: "Додати офери",
  actualPrice: "Актуальна ціна",

  // promo codes
  promoCodesPageTitle: "Промокоди",
  promoCodeEditing: "Редагування промокоду",
  createPromoCode: "Створити промокод",
  editPromoCode: "Редагувати промокод",
  promoCodesCode: "Код",
  promoCodesPercent: "Відсоток знижки",
  promoCodesStartDate: "Дата старту",
  promoCodesEndDate: "Дата закінчення",
  promoCodeDeleteConfirm: "Ви хочете видалити промокод"
}
