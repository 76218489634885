import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"
import { computed } from "vue"

export default () => {
  const columns = computed(() => [
    {
      key: "small_image",
      dataIndex: "product.images",
      title: i18n.t("photo"),
      scopedSlots: {
        customRender: "imageRenderer"
      },
      fixed: "left",
      align: "center",
      width: 100
    },
    {
      key: "code",
      dataIndex: "code",
      title: "CS-code",
      fixed: "left",
      width: 140
    },
    {
      key: "name",
      dataIndex: "product.name.uk",
      title: `${i18n.t("offerName")} SPC (UA)`,
      width: 500
    },
    {
      key: "seller",
      dataIndex: "seller",
      title: i18n.t("seller"),
      scopedSlots: { customRender: "sellerRenderer" },
      width: 300
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: i18n.t("countShorted"),
      customRender: (quantity) => `${quantity} ${i18n.t("pieces")}`,
      width: 130
    },
    {
      key: "price",
      dataIndex: "price",
      title: i18n.t("actualPrice"),
      customRender: (price) => `${getFormatedSum(price, true)} ${i18n.t("uah")}`,
      width: 180,
      fixed: "right"
    },
    {
      key: "price_old",
      dataIndex: "price_old",
      title: i18n.t("priceOld"),
      customRender: (price) => `${getFormatedSum(price, true)} ${i18n.t("uah")}`,
      width: 180,
      fixed: "right"
    },
    {
      key: "actions",
      scopedSlots: { customRender: "actions" },
      width: 70,
      fixed: "right"
    }
  ])
  return columns
}
