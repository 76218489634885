const locales = JSON.parse('[{"value":"uk","label":"UA"},{"value":"en","label":"EN"}]')
export default locales

export const localeLabels = {
  uk: "UA",
  ru: "RU",
  en: "EN",
  "uk-UA": "UA",
  "ru-RU": "RU",
  "en-US": "EN"
}
