import i18n from "@/i18n"

const isLowerCase = str => str === str.toLowerCase()
const hasWhiteSpace = str => str.indexOf(' ') >= 0;

export const validateUppercase = (rule, value, callback) => {
  if (isLowerCase(value)) {
    callback(new Error(i18n.t('lowerCaseError')))
  } else {
    callback()
  }
}

export const validateWhiteSpace = (rule, value, callback) => {
  if (hasWhiteSpace(value)) {
    callback(new Error(i18n.t('whiteSpaseError')))
  } else {
    callback()
  }
}