<template>
  <a-modal
    :visible="visible"
    :title="$t('imageEditing')"
    width="90%"
    centered
    @cancel="handleClose"
  >
    <div>
      <div class="cropper-header">
        <div>Width: {{ width }}px</div>
        <div>Height: {{ height }}px</div>

        <a-button
          theme="primary"
          icon="redo"
          @click="handleRotate"
        >
          {{ $t("rotate") }}
        </a-button>
      </div>
    </div>

    <div class="cropper-container">
      <VueCropper
        v-if="imgSrc"
        ref="cropperRef"
        :src="imgSrc"
        :view-mode="1"
        :initialAspectRatio="1"
        :background="true"
        :rotatable="true"
        :img-style="{ width: '100%' }"
        class="cropper-container__item"
        @crop="showDetails"
        @ready="cropImage"
        @cropend="cropImage"
        @zoom="cropImage"
      />

      <div class="cropper-container__item">
        <img
          v-if="cropImg"
          :src="cropImg"
        />
      </div>
    </div>

    <template #footer>
      <a-button
        type="primary"
        :disabled="!enableButton"
        :loading="isProcessing || isCropping"
        @click="onSubmit"
      >
        {{ $t("save") }}
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { computed, onMounted, ref, shallowRef, watch } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import VueCropper from "vue-cropperjs"
import "cropperjs/dist/cropper.css"

import { API_MODERATION } from "@/utils/HttpUtils"
import { updateProductRu } from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import useDebounce from "@/composables/useDebounce"

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  },
  imgSrc: {
    type: String,
    required: true
  },
  imgName: {
    type: String,
    required: true
  },
  product: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["onUpdateImages", "onClose"])

const { debounce, resetDebounce } = useDebounce()

const cropperRef = ref()

const cropImg = ref("")
const width = ref(0)
const height = ref(0)
const isProcessing = shallowRef(false)

const enableButton = computed(() => cropImg.value)

const handleClose = () => emit("onClose")

const showDetails = (imageInfo) => {
  width.value = Math.round(imageInfo.detail.width)
  height.value = Math.round(imageInfo.detail.height)
}

const initCropper = async () => {
  if (!cropperRef.value) return
  if (!props.visible) {
    cropperRef.value.replace(undefined)
    return
  }

  try {
    const response = await fetch(props.imgSrc, {
      method: "GET",
      mode: "cors",
      credentials: "omit",
      cache: "force-cache",
      redirect: "follow"
    })

    const reader = new FileReader()
    reader.onload = (e) => {
      if (cropperRef.value) {
        cropperRef.value.replace(e.target.result)
      }
    }
    reader.readAsDataURL(await response.blob())
  } catch (error) {
    notification.error({
      message: "Error",
      description: error
    })
  }
}

const onSubmit = async () => {
  isProcessing.value = true
  const name = formatName()

  cropperRef.value.getCroppedCanvas().toBlob(async (blob) => {
    const formData = new FormData()

    formData.append(name, blob)

    try {
      const { data } = await API_MODERATION.post("/product-catalog/moderation/images", formData)

      const updatedImgIndex = props.product.images.findIndex(
        (item) => item.filename === props.imgName
      )
      const updatedImages = props.product.images.map((item, index) => {
        if (index === updatedImgIndex) {
          item.filename = data.message[0].filename
        }
        return item
      })

      const payload = {
        code: props.product.code,
        product: {
          images: updatedImages
        }
      }

      emit("onUpdateImages", updatedImages)

      await updateProductRu(payload)

      notification.success({
        message: i18n.t("imageUpdated")
      })
    } catch (error) {
      notifyResponseError({
        message: i18n.t("imageEditingFailed"),
        error
      })
    } finally {
      isProcessing.value = false
      emit("onClose")
    }
  })
}
const formatName = () => {
  let name = props.imgName
  // keep only last part after slash
  const slashIndex = name.lastIndexOf("/")

  if (slashIndex) name = name.substring(slashIndex + 1)

  return name
}

const isCropping = shallowRef(false)
const cropImage = () => {
  resetDebounce("crop")
  isCropping.value = true

  debounce(
    "crop",
    () => {
      cropImg.value = cropperRef.value?.getCroppedCanvas().toDataURL()
      isCropping.value = false
    },
    300
  )
}
const handleRotate = () => {
  cropperRef.value?.rotate(90)
  cropImage()
}
watch(() => props.visible, initCropper)
onMounted(initCropper)
</script>

<style lang="scss" scoped>
.cropper-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding-bottom: 24px;
}

.cropper-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 32px;

  &__item {
    width: 40%;
    aspect-ratio: 1;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
