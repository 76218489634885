<template>
  <div class="layout-content">
    <div class="layout-content__container">
      <a-spin :spinning="fetching">
        <slot></slot>
      </a-spin>
    </div>
  </div>
</template>

<script>
export default {
  name: "Content",
  props: {
    fetching: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-content {
  padding: 0;
  margin-top: 0;

  &__container {
    width: 100%;
    margin: 0 auto;
    padding: 0 30px 30px;
  }
}
</style>
