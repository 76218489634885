<template>
  <a-form
    :form="generalSettingsForm"
    class="general-settings"
  >
    <a-form-item
      :label="$t('categoryState')"
      class="activity-card"
    >
      <a-radio-group v-decorator="['is_active']">
        <a-radio-button :value="true">{{ $t("active") }}</a-radio-button>

        <a-radio-button :value="false">{{ $t("inactive") }}</a-radio-button>
      </a-radio-group>
    </a-form-item>

    <div class="general-settings__top">
      <div
        v-if="image || newImageFile"
        class="general-settings__top__image"
      >
        <img :src="parseFullImagePath" />
        <div class="general-settings__top__image__actions">
          <a-icon
            type="delete"
            @click="handleDeleteImage"
          />
          <a-icon
            type="edit"
            @click="chooseImage"
          />
        </div>
      </div>

      <!-- IMAGE -->
      <div
        class="general-settings__top__image"
        :style="{ display: image || newImageFile ? 'none' : 'block' }"
      >
        <input
          type="file"
          accept=".jpg,.jpeg,.png"
          ref="fileInput"
          @change="handleAddImage"
        />
        <div
          class="general-settings__top__image__placeholder"
          @click="chooseImage"
        >
          <a-icon type="plus" />
          {{ $t("upload") }}
        </div>
      </div>

      <!-- NAME -->
      <div class="general-settings__top__names">
        <a-form-item :colon="false">
          <template #label
            >{{ $t("name") }}, UA (<span :class="{ 'red-text': ukNameLength > nameMaxLength }">{{
              ukNameLength
            }}</span
            >/{{ nameMaxLength }})
          </template>

          <a-input
            v-decorator="['name.uk', { rules: nameRules }]"
            @change="(e) => onNameChange(e, 'ukNameLength')"
          />
        </a-form-item>

        <a-form-item :colon="false">
          <template #label
            >{{ $t("name") }}, EN (<span :class="{ 'red-text': enNameLength > nameMaxLength }">{{
              enNameLength
            }}</span
            >/{{ nameMaxLength }})
          </template>

          <a-input
            v-decorator="['name.en', { rules: nameRules }]"
            @change="(e) => onNameChange(e, 'enNameLength')"
          />
        </a-form-item>

        <a-form-item :colon="false">
          <template #label
            >{{ $t("name") }}, RU (<span :class="{ 'red-text': ruNameLength > nameMaxLength }">{{
              ruNameLength
            }}</span
            >/{{ nameMaxLength }})
          </template>

          <a-input
            v-decorator="['name.ru', { rules: nameRules }]"
            @change="(e) => onNameChange(e, 'ruNameLength')"
          />
        </a-form-item>
      </div>

      <!-- SHOW VALUES -->
      <div class="general-settings__top__additional">
        <a-form-item :label="$t('showValuesForThisCategory')">
          <a-checkbox
            v-for="item in showInCategoryOptions"
            v-decorator="[`${item.value}`, { valuePropName: 'checked', initialValue: false }]"
            :key="item.value"
            style="margin: 0; width: 100%"
          >
            {{ item.label }}
          </a-checkbox>
        </a-form-item>

        <a-form-item :label="$t('parentCategory')">
          <a-tree-select
            v-decorator="['parent']"
            :treeData="categoriesTree"
            showSearch
            :dropdownStyle="{ maxHeight: '400px', paddingRight: '20px', overflowX: 'hidden' }"
            :placeholder="$t('category')"
            allowClear
            :replaceFields="{ value: 'id' }"
            treeNodeFilterProp="title"
          />
        </a-form-item>
      </div>
    </div>

    <!-- ICON AND NOTE -->
    <div class="general-settings__bottom">
      <a-form-item
        :label="$t('icon')"
        :colon="false"
        class="general-settings__bottom__icon"
      >
        <a-input v-decorator="['icon', { initialValue: '', rules: iconRules }]" />
      </a-form-item>

      <a-form-item
        :label="`${$t('note')} (${descriptionLength}/${descriptionMaxLength})`"
        :colon="false"
      >
        <a-textarea
          v-decorator="['description']"
          :autoSize="{ minRows: 4, maxRows: 8 }"
          @change="(e) => (descriptionLength = e.target.value.length || 0)"
        />
      </a-form-item>
    </div>
  </a-form>
</template>

<script>
import getImagePath from "utils/getImagePath"
import { IMAGES_CDN } from "@/constants/common"
import useCategories from "@/composables/useCategories"
import { onMounted } from "vue"

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ""
    },
    is_active: {
      type: Boolean,
      default: false
    },
    name: {
      type: Object,
      default: () => ({})
    },
    parent: {
      type: Number,
      default: null
    },
    is_shown_condition: {
      type: Boolean,
      default: false
    },
    is_shown_warranty: {
      type: Boolean,
      default: false
    },
    is_available_return: {
      type: Boolean,
      default: false
    },
    ar_d: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  },

  setup() {
    const { categoriesTree, isCategoriesFetching, getCategories } = useCategories({
      selectable: "all"
    })

    onMounted(getCategories)

    return { categoriesTree, isCategoriesFetching }
  },

  data() {
    return {
      generalSettingsForm: this.$form.createForm(this, {
        name: "generalSettingsForm",
        mapPropsToFields: () => {
          return {
            is_active: this.$form.createFormField({
              value: this.is_active
            }),
            "name.uk": this.$form.createFormField({
              value: this.name.uk
            }),
            "name.en": this.$form.createFormField({
              value: this.name.en
            }),
            "name.ru": this.$form.createFormField({
              value: this.name.ru
            }),
            is_shown_condition: this.$form.createFormField({
              value: this.is_shown_condition
            }),
            is_shown_warranty: this.$form.createFormField({
              value: this.is_shown_warranty
            }),
            is_available_return: this.$form.createFormField({
              value: this.is_available_return
            }),
            ar_d: this.$form.createFormField({
              value: this.ar_d
            }),
            parent: this.$form.createFormField({
              value: +this.$route.query.parent || this.parent
            }),
            icon: this.$form.createFormField({
              value: this.icon
            }),
            description: this.$form.createFormField({
              value: this.description
            })
          }
        },
        onValuesChange: () => {
          this.$emit("formChanged")
        }
      }),

      nameMaxLength: 200,
      descriptionMaxLength: 1000,

      ukNameLength: 0,
      enNameLength: 0,
      ruNameLength: 0,
      descriptionLength: 0,

      newImageFile: null,
      newImagePreview: null,
      initialFormValues: {}
    }
  },

  mounted() {
    this.ukNameLength = this.name?.uk?.length || 0
    this.enNameLength = this.name?.en?.length || 0
    this.ruNameLength = this.name?.ru?.length || 0
  },

  computed: {
    nameRules() {
      return [
        {
          required: true,
          message: this.$t("emptyError")
        },
        {
          max: this.nameMaxLength,
          message: this.$t("maxLengthError", { length: this.nameMaxLength })
        }
      ]
    },

    iconRules() {
      return this.parent || this.generalSettingsForm.getFieldValue("parent")
        ? []
        : [{ required: true, message: this.$t("emptyError") }]
    },

    showInCategoryOptions() {
      return [
        {
          label: this.$t("productCondition"),
          value: "is_shown_condition"
        },
        {
          label: this.$t("guaranteePeriod"),
          value: "is_shown_warranty"
        },
        {
          label: this.$t("purchaseReturns"),
          value: "is_available_return"
        },
        {
          label: "18+",
          value: "ar_d"
        }
      ]
    },

    parseFullImagePath() {
      return this.image
        ? `${IMAGES_CDN}/media/assets/images/${getImagePath(this.image, "full")}`
        : this.newImagePreview
    }
  },

  methods: {
    onNameChange(e, nameLengthValue) {
      this[nameLengthValue] = e?.target?.value.length || 0
    },

    chooseImage() {
      this.$refs.fileInput.click()
    },

    handleAddImage(e) {
      this.$emit("formChanged")
      let file = e.target.files.item(0)

      this.newImageFile = file

      let fileReader = new FileReader()

      fileReader.addEventListener("load", (event) => {
        this.newImagePreview = event.target.result
      })

      fileReader.readAsDataURL(file)
    },

    handleDeleteImage() {
      if (this.image) {
        this.$emit("deleteImage")
      } else {
        this.newImageFile = null
        this.newImagePreview = null
      }
    },

    getFields() {
      let fields = {}

      this.generalSettingsForm.validateFields((err, val) => {
        if (err) {
          fields = null
        } else {
          const { parent, ...otherFields } = val

          fields = otherFields

          if (this.newImageFile) {
            fields.image = { img: this.newImageFile }
          } else if (!this.image) {
            fields.image = { img: "" }
          }
          if (parent) {
            fields.parent = parent
          }
        }
      })

      return fields
    }
  }
}
</script>

<style lang="scss" scoped>
.general-settings {
  display: flex;
  flex-flow: column nowrap;
  max-width: 1420px;
  margin: 0 auto;

  & > .activity-card {
    position: absolute;
    width: 360px;
    margin-left: auto;
    border: 1px solid $border-color;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    top: -140px;
    right: 0;
    border-radius: 2px;
  }

  &__top {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    gap: 40px;

    &__image {
      position: relative;
      height: 285px;
      width: 285px;
      border: 1px solid $border-color;
      border-radius: 4px;
      padding: 12px;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      & > input[type="file"] {
        opacity: 0;
      }

      &__actions {
        position: absolute;
        top: 8px;
        right: 8px;
        display: flex;
        flex-flow: row nowrap;
        gap: 8px;
        & > * {
          background-color: $font-default;
          color: $default-color;
          padding: 4px;
          border-radius: 2px;
          transition: 0.1s;
          opacity: 0.75;
          &:hover {
            opacity: 1;
          }
        }
      }

      &__placeholder {
        position: absolute;
        top: 12px;
        left: 12px;
        width: calc(100% - 24px);
        height: calc(100% - 24px);
        z-index: 1;
        background: $default-color;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: $grey-font;
        border: 1px dashed $default-border;
        border-radius: 2px;
        cursor: pointer;
        & > :first-child {
          font-size: 24px;
          color: $font-default;
        }
      }
    }

    &__names {
      width: 40%;
    }

    &__additional {
      width: 20%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }
  }
  &__bottom {
    &__icon {
      width: 220px;
    }
  }
  .red-text {
    color: $red-color;
    accent-color: $red-color;
  }
}
</style>
