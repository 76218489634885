<template>
  <div>
    <div class="table-header">
      <a-button
        type="primary"
        icon="link"
        @click="onShowAddModal"
      >
        {{ $t("toTether") }}
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :pagination="paginationInfo"
      :loading="isFetching || isTypeChanging"
      rowKey="code"
      :scroll="{ x: true }"
      class="attribute-types-table"
      @change="onTableChange"
    >
      <template #isMainTitleRenderer>
        <a-tooltip :title="$t('attributeIsMain')">M</a-tooltip>
      </template>

      <template #isRequiredTitleRenderer>
        <a-tooltip :title="$t('attributeIsRequired')">R</a-tooltip>
      </template>

      <template #isMainRenderer="record">
        <a-checkbox
          :checked="record.is_main"
          @click="onIsMainChange(record)"
        />
      </template>

      <template #isRequiredRenderer="record">
        <a-checkbox
          :checked="record.is_required"
          @click="onIsRequiredChange(record)"
        />
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          shape="circle"
          icon="delete"
          class="delete-button"
          @click="onUnattachType(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="showAddTypeModal"
      :title="$t('tetherAttributeToType')"
      :width="500"
      destroyOnClose
      @close="onHideAddModal"
    >
      <AttachTypeForm
        :attribute="attribute"
        @submit="onTableChange()"
        @close="onHideAddModal"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import useAntTableQuery from "@/composables/useAntTableQuery"
import useAttributeTypesColumns from "../../composables/useAttributeTypesColumns"
import { onMounted, shallowRef } from "vue"
import AttachTypeForm from "./AttachTypeForm.vue"
import {
  fetchAttributeAttachedTypes,
  unattachTypeFromAttribute,
  updateAttributeAttachedType
} from "../../../services/attributesService"
import notifyResponseError from "@/utils/notifyResponseError"
import { Modal, notification } from "ant-design-vue"
import i18n from "@/i18n"

const props = defineProps({
  attribute: {
    type: Object,
    default: () => ({})
  }
})

const { columns } = useAttributeTypesColumns()
const { dataSource, paginationInfo, filteredInfo, isFetching, fetchTableInfo } = useAntTableQuery({
  queryFunction: fetchAttributeAttachedTypes,
  requestParams: { attributeCode: props.attribute.code }
})
const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value })
}

const showAddTypeModal = shallowRef(false)

const onShowAddModal = () => {
  showAddTypeModal.value = true
}

const onHideAddModal = () => {
  showAddTypeModal.value = false
}

const isTypeChanging = shallowRef(false)

const updateRecordAttribute = async (record, payloadKey) => {
  try {
    isTypeChanging.value = true

    const updatedValue = !record[payloadKey]
    await updateAttributeAttachedType({
      attributeCode: props.attribute.code,
      typeCode: record.code,
      payload: { [payloadKey]: updatedValue }
    })

    record[payloadKey] = updatedValue
    notification.success({ message: i18n.t("success") })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isTypeChanging.value = false
  }
}

const onIsMainChange = async (record) => {
  updateRecordAttribute(record, "is_main")
}

const onIsRequiredChange = async (record) => {
  updateRecordAttribute(record, "is_required")
}

const onUnattachType = (record) => {
  Modal.confirm({
    title: i18n.t("confirmToUnattachType"),
    content: i18n.t("deletionWarningMessage"),
    okText: i18n.t("yes"),
    okType: "danger",
    cancelText: i18n.t("no"),
    onOk: () => handleUnattachType(record)
  })
}

const handleUnattachType = async (record) => {
  try {
    isTypeChanging.value = true

    await unattachTypeFromAttribute({ attributeCode: props.attribute.code, typeCode: record.code })

    notification.success({ message: i18n.t("deleted") })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isTypeChanging.value = false
  }
}

onMounted(() => onTableChange())
</script>

<style lang="scss" scoped>
.attribute-types-table {
  max-width: 1020px;
}

.delete-button {
  color: $red-color;
  border-color: $red-color;
}
</style>
