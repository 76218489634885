import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const fetchBrands = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/brands${getFormatedQueryParams(queryParams)}`, { signal })
}

export const updateBrand = (data, id) => {
  return API.patch(`/mp-admin/brands/${id}/`, data)
}

export const fetchSynonyms = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/synonyms${getFormatedQueryParams(queryParams)}`, { signal })
}

export const updateSynonyms = (data, id) => {
  return API.patch(`/mp-admin/synonyms/${id}/`, data)
}

export const createSynonyms = (data) => {
  return API.post("/mp-admin/synonyms", data)
}
