/* eslint-disable */
// @ts-ignore
import Vue from "vue"
import "ant-design-vue/dist/antd.css"
import Antd from "ant-design-vue"
import VueDragscroll from "vue-dragscroll"
import i18n from "./i18n"

import App from "./App.vue"
import { store } from "./store/store"
import router from "./router"

Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(VueDragscroll)

new Vue({
  el: "#app",
  store,
  i18n,
  router: router,
  render: (h) => h(App)
})
