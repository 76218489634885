import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo = {} } = { sortedInfo: {} }) => {
  const columns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("creatingDate"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
      fixed: "left",
      width: 200
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("technicalName"),
      fixed: "left",
      width: 250
    },
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("promoCodesCode"),
      fixed: "left",
      width: 200
    },
    {
      key: "starts_at",
      dataIndex: "starts_at",
      title: i18n.t("promoCodesStartDate"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      fixed: "left",
      width: 150
    },
    {
      key: "ends_at",
      dataIndex: "ends_at",
      title: i18n.t("promoCodesEndDate"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      fixed: "left",
      width: 150
    },
    {
      key: "discount_percent",
      dataIndex: "discount_percent",
      customRender: (count) => `${count}%`,
      title: i18n.t("promoCodesPercent"),
      align: "center",
      fixed: "left",
      width: 145
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 120
    }
  ])

  return columns
}
