import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const paidStatusOptions = computed(() => {
    return [
      {
        label: i18n.t("new"),
        value: "NEW"
      },
      {
        label: i18n.t("canceledStatus"),
        value: "CANCELED"
      },
      {
        label: i18n.t("deleted"),
        value: "REMOVED"
      },
      {
        label: i18n.t("paid"),
        value: "PAID"
      }
    ]
  })

  const transactionTypes = computed(() => {
    return [
      {
        label: i18n.t("creatingBonuses"),
        value: "SUPER_USER_BILL_REPLENISHMENT"
      },
      {
        label: i18n.t("transferBonusesToUser"),
        value: "USER_BILL_REPLENISHMENT"
      },
      {
        label: i18n.t("sellerDebit"),
        value: "SELLER_BILL_REPLENISHMENT"
      },
      {
        label: i18n.t("cashBack"),
        value: "CASHBACK"
      },
      {
        label: i18n.t("orderCancelled"),
        value: "ORDER_CANCELLATION"
      }
    ]
  })

  const negativeOperationTypes = [
    "USER_BILL_REPLENISHMENT",
    "CASHBACK",
    "TARIFF_CHANGE",
    "ADVERTS",
    "SUBSCRIPTION_CONTINUE"
  ]

  const filterTypeOptions = computed(() => {
    return [
      {
        label: i18n.t("checkbox"),
        value: "checkbox"
      },
      {
        label: "Boolean",
        value: "bool"
      },
      {
        label: i18n.t("rangeInput"),
        value: "range_input"
      },
      {
        label: i18n.t("range"),
        value: "custom_range_filter"
      },
      {
        label: i18n.t("slider"),
        value: "slider"
      },
      {
        label: i18n.t("tile"),
        value: "tile"
      }
    ]
  })

  const syncStatuses = computed(() => {
    return [
      {
        label: i18n.t("sync_status_ERROR"),
        value: "ERROR"
      },
      {
        label: i18n.t("sync_status_SUCCESS"),
        value: "SUCCESS"
      },
      {
        label: i18n.t("sync_status_FAILURE"),
        value: "FAILURE"
      }
    ]
  })

  const isActiveOptions = computed(() => {
    return [
      {
        label: i18n.t("active"),
        value: "true"
      },
      {
        label: i18n.t("disabled"),
        value: "false"
      }
    ]
  })

  const syncActiveOptions = computed(() => {
    return [
      {
        label: i18n.t("syncIsActive"),
        value: "true"
      },
      {
        label: i18n.t("syncIsDisabled"),
        value: "false"
      }
    ]
  })

  const sellerActiveOptions = computed(() => {
    return [
      {
        label: i18n.t("sellerIsActive"),
        value: "true"
      },
      {
        label: i18n.t("sellerIsInactive"),
        value: "false"
      }
    ]
  })

  const returnStatuses = computed(() => {
    return [
      {
        label: i18n.t("orderStatuses.new"),
        value: "NEW",
        color: "#52C41A"
      },
      {
        label: i18n.t("approved"),
        value: "SELLER_APPROVED",
        color: "#36CFC9"
      },
      {
        label: i18n.t("orderStatuses.canceled_by_seller"),
        value: "CANCELED_BY_SELLER",
        color: "#FF4D4F"
      },
      {
        label: i18n.t("orderStatuses.canceled_by_customer"),
        value: "CANCELED_BY_CUSTOMER",
        color: "#722ED1"
      },
      {
        label: i18n.t("orderStatuses.closed"),
        value: "CLOSED",
        color: "#000000"
      }
    ]
  })

  const returnProductAims = computed(() => {
    return [
      {
        label: i18n.t("returnProductsRefunds"),
        value: "RETURN_PRODUCT_REFUNDS"
      },
      {
        label: i18n.t("exchangeProduct"),
        value: "EXCHANGE_PRODUCT"
      },
      {
        label: i18n.t("repair"),
        value: "REPAIR_PRODUCT"
      }
    ]
  })

  const customerEmailOptions = computed(() => {
    return [
      {
        label: i18n.t("confirmed"),
        value: "true"
      },
      {
        label: i18n.t("setted"),
        value: "false"
      }
    ]
  })

  const customerStatusOptions = computed(() => {
    return [
      {
        label: i18n.t("active"),
        value: "false"
      },
      {
        label: i18n.t("blocked"),
        value: "true"
      }
    ]
  })

  return {
    paidStatusOptions,
    transactionTypes,
    negativeOperationTypes,
    returnStatuses,
    returnProductAims,
    customerEmailOptions,
    customerStatusOptions,
    filterTypeOptions,
    syncStatuses,
    isActiveOptions,
    syncActiveOptions,
    sellerActiveOptions
  }
}
