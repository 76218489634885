<template>
  <a-descriptions
    :column="1"
    bordered
    size="middle"
    class="tableOrderInformation"
  >
    <a-descriptions-item v-if="orderData.delivery_method">
      <template #label>{{ $t("deliveryMethod") }}</template>
      {{ orderData.delivery_method.name.uk }}
    </a-descriptions-item>

    <a-descriptions-item v-if="orderData.payment_method">
      <template #label>{{ $t("payment_method") }}</template>

      <div style="width: 100%; display: flex; justify-content: space-between;">
        <div>{{ orderData.payment_method.name.uk }}</div>
        <div
          v-if="orderData.payment_status"
          :style="{ color: paymentStatuses[orderData.payment_status].color }"
        >
          {{ paymentStatuses[orderData.payment_status].title }}
        </div>
      </div>
    </a-descriptions-item>

    <a-descriptions-item v-if="orderData.ttn">
      <template #label>{{ $t("ttn") }}</template>
      <div :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">
        <a-input-group
          compact
          :style="{ width: '50%', display: 'flex' }"
        >
          <a-input
            :disabled="!isEditingTtn"
            v-model="newTtn"
          />

          <a-button
            v-if="isEditingTtn"
            type="danger"
            @click="onResetTtn()"
          >
            <a-icon type="undo" />
          </a-button>

          <a-button @click="onEditTtn()">
            <a-icon :type="isEditingTtn ? 'check' : 'edit'" />
          </a-button>
        </a-input-group>

        <div
          v-if="orderData.ttn_added_at"
          :style="{ opacity: '0.45' }"
        >
          {{ $t("added") }} {{ getFormatedDateTime(orderData.ttn_added_at) }}
        </div>
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <template #label>{{ $t("buyerInformation") }}</template>
      <div v-html="buyerInfo" />
    </a-descriptions-item>

    <a-descriptions-item>
      <template #label>{{ $t("receiverInformation") }}</template>
      <div v-html="receiverInfo" />
    </a-descriptions-item>

    <a-descriptions-item>
      <template #label>{{ $t("buyerComment") }}</template>
      {{ orderData.comment_customer }}
    </a-descriptions-item>
    
    <a-descriptions-item>
      <template #label>{{ $t("promoCodeTitle") }}</template>
      {{ orderData.promo_code }}
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { ref } from "vue"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import { useStatuses } from "@/composables/useStatuses"

export default {
  name: "OrderInformation",
  props: {
    orderData: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const newTtn = ref(props.orderData.ttn)
    const isEditingTtn = ref(false)
    const { paymentStatuses } = useStatuses()

    return {
      paymentStatuses,
      newTtn,
      isEditingTtn
    }
  },

  computed: {
    buyerInfo() {
      const fullNameArray = []
      const buyerInfo = []

      fullNameArray.push(this.orderData.customer.first_name)
      fullNameArray.push(this.orderData.customer.middle_name)
      fullNameArray.push(this.orderData.customer.last_name)

      const fullNameString = fullNameArray.filter((s) => s).join(" ")

      // Add name
      if (fullNameString.length) {
        buyerInfo.push(fullNameString)
      }
      // Add phone
      if (this.orderData.customer.mobile_phone) {
        // If customer not registered show receiver mobile phone
        const phone = this.orderData.customer.is_active
          ? this.orderData.customer.mobile_phone
          : this.orderData.receiver_mobile_phone

        buyerInfo.push(`${this.$t("phoneShorted").toLowerCase()}:&nbsp;${phone}`)
      }
      // Add email
      if (this.orderData.customer && this.orderData.customer.email) {
        buyerInfo.push(`email:&nbsp;${this.orderData.customer.email}`)
      }

      return buyerInfo.join(", ")
    },

    receiverInfo() {
      const fullNameArray = []

      fullNameArray.push(this.orderData.receiver_first_name)
      fullNameArray.push(this.orderData.receiver_middle_name)
      fullNameArray.push(this.orderData.receiver_last_name)

      const fullNameString = fullNameArray.filter((s) => s).join(" ")
      const receiverInfo = []

      // Add name
      if (fullNameString.length) receiverInfo.push(fullNameString)
      // Add location
      if (this.orderData.location) receiverInfo.push(this.orderData.location)
      // Add phone
      if (this.orderData.receiver_mobile_phone)
        receiverInfo.push(
          `${this.$t("phoneShorted").toLowerCase()}:&nbsp;${this.orderData.receiver_mobile_phone}`
        )

      return receiverInfo.join(", ")
    }
  },

  methods: {
    getFormatedDateTime,

    onResetTtn() {
      this.newTtn = this.orderData.ttn
      this.isEditingTtn = !this.isEditingTtn
    },

    onEditTtn() {
      this.isEditingTtn = !this.isEditingTtn
    }
  }
}
</script>

<style lang="scss">
.tableOrderInformation {
  .ant-descriptions-item-label {
    font-weight: 500;
    padding: 12px !important;
    white-space: nowrap;
    background: transparent;
  }

  .ant-descriptions-item-content {
    padding: 12px !important;
    color: $font-default;
  }
}
</style>
