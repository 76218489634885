import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const getRoles = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/users-management/roles${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchRoleByID = ({ id }) => {
  return API.get(`/users-management/roles/${id}`)
}

export const updateRole = ({ id, params }) => {
  return API.put(`/users-management/roles/${id}`, params)
}

export const createRole = (payload) => {
  return API.post("/users-management/roles", payload)
}

export const fetchPermissionModels = () => {
  return API.get("/users-management/permissions/models")
}

export const fetchPermissionApps = () => {
  return API.get("/users-management/permissions/applications")
}
