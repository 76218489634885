import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const columns = computed(() => [
    {
      key: "handle",
      dataIndex: "handle",
      width: 50,
      scopedSlots: {
        customRender: "handleRenderer"
      }
    },
    {
      key: "index",
      width: 60,
      customRender: (_, __, index) => index + 1
    },
    {
      key: "code",
      dataIndex: "code",
      title: "AND-code",
      scopedSlots: {
        customRender: "codeRenderer"
      },
      width: 140
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("name"),
      width: 525
    },
    {
      key: "value",
      title: i18n.t("value"),
      scopedSlots: {
        customRender: "valueRenderer"
      },
      width: 525
    },
    {
      key: "type",
      dataIndex: "value_type",
      slots: {
        title: "typeTitleRenderer"
      },
      scopedSlots: {
        customRender: "typeRenderer"
      },
      width: 50
    },
    {
      key: "is_main",
      dataIndex: "is_main",
      slots: {
        title: "mainTitleRenderer"
      },
      scopedSlots: {
        customRender: "booleanTooltipRenderer"
      },
      width: 50
    },
    {
      key: "is_required",
      dataIndex: "is_required",
      slots: { title: "requiredTitleRenderer" },
      scopedSlots: {
        customRender: "booleanTooltipRenderer"
      },
      width: 50
    },
    {
      key: "trust",
      dataIndex: "trust",
      slots: { title: "statusTitleRenderer" },
      scopedSlots: {
        customRender: "trustRenderer"
      },
      width: 50
    },
    {
      key: "actions",
      width: 50,
      scopedSlots: {
        customRender: "actionsRenderer"
      }
    }
  ])

  return { columns }
}
