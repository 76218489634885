<template>
  <div>
    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :loading="isFetching"
      :pagination="paginationInfo"
      rowKey="code"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #photoRenderer="images">
        <TableRendererPhotoGallery :images="(images || []).map((img) => img.filename)" />
      </template>

      <template #actionRenderer="record">
        <a-button
          type="dashed"
          shape="circle"
          icon="form"
          @click="goToProductEditing(record)"
        />
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { onMounted } from "vue"
import useProductAttributeValuesColumns from "../../composables/useProductAttributeValuesColumns"
import useAntTableQuery from "@/composables/useAntTableQuery"
import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/TableRendererPhotoGallery.vue"
import { fetchProducts } from "@/modules/Moderation/services/moderationProductsService"
import router from "@/router"

const props = defineProps({
  selectedValue: {
    type: String,
    default: ""
  }
})

const columns = useProductAttributeValuesColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,
  sortedInfo,

  fetchTableInfo,
  setFilteredInfo
} = useAntTableQuery({
  queryFunction: fetchProducts
})
const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, sorter })
}

const goToProductEditing = (record) => {
  const routeData = router.resolve({
    name: "ProductsModeration",
    query: { openProductModal: record.code }
  })
  window.open(routeData.href, "_blank")
}

onMounted(() => {
  setFilteredInfo({ attributes_value: props.selectedValue })
  onTableChange()
})
</script>
