import { API_V2 } from "@/utils/HttpUtils.js"
import { moderationPrefix, getLocalizedHeaders } from "./servicesUtils.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"

export const fetchAttributeValuesList = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/attribute-values/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchLocalisedAttributeValuesList = async ({ queryParams, requestParams, signal }) => {
  if (requestParams.valueType === "NUMERIC") {
    return fetchAttributeValuesList({ queryParams, requestParams, signal })
  }

  try {
    const ruResponse = await fetchAttributeValuesList({
      queryParams,
      requestParams: {
        language: { value: "ru" },
        changeRouterQuery: requestParams?.changeRouterQuery
      },
      signal
    })
    const ukResponse = await fetchAttributeValuesList({
      queryParams,
      requestParams: { language: { value: "uk" } },
      signal
    })

    const enResponse = await fetchAttributeValuesList({
      queryParams,
      requestParams: { language: { value: "en" } },
      signal
    })
    ruResponse.data.results = ruResponse.data.results.map((record, index) => ({
      ...record,
      value: {
        ru: record.value,
        uk: ukResponse.data.results[index].value,
        en: enResponse.data.results[index].value
      }
    }))

    return Promise.resolve(ruResponse)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createValueForAttribute = ({ payload }) => {
  return API_V2.post(`${moderationPrefix}/attribute-values/`, payload, getLocalizedHeaders("ru"))
}

export const updateValueForAttribute = ({ payload, code, language }) => {
  return API_V2.put(
    `${moderationPrefix}/attribute-values/${code}/`,
    payload,
    getLocalizedHeaders(language)
  )
}

export const updateLocalizedValueForAttribute = async ({ payload, code }) => {
  try {
    const { value } = payload
    const ruPayload = { ...payload, value: value.ru }
    const ukPayload = { value: value.uk }
    const enPayload = { value: value.en }

    await updateValueForAttribute({ payload: ruPayload, code, language: "ru" })
    await updateValueForAttribute({ payload: ukPayload, code, language: "uk" })
    await updateValueForAttribute({ payload: enPayload, code, language: "en" })

    return Promise.resolve(payload)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createNewLocalisedAttributeValue = async (payload) => {
  const { value, ...ruPayload } = payload
  const { uk, ru, en } = value

  try {
    const responseRu = await createValueForAttribute({ payload: { ...ruPayload, value: ru } })

    await updateValueForAttribute({
      code: responseRu.data.code,
      payload: { value: uk },
      language: "uk"
    })
    await updateValueForAttribute({
      code: responseRu.data.code,
      payload: { value: en },
      language: "en"
    })

    return Promise.resolve(responseRu.data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteValueForAttribute = ({ code }) => {
  return API_V2.delete(`${moderationPrefix}/attribute-values/${code}/`)
}

export const getImportResponse = async (url, language) => {
  const link = document.createElement("a")
  link.href = url
  link.download = `values_import_result_${language}.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const checkImportStatus = async (task_id, language) => {
  try {
    const { data } = await API_V2.get(
      `${moderationPrefix}/attribute-values/imports/results/${task_id}/`
    )
    if (data.url) {
      getImportResponse(data.url, language)
    } else {
      setTimeout(() => checkImportStatus(task_id, language), 5000)
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const importAttributeValuesFile = async ({ file, language }) => {
  try {
    const payload = new FormData()
    payload.append("update_file", file)

    const { data } = await API_V2.post(
      `${moderationPrefix}/attribute-values/import/`,
      payload,
      getLocalizedHeaders(language)
    )
    await checkImportStatus(data.task_id, language)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export const importLocalizedAttributeValues = async ({ payload }) => {
  try {
    if (payload.ru) {
      await importAttributeValuesFile({ file: payload.ru, language: "ru" })
    }
    if (payload.uk) {
      await importAttributeValuesFile({ file: payload.uk, language: "uk" })
    }
    if (payload.en) {
      await importAttributeValuesFile({ file: payload.en, language: "en" })
    }
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export const exportAttributeValues = async ({ queryParams, language = "ru" }) => {
  const { data } = await API_V2.get(
    `${moderationPrefix}/attribute-values/export/${getFormatedQueryParams(queryParams)}`,
    getLocalizedHeaders(language)
  )

  const blob = new Blob([data], { type: "text/csv;charset=utf-8" })
  const url = URL.createObjectURL(blob)

  const link = document.createElement("a")
  link.href = url
  link.download = `${queryParams.attribute}_values_export_${language}.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const exportLocalizedAttributeValues = async ({ queryParams, language }) => {
  try {
    await exportAttributeValues({ queryParams, language: "ru" })
    await exportAttributeValues({ queryParams, language: "uk" })
    await exportAttributeValues({ queryParams, language: "en" })

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
