export default {
  lowerCaseError: 'The value must be in uppercase',
  whiteSpaseError: 'The value must whithout spaces',
  changeRating: "Change rating (file)",
  products_rating_successfully_changed: "Products rating successfully changed",
  //
  billing: "Billing",
  creatingBonuses: "Creation of bonuses on the admin account",
  transferBonusesToUser: "Transfer of bonuses to the user's bonus account",
  sellerDebit: "Topping up the seller's balance",
  cashBack: "Reward for buying from the seller",
  orderCancelled: "Order cancelled",
  tariff: "Tariff | Tariffs",
  newTariff: "New tariff",
  addNote: "Add note",
  orderLimitExceeded: "Order limit exceeded",
  ordersCount: "Orders count",
  amountFreeOrders: "Orders limit and date",
  tariffPlan: "Tariff date and end date",
  chooseTariff: "Choose tariff",
  chooseTariffDate: "Select tariff validity period",
  lastOperationInfo: "Last payment sum and date",
  currentBalance: "Current balance",
  currentTariff: "Current tariff",
  noCurrentTariff: "No current tariff",
  offersVisibilityTitle: "Offers",
  licenseExpireDate: "License expire date",
  freeOrdersLimit: "Free orders limit,",
  newOrderLimit: "New order limit",
  cancelTariff: "Cancel tariff",
  changingTariffPlan: "Changing tariff plan",
  chosingTariffError:
    "The selected tariff cannot be set, the seller has an active paid tariff until",
  expirationDate: "Expiration date",
  tariffPrice: "Tariff price",
  tariffPrices: "Tariff prices",
  returnOfBalance: "Balance return",
  saldoAfterOperation: "Saldo after operation",
  sellerOrders: "Seller oreders",
  lastWeek: "For the last seven days",
  activeOrSelling: "Active / selling",
  tariffPlanBalance: "Tariff plan balance",
  topUp: "Top up",
  writeOff: "Write-off",
  balanceRefill: "Balance refill",
  applySum: "Apply sum",
  comment: "Comment",
  writeOffingCommission: "Commision write-off",
  reason: "Reason",
  withdrawForService: "Withdraw money for service",
  questionToChangeTariff: "You really want to change tariff to",
  questionToCancelTariff: "You really want to cancel tariff",
  costOf: "Cost of",
  operationType: "Operation type",
  operationSum: "Operation sum",
  saldo: "Saldo",
  tariffConfigNotActive: "Tariff configuration is not active",
  courseChange: "Course change",
  bonusTitle: "Bonuses",
  promoCodeTitle: "Promo code",
  bonus: "Bonuses | Bonus | Bonuses",
  applyNeeded: "Need apply",
  bonusCourseAlert: "Wanring! Bonus course will be changed at 100 bonuses = {value} uah",
  bonusCreating: "Bonus creating",
  enterSum: "Enter a sum",
  courseHistory: "Course history",
  transferBonuses: "Transfer bonuses",
  recipientSearch: "Recipient search",
  recipient: "Recipient",
  adminTransactionsHistory: "History of transactions Admin balance",
  bonusesAndAdministration: "Bonuses. Administrations",
  adminBalance: "Admin balance",
  totalCreated: "Total created",
  totalUserBalance: "Total user balance",
  totalReserved: "Reserved of it",
  currentCourse: "Current course",
  balanceReplenished: "Balance replenished successfully",
  tariffCanceled: "Tariff canceled",
  commissionDebited: "Commission successfully debited",
  freeOrdersLimitChanged: "Free orders limit changed",
  bonusRateChanged: "Bonus rate successfully changed",
  someBonusesCreated:
    "bonuses successfully created | bonus successfully created | bonuses successfully created",
  someBonusesTransfered:
    "bonuses successfully transfered to chosen user | bonus successfully transfered to chosen user | bonuses successfully transfered to chosen user",
  categoryCreated: "Category successfully created",
  addCategory: "Add category",
  catalogModeration: "Catalog moderation",
  categoryEditing: "Editing category",
  categoryCreating: "Creating category",
  startPrint: "Start print",
  parentCategory: "Parent category",
  offersInCategory: "Offers in category",
  showValuesForThisCategory: "Show value for products in this category",
  productCondition: "Product condition",
  guaranteePeriod: "Guarantee period",
  purchaseReturns: "Purchase returns",
  noSettings: "No settings",
  deleteCategory: "Delete category",
  deleteCategoryAlert: "Are you sure you want to delete the category",
  commaSeparated: "Comma separated",
  indicateNameAlert: "Enter the category name in all languages, from 2 to 100 characters",
  descriptionLengthAlert: "The description should not exceed 1000 characters",
  uniqFilterNameAlert: "The name of the filters must be unique",
  editingCustomRangeFilter: "Editing the filter range",
  filterCondition: "Filter condition",
  valueInTheBaseUnit: "The value of the condition in the base unit of measurement",
  baseUnitError: `Failed to get the unit of measurement, most likely the filter type is not "numeric", in this case the ranges will not be displayed in the marketplace, please check attribute settings`,
  getBaseUnit: "Get base unit",
  baseUnitFetched: "Base unit has gotten",
  baseUnitFetchError: "Can't get base unit",
  offersInRange: "Offers in the range",
  downloadFileWithOffers: "Download file with offers",
  calculateCount: "Calculate count",
  baseValueInAttribute: 'The basic unit of measurement in the attribute "{name}" - ',
  clickToFindOut: "Click to find out",
  calculatingIsSuccess: "Calculating is success",
  reindexSuccess: "Reindex started",
  reindexFailure: "Reindex is failure",
  downloadCategoryReportWillStart: "The report will start downloading soon",
  // general settings
  generalSettings: "General settings",
  tariffPlanSettings: "Tariff plan settings",
  addTariffPlan: "Add tariff",
  configurations: "Configurations",
  addConfiguration: "Add configuration",
  autoRenewTariff: "Autorenew of tariff",
  avaliableSellerToBuy: "Avaliable for seller for purchase",
  activeOffersCount: "Cnt of active pruposes in tariff",
  fillName: "Fill name",
  fillExpire: "Fill expire date",
  expireInDays: "Expire (days)",
  fullPrice: "Full price (uah)",
  pricePerMonth: "Price per month",
  tariffName: "Tariff name",
  confAndPrice: "Configuration and price",
  reasonRefuse: "Order refuse reason",
  editingReasonRefuse: "Edit refuse reason",
  creatingReasonRefuse: "Create refuse reason",
  avaliableFor: "Avaliable for",
  ordersToNull: "Reset the balance of goods on the order",
  refuseNameAlert: "Enter the name of the reason for the refuse, from 2 to 200 characters",
  avaliableRefuseForAlert: "Choose a reason for the refusal to whom it is available",
  editingDeliveryMethods: "Editing shipping methods",
  creatingDeliveryMethods: "Creating shipping methods",
  chooseType: "Choose type",
  deliveryType: "Delivery type",
  deliveryService: "Delivery service",
  deliveryServiceActivity: "Delivery service showing",
  chooseDeliveryType: "Choose delivery type",
  chooseDeliveryService: "Choose delivery service",
  outputSortOrder: "Output sort order",
  takeFilteringBySellers: "Take into when filtering by sellers",
  supportedPayments: "Supported payment methods",
  indicateMethodNameAlert: "Specify the name of the method, between 2 and 100 characters",
  enterIntegerValue: "Enter an integer value",
  deliveryMethod: "Delivery method",
  deliveryMethodActivity: "Payment method activity",
  deliveryMethods: "Delivery methods",
  sellerFilter: "Filter. by seller",
  paymentMethod: "Payment method",
  paymentMethodActivity: "Payment method activity",
  paymentMethods: "Payment methods",
  editingDeliveryService: "Editing delivery service",
  creatingDeliveryService: "Editing delivery service",
  chooseLogo: "Choose logo",
  adminTransactionHistory: "Admin transactions history",
  apiIntegration: "API integration",
  paymentMethodNotSetted: "Payment method not chosen",
  categoryModeration: "Category moderation",
  notLimited: "Not limited",
  deleteLabelAlert: "Are you sure you want to remove the label??",
  // labels
  label: "Label",
  labels: "Labels",
  editingLabels: "Edit labels",
  creatingLabels: "Create label",
  colorFill: "Fill",
  fontColor: "Text color",
  example: "Example",
  enterLabelText: "Enter label text. From 2 to 40 characters",
  labelSettings: "Products label settings",
  offersLabelSettings: "Offers label settings",
  colorOfFill: "Fill color",
  labelDeleted: "Label deleted",
  // payment methods
  editingPaymentMethods: "Editing payment methods",
  creatingPaymentMethods: "Creating payment methods",
  // returnreasons
  returnReasons: "Return reasons",
  editingReturnReasons: "Edit return reasons",
  creatingReturnReasons: "Create return reasons",
  deletionApply: "Apply delition",
  returnReasonWillBeDeleted: "Return reason will be deleted",
  // tags
  tags: "Tags",
  offerTags: "Offer tags",
  editingTags: "Edit tags",
  creatingTags: "Create tag",
  tagNameAlert: "Enter name of tag. From 2 to 100 characters",
  tagDeleted: "Tag deleted",
  // invoices
  sumWithVat: "Amount with VAT, uah",
  sumWithoutVat: "Amount without VAT, uah",
  vat: "VAT, uah",
  account: "Accounts | Account | Accounts",
  invoiceFormating: "Account formating",
  managerComment: "Manager comment",
  adminComment: "Admin comment",
  createInvoice: "Create invoice",
  invoiceCreated: "Invoice created",
  changeInvoiceOn: "Change invoice bill on",
  currentStatus: "Current status",
  changeStatus: "Change status",
  dateAndTimePayment: "Date and time of payment",
  invoiceNumber: "Account number",
  payerFactual: "Payer (fact.)",
  payerPDF: "Payer PDF",
  fillFields: "Fill in required fields",
  paid: "Paid",
  sellerAccounts: "Seller accounts",
  searchInvoicesFactual: "Search by invoice",
  creatingDate: "Date of creating",
  finishingDate: "Finishing date",
  dateInPdf: "The date indicated in the pdf",
  invoiceNumberWithSymbol: "account №",
  invoiceStatus: "Invoice status",
  paymentDate: "Payment date",

  payerInformation: "Інформація про платника",
  enterSellerName: "Введіть назву продавця",
  sellerTypePlaceholder: "ПІБ / ФОП / ТОВ",
  sellerTypePib: "ПІБ",
  sellerTypeFop: "ФОП",
  sellerTypeTov: "ТОВ",
  payerHelpText: "Введіть перевірену назву платника з вказанням ФОП або ТОВ",
  invoiceSumDivider: "Сума оплати",
  invoiceSumFull: "Сума з ПДВ / без ПДВ / ПДВ",
  isVatPayer: "Є платником ПДВ?",
  isVatPayerFull: "Чи є платником податків?",
  edrpou: "Код ЄДРПОУ",
  ipn: "ІПН",
  edrpou_ipn: "Код ЄДРПОУ / ІПН",
  invoiceAddressDivider: "Юридична адреса платника",
  postCode: "Поштовий індекс",
  region: "Область",
  district: "District",
  city: "Місто",
  settlement: "Населений пункт",
  settlementType: "Settlement type",
  streetName: "Вулиця",
  houseNumber: "Будинок",
  cabinet: "Кабінет",
  apartment: "Квартира",
  apartmentShort: "Кв.",
  dateInPdf: "Дата в PDF",
  invoiceCreatingSuccess: "Рахунок успішно створено",
  invoiceCreatingError: "Рахунок не створено",
  invoiceUpdateSuccess: "Рахунок успішно оновлено",
  invoiceUpdateError: "Рахунок не оновлено",
  invoiceCreating: "Створення рахунку",
  editInvoiceModalTitle: "Змінити рахунок {number}",
  invoiceEditInvoiceDataTitle: "Дані, вказані при створенні рахунку",
  payer: "Платник",
  invoiceAdressFull: "Юридична адреса",
  invoicePaidSum: "Сума оплати",
  invoicePaymentDateTime: "Дата і час оплати",
  errorInvoiceDateTime: "Коректний формат DD.MM.YYYY hh:mm",
  invoiceManagerDataDivider: "Дані, вказані менеджером при зарахуванні оплати",
  invoiceDetails: "Реквізити",
  invoiceDateValidUntil: "Рахунок дійсний до",
  invoiceSearch: "Пошук по продавцю, платнику, платнику (факт.), коментар, номер рахунку",
  invoiceOverdue: "The invoice is overdue",
  // moderation buyers
  moderationBuyers: "Customers",
  sellerManager: "Sellers manager",
  searchInMB: "Search by nickname, full name, phone, e-mail",
  userProfile: "User profile",
  userFullName: "User full name",
  photoAllowed: "Profile photo visible",
  photoHidden: "Profile photo hidden",
  activateUser: "Activate user",
  blockUser: "Block user",
  userIsActive: "Active account",
  userIsBlocked: "Blocked account",
  avatarShowAlert: "Do you really want to show this user's photo?",
  avatarHideAlert: "Do you really want to hide this user's photo?",
  userIsActiveAlert: "Are you sure you want to unblock the user?",
  userIsBlockedAlert: "Are you sure you want to block the user?",
  isReferalPartnerAlert:
    "Are you sure you want to allow the user to see and go to the referral program page?",
  isNotReferalPartnerAlert:
    "Are you sure you want to block the user to see and go to the referral program page?",
  registerDate: "Register date",
  lastLogin: "Last login",
  lastName: "Nickname, f.name",
  bonusAccount: "Bonus account",
  reviewsTP: "Reviews prod.",
  // moderation propositions
  moderationPropositions: "Moderation proposes",
  deliveryPolicy: "Shipping policy",
  shipmentTime: "Shipping time",
  deliveryTimeMin: "Delivery time, from",
  deliveryTimeMax: "Delivery time, to",
  shipmentCity: "Shipment city",
  moderationStatus: "Moderation status",
  moderationStatusShorted: "Moder. stat.",
  chooseStatus: "Select a status",
  moderComment: "Moderator's comment",
  moderCommentShorted: "Moder. comm.",
  offerImages: "Img. of off.",
  offerVideo: "Offer video",
  productImages: "Img. of goods",
  priceOld: "Previous price",
  warranty: "Warranty",
  offerStatus: "Offer stat.",
  offerName: "Name of offer",
  cantGetShippingPolicy: "Failed to get shipping policy",
  // orders moderation
  orderStatus: "Order status",
  sellerRefuseReason: "Reason for rejection by the seller",
  buyerRefuseReason: "Reason for rejection by the customer",
  adminRefuseReason: "Reason for rejection by the admin",
  orderDetails: "Order details",
  ttn: "TTN",
  confirmTtn: "Approve setted TTN",
  invalidTtnWarnign: "TTN does not correspond to the formats of delivery services. Continue?",
  recipientData: "Recipient info",
  dontCallMe: "Don't call me",
  buyerComment: "Customer comment",
  sellerNotes: "Seller's notes",
  bonusTransactions: "Bonus transactions on order",
  ordersReturns: "Return by order",
  orderOnSum: "order on sum",
  changesHistory: "Changes history",
  failedCall: "Failed to call",
  phoneShorted: "Pho.",
  productRefunds: "Return of goods or funds",
  exchangeProduct: "Exchange of goods",
  repair: "Repair",
  editDate: "Date of change",
  editAuthor: "Author of changes",
  editObject: "Object of changes",
  stateNameWarranty: "Condition, warranty and name of the product",
  ordering: "ord.",
  bonusesAmount: "Bonus used",
  placedOrders: "Placing an order",
  orderTime: "Time of order",
  orderedDate: "Order date",
  orderedDateShort: "Ord. date",
  paymentStatus: "Payment status",
  paidOnline: "Paid online",
  paidWaiting: "Awaiting payment",
  paidCanceled: "Payment canceled",
  rejectReason: "Reason for refusal",
  moderation: "Moderation",
  unOrdered: "Pending orders in the cart",
  orderCode: "Order code",
  inBucketNotOrdered: "In the cart, not decorated",
  order_status: "Status",
  receiver_data: "Reciever data",
  payment_method: "Payment method",
  comment_customer: "Customer comment",
  // page builder
  pageBuilder: "Info pages",
  categoryEditor: "Category editor",
  sorting: "Sorting",
  categoryName: "Category name",
  enterReturnName: "Enter a return name. From 2 to 250 characters",
  pageEditor: "Page editor",
  urlName: "URL name",
  setPhoneNumber: "Enter a phone number",
  setContentLocation: "Specify the location of the content",
  setUrl: "Enter the link",
  setSlug: "Enter the slug",
  addPage: "Add the page",
  enterContentLocation: "Enter content location",
  socialMedia: "Socail's media",
  pageCategory: "Page category",
  location: "Location",
  // products
  listing: "Listing",
  labelsAndTags: "Labels and tags",
  differentLabelsAlert: "Selected products have different labels setted",
  differentTagsAlert: "Selected products have different tags setted",
  listingAndVariations: "Listing and variations",
  productSearchByCategory: "Product search in {category}",
  listingNumber: "Listing number",
  variations: "Variations",
  chooseProductCover: "Choose product-cover",
  chooseCharacteristic: "Select at least 1 characteristic for variation",
  chosenCharForVar: "Selected characteristics for variation",
  createListing: "Create listing",
  editListing: "Edit listing",
  editListingAndVariations: "Edit/add listing and variations",
  variationIsAdded: "Variation already added",
  productIsChosen: "The product is already selected",
  inNextProducts: "In the following products: {0} not have specifications",
  enterListingNumber: "Enter a number of listing",
  listingSearch: "Search listings",
  productAdding: "Adding products",
  chosenProducts: "Selected products",
  searchResult: "Search result",
  addChosen: "Add favorites",
  addToChosen: "Add to favorites",
  routeEditor: "Route editor in the catalog",
  differentCategoriesAlert: "The selected products are located in different categories",
  chosenCategoryIsNotChild: "The selected category is not the last child",
  chooseCategory: "Select a category",
  setCategory: "Define a category",
  fullRoute: "Full route",
  ACTIVE: "Active",
  moderationIsSuccess: "Moderation is successful",
  MODERATION_SUCCESS: "Moderation is successful",
  moderationIsFailed: "Moderation is failed",
  MODERATION_FAILED: "Moderation is failed",
  moderationIsExpected: "Expects moderation",
  EXPECTS_MODERATION: "Expects moderation",
  moderationInProgress: "Moderation in progress",
  MODERATION_IN_PROCESS: "Moderation in progress",
  ON_MODERATION: "On moderation",
  NEW: "New",
  IN_PROCESSING: "In processing",
  SELLER_APPROVED: "Seller approved",
  SHIPPED: "Shipped",
  SUCCESSFULLY_CLOSED: "Successfully closed",
  CANCELED_BY_SELLER: "Canceled by seller",
  CANCELED_BY_CUSTOMER: "Canceled by customer",
  CANCELED_BY_SUPERVISOR: "Canceled by supervisor",
  editRatingFactor: "Edit rating factor",
  editRating: "Edit rating",
  rating: "Rating",
  addGoods: "Add products",
  sync: "Synchronization",
  article: "Article",
  ratingShorted: "R-g",
  finalRating: "Fin. Rat.",
  moderationOfProducts: "Products",
  cover: "Cover",
  updateProductFail: "Failed to update product",
  alreadyAdded: "Already added",
  createProductFail: "Error. Failed to create",
  syncSuccess: "Sync is success",
  // promotions
  sliderCreating: "Creating a slider",
  sliderEditing: "Editing a slider",
  promoText: "Promo text",
  sliderCreatingAlert:
    "Adding images to the slider and adjusting the display are available after it is created",
  bannersControl: "Banner management",
  allowedFormats: "Supported formats",
  allowedRatio: "Allowed aspect ratios",
  maxFileSize: "Max file size - {size} MB.",
  maxUploadedFilesNumber: "The maximum number of uploaded files",
  maxQuantity: "Maximum quantity",
  maxSize30: "Maximum size - 30 MB",
  bannerForLang: "Banner for language",
  device: "Device",
  ofDevice: "of device",
  ofSize: "of size",
  mainSlider: "Slider on the main page",
  addBanner: "Add a banner",
  imageUploaded: "Image uploaded",
  imageDeleted: "Image deleted",
  banners: "Banners",
  bannerShorted: "Ban.",
  lastShorted: "Last",
  advertisingOptions: "Advertising options",
  // returns
  returnFrom: "Return {0} from {1}",
  returnStatus: "Return status",
  returnPoint: "Purpose of return",
  returnDetails: "Return details",
  contactPerson: "Contact person",
  returnsByOrder: "Return the order",
  orderClosed: "Order completed",
  attachedPhotos: "Photos attached to the order",
  returnProductsRefunds: "Return of goods or funds",
  imagesOfProduct: "Product photo",
  warrantyMonth: "War. mon.",
  imagesOfOffer: "Photo off.",
  oldValue: "Old value",
  newValue: "New value",
  sellerComment: "Seller's comment",
  returnModeration: "Moderation of returns",
  returnNumber: "Return number",
  orderNumber: "Order number",
  aimReasonReturn: "Purpose and reason for return",
  goodsQuantity: "Q-ty of goods",
  anyReturnStatus: "Any return status",
  // products rewiews
  parentReview: "Parent review",
  grade: "Grade",
  advantages: "Advantages",
  flaws: "Flaws",
  photos: "Photos",
  reviewDate: "Review date",
  authorID: "ID Author",
  author: "Author",
  customerContacts: "Email / phone",
  reviewPhoto: "Photo of review",
  reviewVideo: "Video",
  isBought: "Is bought",
  productsReviews: "Reviews. Products",
  // search settings
  brandSynonyms: "Brand' synonyms",
  enterBrandName: "Enter brand name...",
  enterSynonym: "Enter synonyms...",
  synonym: "Synonyms | Synonym | Synonyms",
  synonyms: "Synonyms",
  addSynonyms: "Add synonyms",
  synonymCreating: "Synonym's creating",
  synonymSeparated: "Synonyms (comma separated)",
  sameSynonymExist: "These synonums already exist",
  searchSynonyms: "Synonym's search",
  searchSettings: "Search settings",
  index: "Index",
  key: "Key",
  weight: "Weight",
  import: "Import",
  searchWeights: {
    keywords_all: "Weight All",
    keywords_6: "Weight 6",
    keywords_8: "Weight 8",
    keywords_12: "Weight 12",
    keywords_18: "Weight 18"
  },
  addKey: "Add key",
  enterSearchQuery: "Enter your search query",
  keywordsIsEmpty: "No keys",
  testSearchQuery: "Test search query",
  productWontShowInSample: "The product will not be included in the search selection",
  updateIsStarting: "Updating successfully started",
  filterSortedScoreHelp: "Score / sort score",
  // seller reviews
  bad: "Bad",
  norm: "Norm.",
  good: "Good",
  ratingOverall: "Rating overall",
  authorFullname: "Comment author",
  reviewAddedPhotos: "Photo, added to review",
  sellersReviews: "Reviews. Sellers",
  rating_product_description_fits: "Product fits description",
  rating_delivery: "Delivery and view quality",
  rating_service: "Service rating",
  minKeywordsScore: "Min score",
  // seller
  unboundAlert:
    "The selected user is linked to {seller}. Are you sure you want to unbind it and bind it to the new one?",
  generalInformation: "General information",
  companyManagers: "Company menegers",
  enterPhoneNumber: "Enter phone number",
  chooseManagers: "Select managers",
  publicPhoneNumber: "Public telephone number",
  notifyEmail: "Email for distribution",
  notifyEmailPlaceholder: "Enter placeholder",
  sellerLogo: "Company logo",
  sellerInfo: "Company info",
  accessRestrictions: "Access and restrictions",
  offersBlock: "Block of offers on the marketplace",
  textOnSellerPage: "The text of the message on the seller's page",
  blockSyncSum: "Deny synchronization of prices and balances",
  allowAccessToSeller: "Allow access to the seller's account",
  sellerIsActivated: "The seller is activated",
  additionally: "Additionally",
  sellerHealth: "Health of the seller's account",
  participationInTheBonusProgram: "Participation in the bonus program",
  allowGetBonuses: "Allow to accept bonuses",
  allow_synthetic_delivery_method: "Allow delivery method to Synthetic points",
  maxBonuses: "on the restriction of receiving bonuses",
  createSeller: "Create seller",
  sellerDescription: "Seller description",
  sellerPayments: "Payments",
  sellerEditing: "Edit seller",
  sellerCreating: "Create a seller",
  sellerCreatingError: "Seller create error",
  sellerUpdatingError: "Seller update error",
  sellerCreatingSuccess: "Seller create success",
  sellerUpdatingSuccess: "Seller update success",
  tokenSaved: "Token saved",
  tokenDeleted: "Token deleted",
  monoPartPaymentsSaved: "Purchase in parts monobank saved",
  monoPartPaymentsDeleted: "Purchase in parts monobank deleted",
  privatPartPaymentsDeleted: "Purchase in parts PrivatBank deleted",
  sellerPhone: "Seller's phone number (owner)",
  positiveReviews: "Positive reviews",
  reviews: "Reviews",
  cancelledOrders: "Rejected orders",
  closedOrders: "Closed orders",
  product: "Product",
  productCount: "Products",
  sellerControl: "Management of sellers",
  showAllOffers: "Show all offers",
  showMore: "Show more",
  hideOffers: "Hide offers, show text",
  sellerPaymentsCard: "Card payment",
  sellerPaymentsParts: "Purchase in parts {bank}",
  sellerPaymentsPartsAllow: "Purchase in parts {bank} allow",
  // Sync
  syncManagment: "Sync managment",
  synchronizations: "Syncs",
  syncState: "Sync state",
  syncStatus: "Sync status",
  compare: "Compare",
  processManagement: "Process management",
  totalCountShorted: "Total count",
  validCount: "Valid",
  invalidCount: "Invalid",
  newCount: "New",
  comparedCount: "Compared",
  approvedCount: "Approved",
  activeCount: "Active",
  lastSyncShorted: "Last sync",
  syncIsActive: "Active",
  syncIsDisabled: "Disabled",
  sync_status_ERROR: "With errors",
  sync_status_FAILURE: "Failure",
  sync_status_SUCCESS: "Success",
  syncDeletion: "Delete sync",
  matchWarning: "Matching unavaliable for syncs with failure status",
  syncMatchStarted: "Matching of sellers started",
  syncMatchSuccess: "Matching of seller {seller} is successfull",
  syncMatchError: "Matching of seller {seller} is failed",
  approveWarning: "Approving unavaliable for syncs with failure status",
  syncUpdateStarted: "{seller}'s sync updating is started",
  syncUpdateSuccess: "{seller}'s sync is successfully updated",
  syncUpdateError: "Updating sync of {seller} has failed",
  syncApproveStarted: "Matching of sellers started",
  syncApproveSuccess: "Approving of seller {seller} is successfull",
  syncApproveProgress: "Approving of seller {seller}",
  syncApproveError: "Approving of seller {seller} is failed",
  syncReport: "Sync report",
  activated: "Activated",
  deactivated: "Deactivated",
  syncTableTooltips: {
    last_sync_total: "The total count of products in the file with the unique offer_id",
    last_sync_count: "Count of products for which it is possible (available) to create an offer",
    last_sync_failure_count:
      "Products count for which it is impossible to create an offer, and % of the total count of products",
    last_sync_new_count: "Valid unmatched offers count",
    matched: "Offers count that are already tied to the product",
    confirmed: "Matched and confirmed offers count",
    active: "Available and sold offers count"
  },
  downloadReport: "Download report",
  downloadReportWillStart: "The report will start downloading soon",
  sellerActivity: "Seller activity",
  waitForMatching: "Wait for matching",
  syncXmlLink: "Link on xml-file",
  sellerIsActive: "Active",
  sellerIsInactive: "Inactive",
  // Locations, Post offices
  locationsSettings: "Locations",
  addRecord: "Add record",
  lookUp: "Look up",
  postOffice: "Post office | Post offices",
  deletionWarningTitle: "Are you sure you want to delete?",
  deletionWarningMessage: "This action is irreversible",
  chooseTypeForEdit: "Choose type for edit",
  koatuuLabel: "KOATUU (optional)",
  chooseCountry: "Choose country",
  chooseState: "Choose state",
  chooseDistrict: "Choose district",
  fastenTo: "Fasten to",
  postOfficeOrBox: "Post office or box",
  choosePostOfficeOrBox: "Choose post office or box",
  address: "Address",
  workSchedule: "Schedule",
  weightTo: "Weight to, kg",
  maxCost: "Max cost",
  sizeLimitsBySend: "Limits on sending, cm",
  sizeLimitsByReceive: "Limits on receiving, cm",

  mondayShorted: "Mon",
  tuesdayShorted: "Tue",
  wednesdayShorted: "Wed",
  thursdayShorted: "Thu",
  fridayShorted: "Fri",
  saturdayShorted: "Sat",
  sundayShorted: "Sun",
  withoutLimits: "Without limits",
  width: "Width",
  height: "Height",
  length: "Length",
  widthShorted: "W",
  heightShorted: "H",
  lengthShorted: "L",
  poshta: "Post Office",
  nova_poshta: "Nova poshta",
  ukr_poshta: "UkrPoshta",
  totalPostOfficesCount: "Total post offices count",
  digitalAddress: "Digital address",
  postOfficeAdding: "Post office adding",

  locationTypes: {
    COUNTRY: "Country",
    STATE: "State",
    DISTRICT: "District",
    CITY: "City",
    SPECIAL_CITY: "Special city",
    URBAN: "Urban",
    SETTLEMENT: "Settlement",
    VILLAGE: "Village",
    STREET: "Street"
  },
  postOfficesSettings: "Post offices settings",
  index: "Index",
  postOffices: "Post offices",
  currentLocationDisabled: "Current location is disabled",

  // promo campaigns
  promoCampaigns: "Promo campaigns",
  promoCampaignsList: "Promo campaigns list",
  isPromoActive: "Active",
  isPromoInactive: "Inactive",
  technicalName: "Technical name",
  offersCount: "Offers count",
  createPromoCampaign: "Create promo campaign",
  promoCampaignActivity: "Promo campaign activity",
  promoCampaignEditing: "Promo campaign editing",
  addOffers: "Add offers",
  actualPrice: "Actual price",

  // promo codes
  promoCodesPageTitle: "Promo codes",
  promoCodeEditing: "Promo code editing",
  createPromoCode: "Create promo code",
  editPromoCode: "Edit promo code",
  promoCodesCode: "Code",
  promoCodesPercent: "Discount percent",
  promoCodesStartDate: "Start date",
  promoCodesEndDate: "End date",
  promoCodeDeleteConfirm: "Do you want to remove the promo code?"
}
