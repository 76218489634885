
<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    :colon="false"
    @submit.prevent="onSubmit"
  >
    <a-form-model-item
      :label="$t('technicalName')"
      prop="name"
    >
      <a-input v-model="form.name" />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('promoCodesCode')"
      prop="code"
    >
      <a-input v-model="form.code" />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('promoCodesPercent')"
      prop="discount_percent"
    >
      <a-input-number
        v-model="form.discount_percent"
        :min="1"
        :max="100"
        @change="isFormChanged = true"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('promoCodesStartDate')"
      prop="starts_at"
    >
      <a-date-picker
        v-model="form.starts_at"
        :showTime="{
          defaultValue: defaultTime
        }"
        format="DD.MM.YYYY HH:mm"
        valueFormat="YYYY-MM-DDTHH:mm"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('promoCodesEndDate')"
      prop="ends_at"
    >
      <a-date-picker
        v-model="form.ends_at"
        :showTime="{
          defaultValue: defaultTime
        }"
        format="DD.MM.YYYY HH:mm"
        valueFormat="YYYY-MM-DDTHH:mm"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        icon="plus"
        :loading="isFetching"
        htmlType="submit"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import i18n from "@/i18n"
import { createPromoCode } from "@/modules/MPAdmin/services/promoCodesService"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"
import moment from "moment"
import { computed, reactive, ref, shallowRef } from "vue"
import { validateUppercase, validateWhiteSpace } from '@/utils/validations'

const emit = defineEmits(["submit", "close"])

const formRef = ref()
const form = reactive({
  name: undefined,
  code: undefined,
  discount_percent: undefined,
  starts_at: undefined,
  ends_at: undefined
})
const requiredRule = computed(() => [{ required: true, message: i18n.t("emptyError") }])
const formRules = {
  name: requiredRule.value,
  code: [
    ...requiredRule.value,
    {
      validator: validateUppercase,
      trigger: 'change'
    },
    {
      validator: validateWhiteSpace,
      trigger: 'change'
    },
  ],
  discount_percent: requiredRule.value,
  starts_at: requiredRule.value,
  ends_at: requiredRule.value
}

const defaultTime = computed(() => {
  return moment("00:00:00", "HH:mm:ss")
})

const onClose = () => emit("close")

const isFetching = shallowRef(false)

const onSubmit = async () => {
  const isValid = await formRef.value.validate().catch((status) => status)
  if (!isValid) return

  try {
    isFetching.value = true

    const { data } = await createPromoCode(form)

    notification.success({ message: i18n.t("created") })
    emit("submit", data)
    onClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>
