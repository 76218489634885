import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo = {} } = { sortedInfo: {} }) => {
  const columns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("creatingDate"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
      fixed: "left",
      width: 150
    },
    {
      key: "finishes_at",
      dataIndex: "finishes_at",
      title: i18n.t("finishingDate"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      // sorter: true,
      // sortOrder: sortedInfo?.value?.columnKey === "finishes_at" && sortedInfo?.value?.order,
      fixed: "left",
      width: 150
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("technicalName")
    },
    {
      key: "offers_count",
      dataIndex: "offers_count",
      customRender: (count) => `${count} ${i18n.t("pieces")}`,
      title: i18n.t("offersCount"),
      align: "center",
      width: 145
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "isActiveRenderer"
      },
      width: 160
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 65
    }
  ])

  return columns
}
