<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="edit-type__general-form"
  >
    <a-form-model-item
      prop="full_name.uk"
      :label="`${$t('typeName')} (UA)`"
    >
      <a-input v-model="form.full_name.uk" />
    </a-form-model-item>

    <a-form-model-item
      prop="full_name.ru"
      :label="`${$t('typeName')} (RU)`"
    >
      <a-input v-model="form.full_name.ru" />
    </a-form-model-item>

    <a-form-model-item
      prop="full_name.en"
      :label="`${$t('typeName')} (EN)`"
    >
      <a-input v-model="form.full_name.en" />
    </a-form-model-item>

    <a-form-model-item
      prop="note"
      :label="$t('tag._note')"
      class="edit-type__general-form__note"
    >
      <a-textarea
        v-model="form.note"
        :rows="4"
        :maxLength="500"
      />

      <template #extra>
        <div class="edit-type__general-form__note__counter">
          <span
            :class="{ 'edit-type__general-form__note__counter--error': form.note?.length > 500 }"
          >
            {{ form.note?.length || 0 }}
          </span>
          /500
        </div>
      </template>
      <template #help></template>
    </a-form-model-item>

    <!-- <a-form-model-item
      prop="trust"
      :label="$t('status')"
      class="edit-type__general-form__status"
    >
      <a-select
        v-model="form.trust"
        :disabled="disableChangeTrustStatus"
      >
        <a-select-option
          v-for="option in trustOptions"
          :key="option.key"
          :value="option.key"
        >
          <div :class="option.key">
            {{ option.label }}
          </div>
        </a-select-option>
      </a-select>
    </a-form-model-item> -->
  </a-form-model>
</template>

<script setup>
import i18n from "@/i18n"
import useModerationOptions from "@/modules/Moderation/composables/useModerationOptions"
import { computed, reactive, ref, shallowRef, watch } from "vue"

const props = defineProps({
  typeInfo: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["change"])

const { trustOptions } = useModerationOptions()

const formRef = ref()
const form = reactive({
  full_name: {
    ru: "",
    uk: "",
    en: ""
  },
  note: ""
  // trust: "UNTRUSTED"
})
const formRules = computed(() => ({
  full_name: {
    ru: [{ required: true, message: i18n.t("emptyError") }],
    uk: [{ required: true, message: i18n.t("emptyError") }],
    en: [{ required: true, message: i18n.t("emptyError") }]
  },
  note: [{ max: 500, message: i18n.t("maxLengthError", { length: 500 }) }]
  // trust: [{ required: true, message: i18n.t("emptyError") }]
}))

const formWatcher = ref()

// const disableChangeTrustStatus = shallowRef(false)

const setupForm = (payload = props.typeInfo) => {
  form.full_name = payload?.full_name ? { ...payload.full_name } : form.full_name
  form.note = payload?.note

  // form.trust = payload?.trust || "UNTRUSTED"
  // disableChangeTrustStatus.value = payload.trust === "TRUSTED"

  formRef.value.clearValidate()
  formWatcher.value = watch(() => form, onFormChanged, { deep: true })
}
const onFormChanged = () => {
  emit("change")
  formWatcher.value?.()
}

const getFormValues = async () => {
  const isValid = await formRef.value.validate().catch((isValid) => isValid)

  return isValid ? form : null
}

defineExpose({
  setupForm,
  getFormValues
})
</script>

<style lang="scss" scoped>
.edit-type__general-form {
  width: 100%;

  display: flex;
  gap: 16px;

  & > * {
    flex: 2;
  }

  &__note {
    flex: 3;

    &__counter {
      display: flex;
      justify-content: flex-end;
      color: $dark;
      position: absolute;
      bottom: 0;
      right: 0;

      &--error {
        color: $red-color;
      }
    }
  }

  &__status {
    flex: 1;
  }
}
</style>
