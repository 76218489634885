import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const columns = computed(() => [
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("code"),
      scopedSlots: { customRender: "defaultRenderer" },
      width: 140,
      fixed: "left"
    },
    {
      key: "value.uk",
      title: `${i18n.t("value")} (UA)`,
      width: 420,
      scopedSlots: { customRender: "valueUaRenderer" }
    },
    {
      key: "value.ru",
      title: `${i18n.t("value")} (RU)`,
      width: 420,
      scopedSlots: { customRender: "valueRuRenderer" }
    },
    {
      key: "value.en",
      title: `${i18n.t("value")} (EN)`,
      width: 420,
      scopedSlots: { customRender: "valueEnRenderer" }
    },
    {
      key: "trust",
      title: i18n.t("status"),
      width: 145,
      scopedSlots: { customRender: "trustRenderer" },
      fixed: "right"
    },
    {
      key: "origin",
      title: i18n.t("origin"),
      width: 120,
      scopedSlots: { customRender: "originRenderer" },
      fixed: "right"
    },
    {
      key: "actions",
      width: 145,
      scopedSlots: { customRender: "actionsRenderer" },
      fixed: "right"
    }
  ])

  const numericColumns = computed(() => [
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("code"),
      scopedSlots: { customRender: "defaultRenderer" },
      width: 140,
      fixed: "left"
    },
    {
      key: "value.ru",
      title: i18n.t("value"),
      scopedSlots: { customRender: "valueNumericRenderer" }
    },
    {
      key: "unit",
      title: i18n.t("unit"),
      scopedSlots: { customRender: "unitRenderer" }
    },
    {
      key: "trust",
      title: i18n.t("status"),
      width: 205,
      scopedSlots: { customRender: "trustRenderer" },
      fixed: "right"
    },
    {
      key: "origin",
      title: i18n.t("origin"),
      width: 160,
      scopedSlots: { customRender: "originRenderer" },
      align: "center",
      fixed: "right"
    },
    {
      key: "actions",
      width: 145,
      scopedSlots: { customRender: "actionsRenderer" },
      fixed: "right"
    }
  ])

  return { columns, numericColumns }
}
