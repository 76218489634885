<template>
  <a-table
    :columns="columns"
    :data-source="items"
    row-key="id"
    :pagination="false"
  >
    <template #photoRenderer="text, record">
      <TableRendererPhotoGallery :images="record.images" />
    </template>

    <template #codeTitleRenderer>
      <div>CS-code</div>
      <div>P-code</div>
    </template>

    <template #codeRenderer="text, record">
      <div :style="{ display: 'flex', alignItems: 'center' }">
        <div :style="{ width: '90px' }">{{ record.code }}</div>
        <a-icon
          :style="{ marginRight: '10px', color: '#1890FF' }"
          type="copy"
          @click="handleCopy(record.code)"
        />
        <a
          :style="{ color: '#1890FF' }"
          :href="getCsCodeLink(record)"
          target="_blank"
        >
          <a-icon type="link" />
        </a>
      </div>
      <div :style="{ display: 'flex', alignItems: 'center' }">
        <div :style="{ width: '90px' }">{{ record.product.p_code }}</div>
        <a-icon
          :style="{ marginRight: '10px', color: '#1890FF' }"
          type="copy"
          @click="handleCopy(record.product.p_code)"
        />
        <a
          :style="{ color: '#1890FF' }"
          :href="getPCodeLink(record)"
          target="_blank"
        >
          <a-icon type="link" />
        </a>
      </div>
    </template>

    <template #productNameRenderer="text, record">
      <div>{{ record.product.name }}</div>
      <div>
        {{ record.product.category.name }},
        {{ getOfferConditionString(record.condition_key, record.condition_value) }}
        <template
          v-if="
            record.condition_key !== 'w_undetermined' &&
            record.warranty &&
            record.warranty.length > 0
          "
        >
          , {{ $tc("monthFull", +record.warranty, { count: record.warranty }) }}
        </template>
      </div>
    </template>
  </a-table>
</template>

<script>
import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery"
import { useOfferCondition } from "@/composables/useOfferCondition"
import getFormatedSum from "@/utils/getFormatedSum"
import { MARKETPLACE } from "@/constants/common.js"

export default {
  name: "OrderItems",
  components: {
    TableRendererPhotoGallery
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const { getOfferConditionString } = useOfferCondition()

    return {
      getOfferConditionString
    }
  },
  computed: {
    columns() {
      return [
        {
          key: "index",
          dataIndex: "index",
          title: "№",
          width: 30,
          customRender: (text, record, index) => index + 1
        },
        {
          key: "product.images",
          dataIndex: "product.images",
          title: this.$t("productImages"),
          width: 80,
          scopedSlots: {
            customRender: "photoRenderer"
          }
        },
        {
          key: "images",
          dataIndex: "images",
          title: this.$t("offerImages"),
          width: 80,
          scopedSlots: {
            customRender: "photoRenderer"
          }
        },
        {
          key: "code",
          dataIndex: "code",
          width: 160,
          scopedSlots: {
            title: "codeTitleRenderer",
            customRender: "codeRenderer"
          }
        },
        {
          key: "product",
          dataIndex: "product",
          title: this.$t("orderProductInfo"),
          scopedSlots: {
            customRender: "productNameRenderer"
          }
        },
        {
          key: "quantity",
          dataIndex: "quantity",
          title: `${this.$t("countShorted")}, ${this.$t("pieces")}`,
          width: 110,
          align: "center"
        },
        {
          key: "price",
          dataIndex: "price",
          title: `${this.$t("price")}, ${this.$t("uah")}`,
          width: 110,
          align: "center",
          customRender: (data) => getFormatedSum(data, true)
        },
        {
          key: "price_total",
          dataIndex: "price_total",
          title: `${this.$t("sum")}, ${this.$t("uah")}`,
          width: 110,
          align: "center",
          customRender: (data) => getFormatedSum(data, true)
        },
        {
          key: "bonuses_amount",
          dataIndex: "bonuses_amount",
          title: `${this.$t("bonusTitle")}, ${this.$t("uah")}`,
          width: 110,
          align: "center",
          customRender: (data) => getFormatedSum(data, true)
        },
        {
          key: "promo_code_amount",
          dataIndex: "promo_code_amount",
          title: `${this.$t("promoCodeTitle")}, ${this.$t("uah")}`,
          width: 130,
          align: "center",
          customRender: (data) => getFormatedSum(data, true)
        },
        {
          key: "sumToPayment",
          dataIndex: "sumToPayment",
          title: `${this.$t("sumToPayment")}, ${this.$t("uah")}`,
          width: 170,
          align: "center",
          customRender: (data, record) =>
            getFormatedSum(record.price_total - record.bonuses_amount - record.promo_code_amount, true)
        }
      ]
    }
  },
  methods: {
    handleCopy(text) {
      navigator.clipboard.writeText(text)

      this.$message.info(this.$t("copied"))
    },
    getCsCodeLink(record) {
      return `${MARKETPLACE}/product/${record.product.slug}/${record.product.p_code}/${record.code}`
    },
    getPCodeLink(record) {
      return `${MARKETPLACE}/product/${record.product.slug}/${record.product.p_code}`
    }
  }
}
</script>
