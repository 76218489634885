import { computed } from "vue"
import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"

export default ({ sortedInfo }) => {
  const columns = computed(() => [
    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: i18n.t("dateCreated"),
      scopedSlots: { customRender: "dateRenderer" },
      width: 160,
      sorter: true,
      sortOrder: sortedInfo.value.columnKey === "timestamp" && sortedInfo.value.order,
      fixed: "left"
    },
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("code"),
      width: 160,
      fixed: "left"
    },
    {
      key: "description",
      dataIndex: "description",
      title: i18n.t("description"),
      width: 545
    },
    {
      key: "synonymsNames",
      dataIndex: "synonyms",
      customRender: (synonyms) => synonyms.join(", "),
      title: i18n.t("synonyms"),
      width: 350
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("value"),
      width: 180
    },
    {
      key: "base_name",
      dataIndex: "base_name",
      title: i18n.t("baseUnit"),
      width: 180
    },
    {
      key: "coef",
      dataIndex: "coef",
      title: i18n.t("coefficient"),
      customRender: (coef) =>
        coef ? coef.toLocaleString("uk-UA", { maximumFractionDigits: 16 }) : "",
      width: 180
    },
    {
      key: "actions",
      scopedSlots: { customRender: "actions" },
      width: 65,
      fixed: "right"
    }
  ])

  return columns
}
