<template>
  <div class="units-data-table">
    <PageHeader
      :title="$t('units')"
      showLanguageSelect
      :currentLanguage="languageForFetch"
      @onChangeLanguage="onChangeLanguage"
    >
      <template #filters>
        <div class="date-filter">
          <div>{{ $t("creatingDate") }}:</div>
          <a-range-picker
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            style="margin-top: 3px"
            v-model="filteredInfo.created_at"
            allowClear
            @change="onFilterChange('created_at')"
          >
            <template #suffixIcon>
              <a-icon type="clock-circle" />
            </template>
          </a-range-picker>
        </div>

        <a-input-search
          v-for="field in searchFields"
          :key="field.value"
          :placeholder="field.label"
          v-model="filteredInfo[field.value]"
          allowClear
          @change="onFilterChange(field.value, Boolean($event?.target?.value))"
          @pressEnter="onFilterChange(field.value)"
        >
          <template #enterButton>
            <a-button
              icon="search"
              @focus="onFilterChange(field.value)"
            />
          </template>
        </a-input-search>

        <InfiniteScrollSelect
          :value="filteredInfo.base"
          :customRequest="fetchUnits"
          :customRequestParams="{ base: 'null' }"
          labelKey="name"
          valueKey="oid"
          :placeholder="$t('baseUnit')"
          onFocusFetch
          @change="onBaseFilterChange"
        />
      </template>

      <template #actions>
        <a-button
          icon="undo"
          @click="onResetFilters()"
        >
          {{ $t("resetFilters") }}
        </a-button>
        <a-button
          type="primary"
          icon="plus"
          @click="openUnitModal()"
        >
          {{ $t("addUnitShorted") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      rowKey="code"
      :columns="columns"
      :pagination="paginationInfo"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #actions="record">
        <a-button
          icon="edit"
          type="dashed"
          shape="circle"
          @click="openUnitModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="showUnitModal"
      :title="modalTitle"
      :width="500"
      destroyOnClose
      @close="closeUnitModal()"
    >
      <UnitModal
        :unit="editedUnit"
        :currentLanguage="languageForFetch"
        @createUnit="onUnitCreated"
        @updateUnit="onUnitUpdated"
        @close="closeUnitModal()"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import i18n from "@/i18n"

import PageHeader from "@/ant-components/PageHeader/index"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/index.js"
import UnitModal from "./components/UnitForm.vue"

import useUnitsColumns from "./useUnitsColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import useDebounce from "@/composables/useDebounce"

import { fetchUnits } from "@/modules/Moderation/services/unitsService.js"

const languageForFetch = ref("ru")

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  setFilteredInfo,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchUnits,
  requestParams: { language: computed(() => languageForFetch.value), changeRouterQuery: true }
})
const columns = useUnitsColumns({ sortedInfo: computed(() => sortedInfo.value) })
const { debounce, resetAllDebounces } = useDebounce()

const showUnitModal = ref(false)
const editedUnit = ref()

const searchFields = computed(() => [
  {
    label: i18n.t("code"),
    value: "code"
  },
  {
    label: i18n.t("description"),
    value: "description"
  },
  {
    label: i18n.t("synonyms"),
    value: "synonyms"
  },
  {
    label: i18n.t("value"),
    value: "name"
  }
])

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  resetAllDebounces()
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}
const onFilterChange = (key, delayRequest = false) => {
  resetAllDebounces()
  if (delayRequest) return debounce(key, handleTableFilterChange, 500)
  handleTableFilterChange()
}
const onResetFilters = () => {
  resetAllDebounces()
  handleTableFiltersReset()
}

const onChangeLanguage = (value) => {
  languageForFetch.value = value.language || "ru"
  onTableChange()
}

const modalTitle = computed(() => {
  return Boolean(editedUnit.value)
    ? `${i18n.t("editUnit")}: ${editedUnit.value.name}`
    : i18n.t("addUnit")
})

const openUnitModal = (unit) => {
  showUnitModal.value = true
  editedUnit.value = unit
}

const closeUnitModal = () => {
  showUnitModal.value = false
  editedUnit.value = undefined
}

const onUnitCreated = () => {
  closeUnitModal()
  onTableChange()
}

const onUnitUpdated = (payload) => {
  updateTableDataRecord({ payload, identifier: "code" })
  closeUnitModal()
}

const onBaseFilterChange = (value) => {
  setFilteredInfo({ ...filteredInfo.value, base: value })
  onFilterChange("base")
}

onMounted(() => {
  setupTable({
    defaultSorter: { columnKey: "timestamp", order: "ascend" },
    defaultPagination: { size: "default" },
    sortKey: "order_by"
  })

  onTableChange()
})
</script>

<style lang="scss">
.date-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 350px;

  & > :first-child {
    white-space: nowrap;
    color: $dark;
  }
}
</style>
