import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

export const fetchSearchesList = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/products/search-keywords/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchSearchById = ({ uuid, requestParams }) => {
  return API.get(`/mp-admin/products/search-keywords/${uuid}`)
}

export const updateSearchKeywords = ({ uuid, payload }) => {
  return API.put(`/mp-admin/products/search-keywords/${uuid}`, payload)
}

export const importSearchesCsv = ({ formData }) => {
  return API.post(`/mp-admin/products/search-keywords/import`, formData)
}

export const checkImportFileResult = ({ task }) => {
  return API.get(`/mp-admin/products/search-keywords/import/${task}`)
}

export const getImportFileResult = async ({ url }) => {
  try {
    window.open(url, "_blank")

    notification.success({ message: i18n.t("success") })
  } catch {
    notification.error({
      message: i18n.t("fileLoadingError")
    })
  }
}

export const checkProductKeywordScore = ({ queryParams, code }) => {
  return API.get(`/mp-admin/products/search-keywords/check/${code}?q=${queryParams.q}`)
}

export const fetchKeywordProducts = ({ queryParams, signal }) => {
  return API.get(
    `/mp-admin/products/search-keywords/check/${getFormatedQueryParams(queryParams)}`,
    {
      signal
    }
  )
}
