export default () => {
  const timeouts = new Map()

  const debounce = (key, fn, delay = 300, ...args) => {
    if (timeouts.has(key)) {
      clearTimeout(timeouts.get(key))
    }
    const timeout = setTimeout(() => {
      fn(...args)
      timeouts.delete(key)
    }, delay)
    timeouts.set(key, timeout)
  }

  const resetDebounce = (key) => {
    if (!timeouts.has(key)) return

    clearTimeout(timeouts.get(key))
    timeouts.delete(key)
  }

  const resetAllDebounces = () => {
    timeouts.forEach((timeout) => {
      clearTimeout(timeout)
    })
    timeouts.clear()
  }

  return { debounce, resetDebounce, resetAllDebounces }
}
