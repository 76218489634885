import { API } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { mpaPrefixLegacy } from "./servicesUtils"

export const fetchSellers = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `${mpaPrefixLegacy}/sellers${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    {
      signal
    }
  )
}

export const createSeller = (formData) => {
  return API.post("/mp-admin/sellers", formData)
}

export const fetchSellerBySlug = (slug) => {
  return API.get(`${mpaPrefixLegacy}/sellers/${slug}`)
}

export const updateSeller = ({ slug, formData }) => {
  return API.patch(`${mpaPrefixLegacy}/sellers/${slug}`, formData)
}

export const fetchPaymentToken = ({ slug, code }) => {
  return API.get(`${mpaPrefixLegacy}/sellers/${slug}/payment-services/${code}`)
}

export const updatePaymentToken = ({ slug, code, data }) => {
  return API.post(`${mpaPrefixLegacy}/sellers/${slug}/payment-services/${code}`, data)
}

export const deletePaymentToken = ({ slug, code }) => {
  return API.delete(`${mpaPrefixLegacy}/sellers/${slug}/payment-services/${code}`)
}

export const fetchCustomers = ({ queryParams, signal }) => {
  const params = getFormatedQueryParams(queryParams)

  return API.get(`${mpaPrefixLegacy}/customers${params}`, { signal })
}

export const fetchPaymentServiceData = ({ slug, service }) => {
  return API.get(`${mpaPrefixLegacy}/sellers/${slug}/payment-services/${service}/`)
}

export const addPaymentServiceData = ({ sellerSlug, payload, service }) => {
  return API.post(`${mpaPrefixLegacy}/sellers/${sellerSlug}/payment-services/${service}/`, payload)
}

export const deletePaymentServiceData = ({ slug, service }) => {
  return API.delete(`${mpaPrefixLegacy}/sellers/${slug}/payment-services/${service}/`)
}
