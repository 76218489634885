<template>
  <div class="image-upload">
    <div
      class="dragzone"
      @drop.stop.prevent="handleDraggedFiles"
      @dragover.stop.prevent="handleDragOver"
    >
      <p>{{ $t("selectOrDragPhoto") }}</p>
      <a-button
        type="primary"
        @click="handleOpenFileSelect"
        >{{ $t("addPhoto") }}</a-button
      >
      <input
        style="visibility: hidden"
        type="file"
        name="files"
        multiple
        accept=".jpg, .jpeg, .png"
        @change.stop.prevent="handleSelectedFiles"
        ref="filesInput"
      />
    </div>
    <!-- <output>
      <ul>
        <li v-for="(img, index) in imgOutput" :key="'img-' + index + '-' + _uid">
          <span class="small-img-wrap">
            <img
              class="small-img"
              :src="img.src"
              :title="img.title"
            >
          </span>
        </li>
      </ul>
    </output> -->
  </div>
</template>

<script>
import { API_MODERATION } from "@/utils/HttpUtils"
import getImagePath from "utils/getImagePath"
import { IMAGES_CDN } from "@/constants/common"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "EditProductImagesUpload",
  components: {},
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      imgOutput: [],
      processing: false
    }
  },
  methods: {
    handleOpenFileSelect() {
      this.$refs.filesInput.click()
    },
    checkFileReaderSupport() {
      if (!window.File && !window.FileReader && !window.FileList) {
        console.log("The File APIs are not fully supported in this browser.")
      }
    },
    handleSelectedFiles(event) {
      const allowedMimeTypes = ["image/jpg", "image/jpeg", "image/png"]
      const files = event.target.files

      for (let i = 0; i < files.length; i++) {
        if (!allowedMimeTypes.includes(files[i].type)) {
          return this.$notification.error({
            message: `${this.$t("formatError")}: .jpg, .jpeg, .png`
          })
        }
      }

      this.emitFormData(files)
    },
    handleDraggedFiles(event) {
      const files = event.dataTransfer.files

      this.emitFormData(files)
    },
    handleDragOver(event) {
      // Explicitly show this is a copy.
      event.dataTransfer.dropEffect = "copy"
    },
    async emitFormData(files) {
      const formData = new FormData()
      // eslint-disable-next-line no-cond-assign
      for (let i = 0, f; (f = files[i]); i++) {
        // Only process image files.
        if (!f.type.match("image.*")) {
          continue
        }
        formData.append(i + "-" + f.name, f)
      }

      try {
        const results = await API_MODERATION.post("/product-catalog/moderation/images", formData)

        const updatedImages = results.data.message.map((item) => ({
          filename: item.filename,
          item: IMAGES_CDN + "/media/assets/images/" + getImagePath(item.filename, "full"),
          on_cdn: true
        }))

        this.$emit("onUpdateImages", updatedImages)
      } catch (error) {
        notifyResponseError({ error, message: this.$t("imageEditingFailed") })
      } finally {
        this.processing = false
      }
    }
  },
  created() {
    this.checkFileReaderSupport()
  }
}
</script>
<style scoped lang="scss">
.image-upload {
  position: relative;
  max-width: 50%;
  margin-top: 30px;
}

ul {
  padding-left: 0;
}

li {
  list-style-type: none;
  display: inline-block;
  margin-right: 1px;
  margin-bottom: 1px;
}

.small-img-wrap {
  display: inline-block;
  background-color: $background-color;
  border: 1px solid #cecece;
  border-radius: 3px;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  margin: 2px;
  padding: 2px;
  font-size: 0;
  line-height: 60px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.small-img {
  display: inline-block;
  max-width: 100%;
  margin: 0;
  height: auto;
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

input {
  display: inline-block;
  white-space: normal;
  background: none;
  margin: 0;
  border: none;
  padding: 0;
  line-height: auto;
  border-radius: 0;
  width: 0;
  height: 0;
  box-sizing: border-box;
  position: absolute;
}

.filesLabel {
  vertical-align: middle;
  border: none;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  border-radius: 3px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  background-color: $primary-color;
  width: fit-content;

  &:hover {
    filter: contrast(120%);
  }

  &:active {
    filter: contrast(140%);
  }

  &:disabled {
    opacity: 0.7;
  }

  &:disabled:hover {
    filter: contrast(100%);
  }

  &:disabled:active {
    filter: contrast(100%);
  }
}

.dragzone {
  border: 2px dashed #e9ecef;
  padding: 18px 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;

  p {
    font-size: $default-font-size;
    margin: 0 0 10px 0;
    line-height: $default-font-size;
  }
}
</style>
