import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { mpaPrefixLegacy } from "./servicesUtils.js"
import { API } from "@/utils/HttpUtils.js"

export const fetchInvoices = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `${mpaPrefixLegacy}/invoices${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const createInvoice = (payload) => {
  return API.post(`${mpaPrefixLegacy}/invoices`, payload)
}

export const updateInvoice = ({ uuid, payload }) => {
  return API.patch(`${mpaPrefixLegacy}/invoices/${uuid}`, payload)
}
