<template>
  <div class="promo-campaign-list">
    <PageHeader :title="$t('promoCampaignsList')">
      <!-- <template #filters>
        <div class="promo-campaign-list__date-picker">
          <div>{{ $t("creatingDate") }}:</div>
          <a-range-picker
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            v-model="filteredInfo.created_at"
            style="margin-top: 3px"
            @change="handleTableFilterChange()"
          >
            <template #suffixIcon>
              <a-icon type="clock-circle" />
            </template>
          </a-range-picker>
        </div>

        <div class="promo-campaign-list__date-picker">
          <div>{{ $t("finishingDate") }}:</div>
          <a-range-picker
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            v-model="filteredInfo.finishes_at"
            style="margin-top: 3px"
            @change="handleTableFilterChange()"
          >
            <template #suffixIcon>
              <a-icon type="clock-circle" />
            </template>
          </a-range-picker>
        </div>

        <a-input-search
          v-model="filteredInfo.name"
          :placeholder="$t('technicalName')"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-select
          v-model="filteredInfo.is_active"
          :placeholder="$t('status')"
          @change="handleTableFilterChange()"
        >
          <a-select-option value="true">
            {{ $t("isPromoActive") }}
          </a-select-option>
          <a-select-option value="false">
            {{ $t("isPromoInactive") }}
          </a-select-option>
        </a-select>
      </template> -->

      <template #actions>
        <a-button
          icon="undo"
          @click="handleTableFiltersReset()"
        >
          {{ $t("clearFilters") }}
        </a-button>

        <a-button
          type="primary"
          icon="plus"
          @click="handleShowCreateModal()"
        >
          {{ $t("createPromoCampaign") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :pagination="paginationInfo"
      rowKey="uuid"
      :columns="columns"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #isActiveRenderer="isActive">
        <span :class="[isActive ? 'active' : 'inactive']">
          {{ isActive ? $t("isPromoActive") : $t("isPromoInactive") }}
        </span>
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          shape="circle"
          icon="edit"
          @click="goToEditPage(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :width="500"
      :title="$t('createPromoCampaign')"
      :visible="showCreateModal"
      destroyOnClose
      @close="handleHideCreateModal()"
    >
      <CreateModal
        @close="handleHideCreateModal()"
        @submit="goToEditPage"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"
import useAntTableQuery from "@/composables/useAntTableQuery"
import { fetchPromoCampaigns } from "@/modules/MPAdmin/services/promoCampaignsService"
import { computed, onMounted, shallowRef } from "vue"
import usePromoListColumns from "./usePromoListColumns"
import router from "@/router"
import CreateModal from "./components/CreateModal.vue"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/TableRendererDateTime.vue"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: fetchPromoCampaigns,
  requestParams: { changeRouterQuery: true }
})

const columns = usePromoListColumns({
  sortedInfo: computed(() => sortedInfo.value)
})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const showCreateModal = shallowRef(false)
const handleShowCreateModal = () => {
  showCreateModal.value = true
}
const handleHideCreateModal = () => {
  showCreateModal.value = false
}
const goToEditPage = (record) => {
  router.push({ name: "EditPromoCampaign", params: { uuid: record.uuid } })
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.promo-campaign-list {
  &__date-picker {
    display: flex;
    align-items: center;
    gap: 8px;

    width: 340px;

    & > :first-child {
      white-space: nowrap;
      color: $dark;
    }
  }
}

.active {
  color: $green-color;
}

.inactive {
  color: $dark;
}
</style>
