<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="create-brand-modal"
  >
    <a-spin :spinning="isFetching">
      <a-form-model-item
        :label="nameLabel"
        prop="name"
      >
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item
        v-if="props.brand?.code"
        :label="$t('status')"
        prop="trust"
        class="create-brand-modal__trust"
      >
        <a-select v-model="form.trust">
          <a-select-option
            v-for="option in trustOptions"
            :value="option.key"
            :key="option.key"
          >
            <span :class="option.key">
              {{ option.label }}
            </span>
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-spin>

    <div class="drawer-footer">
      <a-button
        :disabled="isSubmiting || isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :icon="props.brand?.code ? false : 'plus'"
        :disabled="isFetching"
        :loading="isSubmiting"
        @click="onSubmit"
      >
        {{ props.brand?.code ? $t("save") : $t("add") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { reactive, ref, computed, onMounted, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import notifyResponseError from "@/utils/notifyResponseError.js"
import {
  fetchBrand,
  createBrand,
  updateBrand
} from "@/modules/Moderation/services/brandsService.js"
import useModerationOptions from "../../composables/useModerationOptions.js"
import { localeLabels } from "@/constants/locales.js"

const props = defineProps({
  brand: {
    type: Object,
    default: () => ({})
  },
  chosenLanguage: {
    type: String,
    default: "ru"
  }
})
const emit = defineEmits(["created", "updated", "close"])

const { trustOptions } = useModerationOptions()

const formRef = ref()
const form = reactive({
  name: undefined,
  trust: "UNTRUSTED"
})
const formRules = computed(() => ({
  name: [
    { required: true, message: i18n.t("emptyError") },
    { max: 50, message: i18n.t("maxLengthError", { length: 50 }) }
  ],
  trust: [{ required: true, message: i18n.t("emptyError") }]
}))

const nameLabel = computed(() => {
  if (!props.brand?.code) return `${i18n.t("brandName")} (${localeLabels.ru})`
  return `${i18n.t("brandName")} (${localeLabels[props.chosenLanguage]})`
})

const isSubmiting = shallowRef(false)
const onSubmit = async () => {
  const isValid = await formRef.value.validate().catch((status) => status)
  if (!isValid) return

  try {
    isSubmiting.value = true

    if (props.brand?.code) {
      const { data } = await updateBrand({
        payload: form,
        code: props.brand.code,
        language: props.chosenLanguage
      })
      notification.success({ message: i18n.t("updated") })
      emit("updated", data)
    } else {
      const { data } = await createBrand({ name: form.name })
      notification.success({ message: i18n.t("created") })
      emit("created", data)
    }
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubmiting.value = false
  }
}

const onClose = () => emit("close")

const isFetching = shallowRef(false)
const setBrand = async () => {
  if (!props.brand?.code) return

  try {
    isFetching.value = true

    const { data } = await fetchBrand({ code: props.brand.code, language: props.chosenLanguage })
    form.name = data.name
    form.trust = data.trust
  } catch (error) {
    notifyResponseError({ error })
    onClose()
  } finally {
    isFetching.value = false
  }
}

onMounted(setBrand)
</script>

<style lang="scss" scoped>
.create-brand-modal {
  padding-bottom: 50px;

  &__trust {
    max-width: 160px;
  }
}
</style>
