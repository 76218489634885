<template>
  <div>
    <a-page-header
      :title="title"
      class="table-header"
    >
      <template #extra>
        <template v-if="showLanguageSelect">
          <a-select
            :defaultValue="currentLanguage"
            style="width: 60px"
            @change="handleChange"
          >
            <a-select-option value="ru">{{ localeLabels.ru }}</a-select-option>
            <a-select-option value="uk">{{ localeLabels.uk }}</a-select-option>
            <a-select-option value="en">{{ localeLabels.en }}</a-select-option>
          </a-select>
        </template>
      </template>
    </a-page-header>

    <div class="table-header__footer">
      <div class="table-header__footer__filters">
        <slot name="filters" />
      </div>

      <div class="table-header__footer__actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { localeLabels } from "@/constants/locales"

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  showLanguageSelect: {
    type: Boolean,
    default: false
  },
  currentLanguage: {
    type: String,
    default: ""
  }
})
const emit = defineEmits(["onChangeLanguage"])

const handleChange = (value) => {
  emit("onChangeLanguage", { language: value })
}
</script>

<style lang="scss">
.table-header {
  padding: 16px 0;

  &__footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 16px;

    padding-bottom: 16px;

    &__filters {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;

      gap: inherit;

      & > * {
        width: 240px;
        margin-bottom: 0;
      }

      & .ant-calendar-picker {
        position: relative;
        top: -3px;
      }
    }

    &__actions {
      display: flex;
      flex-flow: row nowrap;
      gap: inherit;
    }
  }
}
</style>
