<template>
  <a-badge
    class="images"
    :number-style="{ backgroundColor: '#2db7f5' }"
    :count="images.length"
    :dot="false"
  >
    <img
      @click="onOpenGallery"
      :src="firstImageFullPath"
      @error.once="changeImageSize"
    />
  </a-badge>
</template>

<script setup>
import { computed, inject } from "vue"
import getImagePath from "utils/getImagePath"
import { IMAGES_CDN } from "@/constants/common"
import IMAGE_PLACEHOLDER_URL from "@/assets/images/img-placeholder.png"

const props = defineProps({
  images: {
    required: true,
    type: Array
  }
})

const galleryRef = inject("galleryRef")

const firstImageFullPath = computed(() => {
  if (props.images.length > 0) {
    const firstUrl = props.images[0]
    const isFullPath = firstUrl.startsWith(IMAGES_CDN) || firstUrl.startsWith("http")

    return isFullPath ? firstUrl : parseFullImagePath(firstUrl)
  } else {
    return IMAGE_PLACEHOLDER_URL
  }
})
const onOpenGallery = () => {
  galleryRef.value.handleEvent({ images: props.images })
}

const parseFullImagePath = (path, size = "100x100") => {
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(path, size)}`
}

const changeImageSize = (e) => {
  e.target.src = parseFullImagePath(props.images[0], "full")
}
</script>

<style lang="scss" scoped>
.images {
  height: 50px;
  width: 50px;
  position: relative;

  img {
    z-index: 1;
    max-width: 100%;
    position: absolute;
    top: 50%;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
  }
}
</style>
