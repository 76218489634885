import { API_ADS } from "@/utils/HttpUtils.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"

export const fetchRefferers = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `v1/admin/referrers/${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const subtractRef = (referralCode) => {
  return API_ADS.post(`/v1/admin/referrers/${referralCode}/commit-ref/`)
}
