import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const fetchCancellationReasons = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/order-reject-reasons/${getFormatedQueryParams(queryParams)}`, {
    signal
  })
}

export const fetchCancellationReasonsMeta = () => {
  return API.get("/mp-admin/order-reject-reasons/meta")
}

export const updateCancellationReason = (data, id) => {
  return API.put(`/mp-admin/order-reject-reasons/${id}`, data)
}

export const createCancellationReason = (data) => {
  return API.post("/mp-admin/order-reject-reasons", data)
}
