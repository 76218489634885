<template>
  <a-drawer
    :title="$t('addAttribute')"
    :visible="visible"
    :width="600"
    :maskStyle="{ animation: 'none', transition: 'none' }"
    :wrapStyle="{ animation: 'none', transition: 'none' }"
    @ok="onSubmit"
    @close="onClose"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        :label="$t('chooseAttribute')"
        prop="code"
      >
        <InfiniteScrollSelect
          :value="form.code"
          :customRequest="fetchAttributes"
          :placeholder="$t('search')"
          valueKey="code"
          labelKey="name"
          class="add-attribute-modal__form-field"
          @change="onAttributeChange"
        />
      </a-form-model-item>

      <a-form-model-item
        v-if="form.code"
        :label="$t('attributeValue')"
        prop="value"
      >
        <InfiniteScrollSelect
          ref="valueScrollRef"
          :value="form.value"
          :mode="allowMultipleValues ? 'multiple' : 'default'"
          :customRequest="fetchAttributeValuesList"
          :customRequestParams="{ attribute: form.code }"
          :defaultOptions="fetchedValues"
          labelKey="value"
          valueKey="code"
          searchKey="value_str"
          onFocusFetch
          class="add-attribute-modal__form-field"
          @change="onValueChange"
        >
          <template #option="{ option }">
            <a-tooltip placement="topLeft">
              <template #title>
                {{ option.value }}
                <template v-if="option?.unit?.name">{{ option.unit.name }}</template>
              </template>

              {{ option.value }}
              <template v-if="option?.unit?.name">{{ option.unit.name }}</template>
            </a-tooltip>
          </template>

          <template #extraButton>
            <a-divider style="padding: 0; margin: 0" />
            <a-button
              block
              type="link"
              icon="plus"
              style="padding: 0"
              @click="openCreateValueModal()"
            >
              {{ $t("addNewValue") }}
            </a-button>
          </template>
        </InfiniteScrollSelect>
      </a-form-model-item>

      <a-form-model-item
        :label="$t('orderingFull')"
        prop="order"
      >
        <a-input-number
          v-model="form.order"
          :step="1"
          :precision="0"
          :min="0"
          class="add-attribute-modal__form-field"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        :disabled="isSaving"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isSaving"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>

    <a-modal
      v-if="showCreateValue"
      :visible="true"
      @cancel="closeCreateValueModal"
    >
      <AddValueForm
        :attributeCode="mappedSelectedAttribute.code"
        :attributeType="mappedSelectedAttribute.value_type"
        :attributeUnit="mappedSelectedAttribute.default_unit"
        :defaultUnits="numericPossibleUnits"
        @submit="onNewValueCreated"
        @close="closeCreateValueModal"
      />
    </a-modal>
  </a-drawer>
</template>

<script setup>
import { ref, shallowRef, reactive, computed, watch } from "vue"
import i18n from "@/i18n"
import { message } from "ant-design-vue"

import { fetchAttributesByType } from "@/modules/Moderation/services/moderationProductsService.js"

import {
  fetchAttribute,
  fetchAttributes,
  fetchAttributeUnits
} from "@/modules/Moderation/services/attributesService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import { fetchAttributeValuesList } from "@/modules/Moderation/services/attributeValuesService"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"
import AddValueForm from "@/modules/Moderation/Attributes/EditAttribute/values/AddValueForm.vue"

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  product: {
    type: Object,
    default: () => ({})
  },
  mappedAttributes: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["addNewAttribute", "close"])

// FORM
const formRef = ref()
const form = reactive({
  code: undefined,
  value: undefined,
  order: 1
})
const formRules = computed(() => ({
  code: [{ required: true, message: i18n.t("emptyError") }],
  value: [{ required: true, message: i18n.t("emptyError") }]
}))

// ATTRIBUTES API
const isFetching = ref(false)

const fetchedAttributes = ref([])
const mappedSelectedAttribute = ref({})

const allowMultipleValues = computed(() => Boolean(mappedSelectedAttribute.value?.allow_multiple))

const onAttributeChange = (value) => {
  form.code = value
  if (!value) return
  fetchSelectedAttribute(value)
}

const setAttribute = async ({ value, is_main = false, is_required = false }) => {
  try {
    const { data: attribute } = await fetchAttribute({ code: value })
    mappedSelectedAttribute.value = {
      ...attribute,
      is_main,
      is_required
    }
  } catch (error) {
    notifyResponseError({ error })
  }
}

const fetchSelectedAttribute = async (value) => {
  if (props.mappedAttributes.some(({ code }) => code === value)) {
    message.error(i18n.t("duplicateAttributeError"))
    form.code = undefined
    return
  }

  if (!props.product.type?.code) {
    return setAttribute({ value })
  }

  try {
    isFetching.value = true

    const { data } = await fetchAttributesByType({
      code: props.product.type.code,
      queryParams: {}
    })

    const currentAttributeInType = data.results.find(({ code }) => code === value)

    if (!currentAttributeInType) {
      return setAttribute({ value })
    }

    mappedSelectedAttribute.value = currentAttributeInType
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("loadAttributesError") })

    setAttribute({ value })
  } finally {
    isFetching.value = false
  }
}

// VALUE
const valueScrollRef = ref()
const fetchedValues = ref([])

const onValueChange = (value) => {
  form.value = value

  if (!value.length) return
  if (
    fetchedValues.value.find(({ code }) =>
      Array.isArray(value) ? code === value.at(-1) : code === value
    )
  )
    return
  const newOption = valueScrollRef.value.selectOptions.find(({ code }) =>
    Array.isArray(value) ? code === value.at(-1) : code === value
  )

  fetchedValues.value.push(newOption)
}

// NEW VALUE

const showCreateValue = shallowRef(false)
const numericPossibleUnits = ref([])
const openCreateValueModal = async () => {
  if (mappedSelectedAttribute.value.value_type === "NUMERIC") {
    try {
      const { data } = await fetchAttributeUnits(mappedSelectedAttribute.value.code)
      numericPossibleUnits.value = data.results
    } catch (error) {
      notifyResponseError({ error })
    }
  }

  showCreateValue.value = true
}
const closeCreateValueModal = () => {
  showCreateValue.value = false
}
const onNewValueCreated = (value) => {
  fetchedValues.value.unshift(value)
  if (allowMultipleValues.value) {
    form.value.push(value.code)
  } else {
    form.value = value.code
  }
}

// SUBMIT AND CANCEL
const isSaving = ref(false)

const findAttributeValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((item) => valueScrollRef.value.selectOptions.find(({ code }) => code === item))
  } else {
    return [valueScrollRef.value.selectOptions.find(({ code }) => code === value)]
  }
}

const onSubmit = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  isSaving.value = true

  const params = {
    attr: {
      ...mappedSelectedAttribute.value,
      value: findAttributeValue(form.value)
    },
    order: form.order
  }

  resetForm()
  emit("addNewAttribute", params)

  isSaving.value = false
}

const onClose = () => {
  resetForm()
  emit("close")
}

const resetForm = () => {
  formRef.value.resetFields()
  formRef.value.clearValidate()
  fetchedAttributes.value = []
  fetchedValues.value = []
  mappedSelectedAttribute.value = {}
  isSaving.value = false
  isFetching.value = false
}

watch(
  () => mappedSelectedAttribute.value,
  (value) => {
    if (Boolean(value?.allow_multiple)) {
      form.value = []
    } else {
      form.value = ""
    }
  }
)
</script>

<style lang="scss" scoped>
.add-attribute-modal {
  &__form-field {
    width: 100%;
  }
}
</style>
