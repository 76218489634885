<template>
  <div>
    <PageHeader
      :title="$t('brands')"
      showLanguageSelect
      :currentLanguage="languageForDataFetch"
      @onChangeLanguage="onChangeLanguage"
    >
      <template #filters>
        <div class="date-filter">
          <div>{{ $t("creatingDate") }}:</div>
          <a-range-picker
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            style="margin-top: 3px"
            v-model="filteredInfo.created_at"
            @change="onFilterChange('created_at')"
          >
            <template #suffixIcon>
              <a-icon type="clock-circle" />
            </template>
          </a-range-picker>
        </div>

        <a-input-search
          v-for="field in searchFields"
          :key="field.value"
          v-model="filteredInfo[field.value]"
          :placeholder="field.label"
          allowClear
          @change="onFilterChange(field.key, Boolean($event?.target?.value))"
          @pressEnter="onFilterChange(field.key)"
        >
          <template #enterButton>
            <a-button
              icon="search"
              @focus="onFilterChange(field.key)"
            />
          </template>
        </a-input-search>

        <a-select
          v-model="filteredInfo.trust"
          :options="trustOptions"
          :placeholder="$t('status')"
          allowClear
          @change="onFilterChange('trust')"
        />
      </template>

      <template #actions>
        <a-button
          icon="undo"
          @click="onResetFilters()"
        >
          {{ $t("resetFilters") }}
        </a-button>
        <a-button
          icon="plus"
          type="primary"
          @click="handleShowBrandModal()"
        >
          {{ $t("addBrand") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      rowKey="code"
      :columns="columns"
      :pagination="paginationInfo"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #nameTitleRenderer>
        {{ $t("brandName") }} ({{ localeLabels[languageForDataFetch] }})
      </template>

      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #authorRenderer="author">
        {{ author?.name || "" }}
      </template>

      <template #trustRenderer="trust">
        <div :class="trust">
          {{ $t(`moderationTrustStatus.${trust}`) }}
        </div>
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          shape="circle"
          icon="edit"
          @click="onOpenEditModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="showBrandModal"
      :title="modalTitle"
      :width="600"
      destroyOnClose
      @close="onCloseBrandModal()"
    >
      <BrandModal
        :brand="chosenBrand"
        :chosenLanguage="languageForDataFetch"
        @updated="onBrandUpdated"
        @created="onBrandCreated"
        @close="onCloseBrandModal()"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import i18n from "@/i18n"

import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/TableRendererDateTime.vue"
import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"
import BrandModal from "./components/BrandModal.vue"

import useBrandsColumns from "./useBrandsColumns.js"
import useModerationOptions from "@/modules/Moderation/composables/useModarationOptions.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

import { fetchProductBrands } from "../services/brandsService.js"
import { localeLabels } from "@/constants/locales.js"
import useDebounce from "@/composables/useDebounce"

const languageForDataFetch = ref("ru")
const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchProductBrands,
  requestParams: { changeRouterQuery: true, language: computed(() => languageForDataFetch.value) }
})
const columns = useBrandsColumns({
  sortedInfo: computed(() => sortedInfo.value)
})
const { trustOptions } = useModerationOptions()
const { debounce, resetAllDebounces } = useDebounce()

const showBrandModal = ref(false)
const chosenBrand = ref()

const searchFields = computed(() => [
  {
    label: i18n.t("brandCode"),
    value: "code"
  },
  {
    label: i18n.t("brandName"),
    value: "name"
  },
  {
    label: i18n.t("author"),
    value: "created_by_name"
  }
])

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  resetAllDebounces()
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}
const onFilterChange = (key, delayRequest = false) => {
  resetAllDebounces()
  if (delayRequest) return debounce(key, handleTableFilterChange, 500)
  handleTableFilterChange()
}
const onResetFilters = () => {
  resetAllDebounces()
  handleTableFiltersReset()
}

const modalTitle = computed(() =>
  chosenBrand.value?.code
    ? `${i18n.t("editBrand")}: ${chosenBrand.value?.name}`
    : i18n.t("addBrand")
)

const handleShowBrandModal = () => {
  showBrandModal.value = true
}

const onOpenEditModal = (brand) => {
  chosenBrand.value = brand
  handleShowBrandModal()
}

const onCloseBrandModal = () => {
  chosenBrand.value = undefined
  showBrandModal.value = false
}

const onBrandUpdated = (payload) => {
  updateTableDataRecord({ payload, identifier: "code" })
  onCloseBrandModal()
}

const onBrandCreated = () => {
  onCloseBrandModal()
  onTableChange()
}

const onChangeLanguage = ({ language }) => {
  languageForDataFetch.value = language || "ru"
  onTableChange()
}

onMounted(() => {
  setupTable({ defaultSorter: { columnKey: "timestamp", order: "ascend" }, sortKey: "order_by" })
  onTableChange()
})
</script>

<style lang="scss" scoped>
.date-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 350px;

  & > :first-child {
    white-space: nowrap;
    color: $dark;
  }
}
</style>
