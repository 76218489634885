<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="tether-modal"
  >
    <a-form-model-item
      :label="$t('newAttribute')"
      prop="attribute"
    >
      <InfiniteScrollSelect
        :value="form.attribute"
        :customRequest="fetchAttributes"
        valueKey="code"
        labelKey="name"
        @change="onAttributeChange"
      />
    </a-form-model-item>

    <a-form-model-item
      prop="is_main"
      class="tether-modal__checkbox"
    >
      <a-checkbox v-model="form.is_main">
        <a-tooltip>
          <template #title>{{ $t("attributeIsMain") }}</template>
          Main
        </a-tooltip>
      </a-checkbox>
    </a-form-model-item>

    <a-form-model-item
      prop="is_required"
      class="tether-modal__checkbox"
    >
      <a-checkbox v-model="form.is_required">
        <a-tooltip>
          <template #title>{{ $t("attributeIsRequired") }}</template>
          Required
        </a-tooltip>
      </a-checkbox>
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isSubmitting"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isSubmitting"
        @click="onSubmit"
      >
        {{ $t("toTether") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { reactive, ref, computed, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

import {
  fetchAttributes,
  attachTypeToAttribute
} from "@/modules/Moderation/services/attributesService.js"

import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  type: {
    type: Object,
    required: true
  },

  totalOrderLength: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(["created", "close"])

const formRef = ref()
const form = reactive({
  attribute: undefined,
  is_main: false,
  is_required: false
})
const formRules = computed(() => ({
  attribute: [{ required: true, message: i18n.t("emptyError") }]
}))

const onAttributeChange = (value) => {
  form.attribute = value
}

const isSubmitting = shallowRef(false)
const onSubmit = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  try {
    isSubmitting.value = true

    await attachTypeToAttribute({
      attributeCode: form.attribute,
      payload: {
        product_type: props.type.code,
        is_main: form.is_main,
        is_required: form.is_required,
        order: props.totalOrderLength
      }
    })

    notification.success({ message: i18n.t("tethered") })
    onClose()
    emit("created")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubmitting.value = false
  }
}
const onClose = () => emit("close")
</script>

<style lang="scss" scoped>
.tether-modal {
  padding-bottom: 50px;

  &__checkbox {
    margin: 0;
  }
}
</style>
