// New query builder for ant table

/**
 * Function that transorm table params to object that we use as query
 *
 * @param {object} pagination
 * @param {object} [filters]
 * @param {object} [sorter]
 * @param {string} sorterKey for custom sorter key. Default: ordering
 *
 * @return {object}
 */

const buildTableQueryParams = ({ pagination, filters, sorter, sorterKey = "ordering" }) => {
  const queryParams = {}

  if (filters) {
    // iterate filters and transform to string
    for (const [key, value] of Object.entries(filters)) {
      if ((!value || !value.length) && typeof value !== "number") continue

      if (key.endsWith("_at")) {
        queryParams[key + "_min"] = value[0]
        queryParams[key + "_max"] = value[1]
        continue
      }

      if (key.endsWith("_interval")) {
        queryParams[key.replace("_interval", "_min")] = value[0]
        queryParams[key.replace("_interval", "_max")] = value[1]
        continue
      }

      if (key.endsWith("_range")) {
        queryParams[key.replace("_range", "_from")] = value[0]
        queryParams[key.replace("_range", "_to")] = value[1]
        continue
      }

      queryParams[key] = Array.isArray(value) ? value.join() : value
    }
  }

  // Add pagination params
  queryParams.page = pagination?.current || 1
  queryParams.pageSize = pagination?.pageSize || 10

  // check for sorter, if it exist and have order add ordering to obj
  if (sorter && sorter.order) {
    queryParams[sorterKey] = sorter.order === "descend" ? sorter.columnKey : `-${sorter.columnKey}`
  }

  // remove blank attributes and return
  return Object.fromEntries(Object.entries(queryParams).filter(([_, value]) => value !== ""))
}

export default buildTableQueryParams
