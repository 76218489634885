import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API } from "@/utils/HttpUtils"

export const fetchDeliveryServices = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/delivery-services/${getFormatedQueryParams(queryParams)}`, { signal })
}

export const createDeliveryService = (formData) => {
  return API.post("/mp-admin/delivery-services/", formData)
}

export const updateDeliveryService = (formData, id) => {
  return API.put(`/mp-admin/delivery-services/${id}`, formData)
}
