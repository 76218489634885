<template>
  <div>
    <div class="wrapper">
      <div
        v-if="fetching"
        class="wrapper__loader"
      >
        <a-spin size="large" />
      </div>

      <a-form-model
        v-else
        ref="form"
        :model="form"
        layout="vertical"
        hide-required-mark
      >
        <a-row :gutter="24">
          <a-col :span="15">
            <a-form-model-item :label="$t('nameForPublish')">
              <a-input
                :value="product.public_title"
                disabled
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="9">
            <a-form-model-item :label="$t('naming')">
              <a-input
                :value="product.name"
                disabled
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="15">
            <a-form-item
              :label="`${$t('translationTitleForPublish')} (${locale})`"
              :help="!hasDefaultPublicTitle ? $t('setValueInMainLocale') : ''"
            >
              <a-input
                v-model="form.public_title"
                :disabled="!hasDefaultPublicTitle"
                @change="$emit('formChanged')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item
              :label="`${$t('nameTranslation')} (${locale})`"
              :help="!hasDefaultName ? $t('setValueInMainLocale') : ''"
            >
              <a-input
                v-model="form.name"
                :disabled="!hasDefaultName"
                @change="$emit('formChanged')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item :label="$t('shortProductDescription')">
              <a-textarea
                :rows="5"
                :value="product.short_description"
                disabled
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item
              :label="$t('shortDescriptionTranslation')"
              :help="!hasDefaultShortDescription ? $t('setValueInMainLocale') : ''"
            >
              <a-textarea
                :rows="5"
                :disabled="!hasDefaultShortDescription"
                v-model="form.short_description"
                @change="$emit('formChanged')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item :label="$t('description')">
              <a-textarea
                :rows="15"
                :value="product.description"
                disabled
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item
              :label="`${$t('descriptionTranslate')} (${locale})`"
              :help="!hasDefaultDescription ? $t('setValueInMainLocale') : ''"
            >
              <EditorHtml
                v-model="form.description"
                :use-custom-image-handler="true"
                :replace-video-iframe="true"
                :disabled="!hasDefaultDescription"
                id="productEditDescription"
                @change="handleEditorHtml"
              />
              <!--              <a-textarea-->
              <!--                :rows="15"-->
              <!--                :disabled="!hasDefaultDescription"-->
              <!--                v-model="form.description"-->
              <!--                @change="$emit('formChanged')"-->
              <!--              />-->
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import EditorHtml from "@/components/Editor/EditorHtml"

export default {
  name: "EditProductLocalizationCommonTab",
  components: {
    EditorHtml
  },
  props: {
    mpcCode: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      default: () => {}
    },
    productLocalized: {
      type: Object,
      default: () => {}
    },
    fetching: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        name: "",
        public_title: "",
        short_description: "",
        description: ""
      }
    }
  },
  computed: {
    hasDefaultName() {
      return this.product && this.product.name
    },
    hasDefaultPublicTitle() {
      return this.product && this.product.public_title
    },
    hasDefaultShortDescription() {
      return this.product && this.product.short_description
    },
    hasDefaultDescription() {
      return this.product && this.product.description
    }
  },
  watch: {
    productLocalized(value) {
      if (!this.isEmpty(value)) {
        // Set form values if product data not empty
        const description = value.description || ""

        this.form.name = value.name
        this.form.public_title = value.public_title
        this.form.short_description = value.short_description
        this.form.description = description.trim() || this.product.description
      } else {
        // clear after modal close
        this.form.name = ""
        this.form.public_title = ""
        this.form.short_description = ""
        this.form.description = ""
      }
    }
  },
  methods: {
    handleEditorHtml(value) {
      if (
        this.productLocalized.description !== undefined &&
        this.productLocalized.description !== value
      ) {
        this.$emit("formChanged")
      }
    },

    getLocalizedFormValues() {
      return new Promise(async (res, rej) => {
        this.$refs.form.validate((isValid, errors) => {
          if (!isValid) {
            const emptyFields = Object.keys(errors)
              .map((field) => i18n.t(field))
              .join(", ")

            this.$notification.error({
              message: this.$t("generalProductFormFilledWithErrors"),
              description: `${this.$t("fillFields")}: ${emptyFields}`
            })
            rej(null)
          }

          res(this.form)
        })
      })
    },

    isEmpty(value) {
      if (value == null) return true
      if (typeof value === "object") return !Object.keys(value).length
      if (typeof value === "string" || Array.isArray(value)) return !value.length
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: 80px;

  &__loader {
    z-index: 9;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.product-type-name {
  padding-top: 5px;
}

.use-public-title-switch {
  margin-top: 5px;
}

.use-public-title-input {
  margin-left: 16px;
  float: right;
  width: calc(100% - 60px);
}

.product-full-name {
  margin-bottom: 20px;
}

.gtin-add-button {
  cursor: pointer;
}
</style>
