<template>
  <div class="edit-promo-campaign">
    <a-page-header
      :title="pageTitle"
      class="edit-promo-campaign__header"
      @back="handleBack()"
    >
      <template #extra>
        <a-button
          :disabled="!isFormChanged || isFetching"
          @click="handleCancel"
        >
          {{ $t("cancel") }}
        </a-button>
        <a-button
          type="primary"
          :disabled="!isFormChanged"
          :loading="isFetching"
          @click="handleSave"
        >
          {{ $t("save") }}
        </a-button>
      </template>
    </a-page-header>

    <a-spin :spinning="isFetching">
      <a-form-model
        ref="formRef"
        :model="form"
        :rules="formRules"
        class="edit-promo-campaign__form"
      >
        <a-form-model-item
          :label="$t('creatingDate')"
          prop="created_at"
        >
          <a-date-picker
            v-model="form.created_at"
            disabled
            :showTime="{
              defaultValue: defaultTime
            }"
            format="DD.MM.YYYY HH:mm"
            valueFormat="YYYY-MM-DDTHH:mm"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('finishingDate')"
          prop="finishes_at"
        >
          <a-date-picker
            v-model="form.finishes_at"
            :disabledDate="disabledDate"
            :showTime="{
              defaultValue: defaultTime
            }"
            format="DD.MM.YYYY HH:mm"
            valueFormat="YYYY-MM-DDTHH:mm"
            @change="isFormChanged = true"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('technicalName')"
          prop="name"
          class="edit-promo-campaign__form__name"
        >
          <a-input
            v-model="form.name"
            @change="isFormChanged = true"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-button
            type="primary"
            ghost
            icon="plus"
            @click="handleShowOffersModal"
          >
            {{ $t("addOffers") }}
          </a-button>
        </a-form-model-item>

        <a-form-item
          :label="$t('promoCampaignActivity')"
          class="edit-promo-campaign__form__activity"
        >
          <a-radio-group
            v-model="form.is_active"
            @change="isFormChanged = true"
          >
            <a-radio-button :value="true">{{ $t("isPromoActive") }}</a-radio-button>
            <a-radio-button :value="false">{{ $t("isPromoInactive") }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-form-model>
    </a-spin>

    <a-drawer
      :width="700"
      :title="$t('addOffers')"
      :visible="showOffersModal"
      destroyOnClose
      @close="handleHideOffersModal()"
    >
      <AddOffersModal
        :promoUuid="campaignUuid"
        @close="handleHideOffersModal()"
        @submit="onOffersAdded"
      />
    </a-drawer>

    <OffersTable
      ref="offersTableRef"
      :campaignUuid="campaignUuid"
    />
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import {
  fetchSinglePromoCampaign,
  updatePromoCampaign
} from "@/modules/MPAdmin/services/promoCampaignsService"
import notifyResponseError from "@/utils/notifyResponseError"
import { message, notification } from "ant-design-vue"
import { computed, onMounted, reactive, ref, shallowRef } from "vue"
import AddOffersModal from "./components/AddOffersModal.vue"
import OffersTable from "./components/OffersTable.vue"
import moment from "moment"
import router from "@/router"

const campaignUuid = computed(() => router.currentRoute.params.uuid)

const campaignInfo = ref()
const isFetching = shallowRef(false)
const isFormChanged = shallowRef(false)

const pageTitle = computed(
  () => `${i18n.t("promoCampaignEditing")}: ${campaignInfo.value?.name || ""}`
)

const getPromoCampaignInfo = async () => {
  try {
    isFetching.value = true

    const { data } = await fetchSinglePromoCampaign(campaignUuid.value)

    campaignInfo.value = {
      ...data,
      created_at: moment(data.created_at).local(),
      finishes_at: moment(data.finishes_at).local()
    }
    setForm()
    isFormChanged.value = false
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const handleBack = () => router.push({ name: "PromoCampaignsList" })
const handleCancel = () => getPromoCampaignInfo()

const formRef = ref()
const form = reactive({
  created_at: undefined,
  finishes_at: undefined,
  name: undefined,
  is_active: false
})
const requiredRule = computed(() => [{ required: true, message: i18n.t("emptyError") }])
const formRules = {
  created_at: requiredRule.value,
  finishes_at: requiredRule.value,
  name: requiredRule.value
}

const setForm = (data = campaignInfo.value) => {
  Object.keys(form).forEach((key) => {
    form[key] = data[key]
  })
}

const defaultTime = computed(() => {
  return moment("00:00:00", "HH:mm:ss")
})
const disabledDate = (current) => {
  return current && current < moment().endOf("day")
}

const handleSave = async () => {
  const isValid = await formRef.value.validate().catch((status) => status)
  if (!isValid) return message.error("fillFields")

  try {
    isFetching.value = true
    const { created_at, ...payload } = form

    payload.finishes_at = moment(payload.finishes_at).local()

    await updatePromoCampaign({ uuid: campaignInfo.value.uuid, payload })
    notification.success({ message: i18n.t("updated") })
    getPromoCampaignInfo()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const showOffersModal = shallowRef(false)
const handleShowOffersModal = () => {
  showOffersModal.value = true
}
const handleHideOffersModal = () => {
  showOffersModal.value = false
}

const offersTableRef = ref()
const onOffersAdded = () => {
  offersTableRef.value?.handleTableFiltersReset?.()
}

onMounted(getPromoCampaignInfo)
</script>

<style lang="scss" scoped>
.edit-promo-campaign {
  max-width: 1600px;
  margin: 0 auto;

  &__header {
    padding: 16px 0;
  }

  &__form {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    gap: 16px;

    &__name {
      width: 525px;
      max-width: 50%;
    }

    &__activity {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: space-between;

      padding: 16px 0;
      margin-left: auto;

      border: 1px solid $border-color;
      border-radius: 2px;
    }
  }
}
</style>
