<template>
  <div class="category-moderation">
    <a-page-header
      class="page-header"
      :title="$route.params.id ? $t('categoryEditing') : $t('categoryCreating')"
      @back="goToCategoriesList"
    >
      <a-breadcrumb style="margin-bottom: 10px; margin-top: 8px">
        <a-breadcrumb-item
          class="category-breadcrumb"
          v-for="(item, index) in breadcrumbs"
          :key="`crumb-${item}`"
        >
          <span @click="index === breadcrumbs.length - 1 ? undefined : goToParentCategory()">
            {{ item }}
          </span>
        </a-breadcrumb-item>
      </a-breadcrumb>

      <template #extra>
        <a-button
          :disabled="disableButtons"
          @click="goToCategoriesList"
        >
          {{ $t("reject") }}
        </a-button>

        <a-popconfirm
          v-if="$route.params.id"
          :title="$t('deletingConfirmation')"
          @confirm="handleDeleteCategory"
        >
          <a-button
            type="danger"
            :disabled="disableButtons && !isDeleting"
            :loading="isDeleting"
            ghost
          >
            {{ $t("deleteCategory") }}
          </a-button>
        </a-popconfirm>

        <a-button
          v-if="$route.params.id"
          type="primary"
          :disabled="disableButtons && !isIndexing"
          :loading="isIndexing"
          @click="handleReindex($route.params.id)"
        >
          {{ $t("reindex") }}
        </a-button>

        <a-button
          :disabled="disableButtons && !isSaving"
          :loading="isSaving"
          type="primary"
          @click="handleSave"
        >
          {{ saveButtonText }}
        </a-button>
      </template>

      <div class="category-moderation__tabs">
        <a-tabs
          v-model="activeTab"
          @change="(e) => handleChangeTab(e)"
        >
          <a-tab-pane
            key="general"
            :tab="$t('generalSettings')"
          />
          <a-tab-pane
            key="seo"
            :tab="'SEO ' + $t('settings')"
          />
          <a-tab-pane
            :disabled="!$route.params.id"
            key="filters"
            :tab="$t('filters')"
          />
        </a-tabs>
      </div>
    </a-page-header>

    <a-spin
      :spinning="fetching || disableButtons"
      style="margin: 0 auto 48px auto"
    >
      <GeneralSettings
        v-if="!fetching"
        ref="general"
        v-show="activeTab === 'general'"
        :loading="isSaving || isDeleting"
        :image="categoryData.image"
        :is_active="categoryData.is_active"
        :name="categoryData.name"
        :parent="categoryData.parent?.id"
        :is_shown_condition="categoryData.is_shown_condition"
        :is_shown_warranty="categoryData.is_shown_warranty"
        :is_available_return="categoryData.is_available_return"
        :ar_d="categoryData.ar_d"
        :icon="categoryData.icon"
        :description="categoryData.description"
        @deleteImage="deleteImage"
        @formChanged="handleFormChange"
      />

      <SeoSettings
        v-if="!fetching"
        ref="seo"
        v-show="activeTab === 'seo'"
        :languages="languages"
        :seo_title="categoryData.seo_title"
        :seo_description="categoryData.seo_description"
        :seo_keywords="categoryData.seo_keywords"
        :seo_text="categoryData.seo_text"
        @formChanged="handleFormChange"
      />

      <Filters
        ref="filters"
        v-show="activeTab === 'filters'"
        :languages="languages"
        :filters="categoryData.filters"
        :filtersIsChanged="filtersIsChanged"
        :isFetching="fetching"
        @handleFieldChange="handleFieldChange"
        @downloadFilterOffers="onDownloadFilterOffers"
        @handleAddNewFilter="handleAddNewFilter"
        @deleteFilter="handleDeleteFilter"
        @filtersChanged="handleFiltersChange"
      />
    </a-spin>
  </div>
</template>

<script>
import GeneralSettings from "./components/GeneralSettings.vue"
import SeoSettings from "./components/SeoSettings.vue"
import Filters from "./components/Filters.vue"
import getCurrentLocale from "@/utils/getCurrentLocale.js"
import {
  deleteCategoryById,
  fetchCategoryById,
  fetchFilterTaskReport,
  reindexCategory,
  updateCategoryById,
  updateCategoryFilters
} from "@/modules/MPAdmin/services/categoriesService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

export default {
  name: "EditCategory",

  metaInfo: {
    title: "Редагування категорії"
  },

  components: {
    GeneralSettings,
    SeoSettings,
    Filters
  },

  data() {
    return {
      fetching: true,
      isSaving: false,
      isDeleting: false,
      isIndexing: false,

      formIsChanged: false,
      filtersIsChanged: false,
      tabsList: ["general", "seo", "filters"],
      activeTab: "general",

      categoryData: {
        filters: [],
        seo_title: {},
        seo_description: {},
        seo_keywords: {},
        seo_text: {}
      },
      languages: [
        { label: "UA", value: "uk" },
        { label: "EN", value: "en" },
        { label: "RU", value: "ru" }
      ]
    }
  },

  async mounted() {
    if (this.$route.params?.id) {
      this.fetching = true
      this.getCategoryData()
    } else {
      this.fetching = false
    }

    if (this.tabsList.includes(this.$route.query.tab)) {
      this.activeTab = this.$route.query.tab
    } else {
      this.$router.replace({ query: { ...this.$route.query, tab: undefined } })
    }

    this.$message.config({ maxCount: 1 })
  },

  computed: {
    saveButtonText() {
      return this.activeTab === "filters"
        ? `${this.$t("save")} ${this.$t("filters").toLowerCase()}`
        : `${this.$t("save")} ${this.$t("main").toLowerCase()} + SEO`
    },
    breadcrumbs() {
      return this.categoryData?.parent
        ? [
            this.categoryData.parent?.name[getCurrentLocale()],
            this.categoryData?.name[getCurrentLocale()]
          ]
        : [this.categoryData?.name?.uk ? this.categoryData?.name[getCurrentLocale()] : ""]
    },

    disableButtons() {
      return this.isIndexing || this.isSaving || this.isDeleting
    }
  },

  methods: {
    async getCategoryData() {
      try {
        const { data } = await fetchCategoryById(this.$route.params.id)

        this.categoryData = data
      } catch (error) {
        notifyResponseError({ error })

        this.$router.push({ name: "categories" })
      }

      this.$nextTick(() => {
        this.fetching = false
        this.formIsChanged = false
        this.filtersIsChanged = false
      })
    },

    handleFormChange() {
      if (!this.formIsChanged) {
        this.formIsChanged = true
      }
    },

    handleFiltersChange() {
      if (!this.filtersIsChanged) {
        this.filtersIsChanged = true
      }
    },

    goToCategoriesList() {
      if (this.formIsChanged || this.filtersIsChanged) {
        this.$confirm({
          title: this.$t("changesNotSaved"),
          content: this.$t("leavePage"),
          cancelText: this.$t("no"),
          okText: this.$t("yes"),
          onOk: () => {
            this.$router.push({ name: "categories" })
          }
        })
      } else {
        this.$router.push({ name: "categories" })
      }
    },

    goToParentCategory() {
      if (this.formIsChanged || this.filtersIsChanged) {
        this.$confirm({
          title: this.$t("changesNotSaved"),
          content: this.$t("leavePage"),
          cancelText: this.$t("no"),
          okText: this.$t("yes"),
          onOk: () => {
            this.$router.push({
              name: "EditCategory",
              params: { id: this.categoryData.parent.id }
            })
            this.fetching = true
            this.getCategoryData()
            this.activeTab = "general"
          }
        })
      } else {
        this.$router.push({
          name: "EditCategory",
          params: { id: this.categoryData.parent.id }
        })
        this.fetching = true
        this.getCategoryData()
        this.activeTab = "general"
      }
    },

    handleChangeTab(value) {
      this.$router.replace({ query: { tab: value === "general" ? undefined : value } })
    },

    handleFieldChange({ value, index, field }) {
      this.handleFiltersChange()
      this.categoryData.filters[index][field] = value
    },

    onDownloadFilterOffers(rowItem) {
      this.$message.info(this.$t("downloadCategoryReportWillStart"))
      fetchFilterTaskReport(this.categoryData, rowItem)
    },

    hasEmptyNames(dataSource) {
      return dataSource.some((row) => Object.values(row.name).some((item) => item.trim() === ""))
    },

    hasDuplicateNames(dataSource) {
      const ukTitles = new Set()
      const enTitles = new Set()
      const ruTitles = new Set()

      for (const row of dataSource) {
        ukTitles.add(row.name?.uk)
        enTitles.add(row.name?.en)
        ruTitles.add(row.name?.ru)
      }

      return (
        ukTitles.size !== dataSource.length ||
        enTitles.size !== dataSource.length ||
        ruTitles.size !== dataSource.length
      )
    },

    async handleReindex(id) {
      this.isIndexing = true

      try {
        await reindexCategory(id)

        this.$message.success(this.$t("reindexSuccess"))
      } catch {
        this.$message.error(this.$t("reindexFailure"))
      } finally {
        this.isIndexing = false
      }
    },

    async handleSave() {
      if (this.activeTab === "filters") {
        try {
          this.isSaving = true

          if (this.hasEmptyNames(this.categoryData.filters)) {
            this.$message.error(this.$t("filterNamesEmptyError"))
            return
          }

          if (this.hasDuplicateNames(this.categoryData.filters)) {
            this.$message.error(this.$t("filterNamesDuplicateError"))
            return
          }

          const newFilters = this.categoryData.filters.map((item, index) => {
            return { ...item, ordering: index, group_title: undefined, group_code: undefined }
          })

          await updateCategoryFilters(this.categoryData.id, newFilters)

          this.$notification.success({ message: this.$t("updated") })
          this.getCategoryData()

          this.filtersIsChanged = false
        } catch (error) {
          notifyResponseError({ error })
        } finally {
          this.isSaving = false
        }
      } else {
        const seoFields = this.$refs.seo.getFields()
        let generalFields = this.$refs.general.getFields()

        if (seoFields && generalFields) {
          this.isSaving = true
          let formData = new FormData()

          for (const key in seoFields) {
            formData.append(key, JSON.stringify(seoFields[key]))
          }

          for (const key in generalFields) {
            if (key !== "icon") {
              formData.append(
                key === "image" ? "image.img" : key,
                key === "image" ? generalFields.image.img : JSON.stringify(generalFields[key])
              )
            }
          }

          if (generalFields.icon) {
            formData.append("icon", [generalFields.icon])
          } else formData.append("icon", ``)

          try {
            const { data } = await updateCategoryById(formData, this.$route.params?.id || null)

            this.$notification.success({ message: this.$t("updated") })
            if (!this.$route.params?.id) {
              this.$router.push({ name: "EditCategory", params: { id: data.id } })
            }

            this.formIsChanged = false
            this.getCategoryData()
          } catch (error) {
            notifyResponseError({ error })
          } finally {
            this.isSaving = false
          }
        }
      }
    },

    async handleDeleteCategory() {
      this.isDeleting = true

      try {
        await deleteCategoryById(this.$route.params.id)

        this.$notification.success({
          message: this.$t("deleted")
        })
        this.$router.push({ name: "categories" })
      } finally {
        this.isDeleting = false
      }
    },

    deleteImage() {
      this.categoryData.image = null
    },

    handleAddNewFilter(filter) {
      this.handleFiltersChange()
      this.categoryData.filters.push(filter)
    },

    handleDeleteFilter(rowIndex) {
      this.handleFiltersChange()
      this.categoryData.filters.splice(rowIndex, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.category-moderation {
  max-width: 1570px;
  margin: 0 auto;
  &__tabs {
    display: flex;
    justify-content: space-between;
    margin: 0;
    &__state {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }
}

.category-breadcrumb {
  cursor: pointer;
  &:last-child {
    cursor: default;
  }
}
.page-header {
  padding: 16px 0;
  margin: 30px 0 64px 0;
}
</style>
