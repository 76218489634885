import i18n from "@/i18n"
import { computed } from "vue"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => [
    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: i18n.t("dateCreated"),
      scopedSlots: { customRender: "dateRenderer" },
      width: 160,
      sorter: true,
      sortOrder: sortedInfo?.value.columnKey === "timestamp" && sortedInfo?.value.order,
      fixed: "left"
    },
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("brandCode"),
      width: 330,
      fixed: "left"
    },
    {
      key: "name",
      dataIndex: "name",
      scopedSlots: {
        title: "nameTitleRenderer"
      }
    },
    {
      key: "created_by",
      dataIndex: "created_by",
      title: i18n.t("author"),
      scopedSlots: { customRender: "authorRenderer" },
      width: 330
    },
    {
      key: "trust",
      dataIndex: "trust",
      title: i18n.t("status"),
      scopedSlots: { customRender: "trustRenderer" },
      width: 330
    },
    {
      key: "actions",
      scopedSlots: { customRender: "actions" },
      fixed: "right",
      width: 65
    }
  ])

  return columns
}
