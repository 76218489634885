import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { mpaPrefix } from "./servicesUtils"
import { API_V2 } from "@/utils/HttpUtils"

export const fetchPromoCampaigns = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${mpaPrefix}/promo-campaigns${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchSinglePromoCampaign = (uuid) => {
  return API_V2.get(`${mpaPrefix}/promo-campaigns/${uuid}`)
}

export const createPromoCampaign = (payload) => {
  return API_V2.post(`${mpaPrefix}/promo-campaigns`, payload)
}

export const updatePromoCampaign = ({ uuid, payload }) => {
  return API_V2.put(`${mpaPrefix}/promo-campaigns/${uuid}`, payload)
}

export const fetchPromoCampaignOffers = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${mpaPrefix}/promo-campaigns/${requestParams?.promoUuid}/offers${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const addOffersToPromoCampaign = ({ uuid, payload }) => {
  return API_V2.post(`${mpaPrefix}/promo-campaigns/${uuid}/offers`, payload)
}

export const removeOfferFromPromoCampaign = ({ promoUuid, offerUuid }) => {
  return API_V2.delete(`${mpaPrefix}/promo-campaigns/${promoUuid}/offers/${offerUuid}`)
}
