<template>
  <div class="file-wrapper">
    <div
      class="file-wrapper__item"
      v-for="(file, index) in files"
      :key="file.id"
    >
      <div
        v-if="showPreview"
        class="file-wrapper__item__image"
      >
        <img :src="file.preview" />
      </div>
      <div class="description">
        {{ file.name }}
      </div>
      <a-icon
        class="file-wrapper__item--danger"
        type="close"
        @click="removeFile(index)"
        @touchdown="removeFile(index)"
      />
    </div>

    <div
      v-if="disabled"
      class="disabled-section"
    >
      <a-spin />
    </div>

    <div class="load-section">
      <label style="padding: 0">
        {{ $t("selectFiles") }}
        <span class="loader-wrapper">
          <a-spin v-if="inProcessReading !== 0" />
        </span>
        <input
          type="file"
          :accept="acceptTypes.length ? acceptTypes : ''"
          multiple
          @change="addFiles"
          ref="fileInput"
        />
        <small>
          {{ limit != Infinity ? `${$t("maxQuantity")} - ${limit}` : `` }}
          {{ allowedFormats.length ? `${$t("allowedFormats")} - ${getAllowedFormats}.` : "" }}
          {{ maxKb ? $t("maxFileSize", { size: formatMaxSize }) : "" }}
        </small>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileLoader",

  props: {
    limit: {
      type: Number,
      default: Infinity
    },
    allowedFormats: {
      type: Array,
      default: () => []
    },
    acceptTypes: {
      type: Array,
      default: () => []
    },
    showPreview: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxKb: {
      type: Number,
      default: Infinity
    }
  },

  data() {
    return {
      files: [],
      value: null,
      inProcessReading: 0
    }
  },

  methods: {
    addFiles(event) {
      const eventFiles = event.target.files
      let fileReaderLoadCount = 0
      let readFiles = 0

      for (let i = 0; i < eventFiles.length; i++) {
        const file = eventFiles.item(i)

        if (this.limit > i + this.files.length) {
          readFiles++

          if (
            (this.allowedFormats.length && !this.validateFormat(file, this.allowedFormats)) ||
            !this.validateSize(file)
          ) {
            continue
          }

          let fileReader = new FileReader()

          fileReader.addEventListener("loadend", (event) => {
            this.inProcessReading -= 1
          })

          fileReader.addEventListener("loadstart", (event) => {
            this.inProcessReading += 1
          })

          fileReader.addEventListener("load", (event) => {
            const fileObject = {
              file: file,
              preview: event.target.result,
              name: file.name,
              size: file.size / 1024
            }

            this.files.push(fileObject)

            fileReaderLoadCount += 1

            if (fileReaderLoadCount == readFiles) this.emitChange()
          })

          fileReader.readAsDataURL(file)
        } else {
          this.showLimitError()
          break
        }
      }
    },
    showLimitError() {
      this.$notification.error({ message: `${this.$t("maxUploadedFilesNumber")} - ${this.limit}` })
    },
    showInvalidDataError() {
      this.$notification.error({ message: this.$t("unsupportedFormat") })
    },
    showInvalidSizeError() {
      this.$notification.error({ message: this.$t("maxFileSize", { size: this.formatMaxSize }) })
    },
    emitChange() {
      const eventData = {
        files: this.files.map((fileObject) => fileObject.file)
      }

      this.$emit("change", eventData)
    },
    removeFile(index) {
      this.files.splice(index, 1)

      this.emitChange()
    },
    endsWith(str, suffix) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1
    },
    validateFormat(file, allowedFormats) {
      if (!this.allowedFormats.some((format) => this.endsWith(file.name, format))) {
        this.showInvalidDataError()
        return false
      }

      return true
    },
    validateSize(file) {
      if (!this.maxKb || file.size / 1024 < this.maxKb) return true

      this.showInvalidSizeError()
      return false
    }
  },

  computed: {
    formatMaxSize() {
      return (this.maxKb / 1024).toFixed(2)
    },
    getAllowedFormats() {
      return this.allowedFormats.join(", ")
    }
  }
}
</script>

<style lang="scss" scoped>
.middle {
  vertical-align: middle;
}

.file-wrapper {
  border: 1px solid $light-border;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  display: block;
  box-shadow: $light-shadow;

  &__item {
    border-bottom: 1px solid $light-border;
    position: relative;
    padding: 10px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__image {
      width: 20%;
      aspect-ratio: 1;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &--danger {
      color: $red-color;
    }
  }

  & .disabled-section {
    position: absolute;
    cursor: default;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: #ffffffdd;

    & span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
    }
  }

  & .load-section {
    line-height: 21px;
    position: relative;

    & label {
      padding: 10px;
      color: $primary-color;
      line-height: inherit;
      cursor: pointer;

      & input {
        display: none;
      }
    }

    & small {
      display: inline-block;
      margin-top: 5px;
      color: #242424;
    }

    & .loader-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;
      display: inline-block;
    }
  }

  & .description {
    vertical-align: middle;
    line-height: 21px;
    display: inline-block;
  }

  & .remove {
    position: absolute;
    top: 10px;
    right: 10px;
    line-height: 21px;
    text-transform: uppercase;
    color: $danger-color;
    width: 21px;
    height: 21px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
