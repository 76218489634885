import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo } = {}) => {
  const ordersListColumns = computed(() => [
    {
      key: "ordered_at",
      dataIndex: "ordered_at",
      title: i18n.t("orderedDateShort"),
      scopedSlots: { customRender: "dateRenderer" },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "ordered_at" && sortedInfo?.value?.order,
      width: 110,
      fixed: "left"
    },
    {
      key: "updated_at",
      dataIndex: "updated_at",
      title: i18n.t("updatedAtShort"),
      scopedSlots: { customRender: "dateRenderer" },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "updated_at" && sortedInfo?.value?.order,
      width: 110,
      fixed: "left"
    },
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("orderCode"),
      width: 120
    },
    {
      key: "seller__name",
      dataIndex: "seller__name",
      title: i18n.t("sellerAndSlug"),
      scopedSlots: { customRender: "sellerRenderer" }
    },
    {
      key: "customer",
      dataIndex: "customer",
      title: i18n.t("customer"),
      scopedSlots: { customRender: "customerRenderer" }
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: `${i18n.t("countShorted")}, ${i18n.t("pieces")}`,
      width: 130,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "quantity" && sortedInfo?.value?.order
    },
    {
      key: "promo_code_discount_total",
      dataIndex: "promo_code_discount_total",
      title: `${i18n.t("promoCodeTitle")}, ${i18n.t("uah")}`,
      width: 150,
      // sorter: true,
      // sortOrder: sortedInfo?.value?.columnKey === "promo_code_discount_total" && sortedInfo?.value?.order
    },
    {
      key: "bonus_discount_total",
      dataIndex: "bonus_discount_total",
      title: `${i18n.t("bonusTitle")}, ${i18n.t("uah")}`,
      width: 130,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "bonus_discount_total" && sortedInfo?.value?.order
    },
    {
      key: "price_total",
      dataIndex: "price_total",
      title: `${i18n.t("sum")}, ${i18n.t("uah")}`,
      width: 120,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "price_total" && sortedInfo?.value?.order
    },
    {
      key: "status",
      dataIndex: "status",
      title: i18n.t("orderStatus"),
      width: 180,
      scopedSlots: { customRender: "statusRenderer" }
    },
    {
      key: "ttn",
      dataIndex: "ttn",
      title: i18n.t("ttn"),
      scopedSlots: { customRender: "ttnRenderer" },
      width: 180
    },
    {
      key: "payment_status",
      dataIndex: "payment_status",
      title: i18n.t("paymentStatus"),
      width: 180,
      scopedSlots: { customRender: "paymentRenderer" }
    },
    {
      key: "reject_reason",
      dataIndex: "reject_reason",
      title: i18n.t("rejectReason"),
      width: 180,
      customRender: (data) => data?.reason.uk
    },
    {
      dataIndex: "actions",
      scopedSlots: { customRender: "actions" },
      fixed: "right"
    }
  ])

  return {
    ordersListColumns
  }
}
