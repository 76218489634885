import { API } from "@/utils/HttpUtils"
import i18n from "@/i18n"
import { getFormatedDate } from "@/utils/getFormatedDateTime"
import { fetchAttributes } from "@/modules/Moderation/services/attributesService"

let interval = null

export const getCategoriesTree = () => {
  return API.get("mp-admin/categories/tree")
}

export const searchCategoryByName = (name) => {
  return API.get(`/mp-admin/categories?search=${name}`)
}

export const fetchCategories = () => {
  return API.get("/mp-admin/categories?parent=null")
}

export const fetchChildrenCategories = (parent) => {
  return API.get(`/mp-admin/categories?parent=${parent}`)
}

export const fetchCategoryById = (categoryId) => {
  return API.get(`/mp-admin/categories/${categoryId}`)
}

export const reindexCategory = (categoryId) => {
  return API.post(`/mp-admin/categories/${categoryId}/reindex`)
}

export const fetchFilterById = (categoryId, filterId) => {
  return API.get(`/mp-admin/categories/${categoryId}/filters/${filterId}`)
}

export const updateFilterById = (data, categoryId, filterId) => {
  return API.patch(`/mp-admin/categories/${categoryId}/filters/${filterId}`, data)
}

export const fetchOffersMissingFilter = (category_pk, id) => {
  return API.get(`/mp-admin/categories/${category_pk}/filters/${id}/other/calculate`)
}

export const fetchFilterTaskReport = (categoryData, filterData) => {
  API.get(`/mp-admin/categories/${categoryData.id}/filters/${filterData.id}/other/download`).then(
    ({ data }) => {
      fetchFilterReport(data.task_uuid, categoryData, filterData)
    }
  )
}

export const fetchFilterReport = async (task_uuid, categoryData, filterData) => {
  const responseTask = await API.get(
    `/mp-admin/categories/${categoryData.id}/filters/${filterData.id}/other/download/${task_uuid}`
  )
  if (responseTask.data.url) {
    clearTimeout(interval)
    interval = null

    const xmlResponse = new XMLHttpRequest()
    xmlResponse.open("GET", responseTask.data.url)
    xmlResponse.responseType = "blob"
    xmlResponse.onload = (e) => {
      const blob = new Blob([xmlResponse.response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      const link = document.createElement("a")

      link.href = URL.createObjectURL(blob)
      link.setAttribute(
        "download",
        `${categoryData.name.uk}, ${i18n.t("notInFilter").toLowerCase()} ${
          filterData.name.uk
        } ${getFormatedDate()}.xlsx`
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    xmlResponse.send()
  } else {
    interval = setTimeout(() => {
      fetchFilterReport(task_uuid, categoryData, filterData)
    }, 300)
  }
}

export const updateCategoryById = (formData, id) => {
  return API[id ? "put" : "post"](`/mp-admin/categories/${id || ""}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}

export const updateCategoryOrdering = (data, id) => {
  return API.patch(`/mp-admin/categories/${id}`, data)
}

export const updateCategoryFilters = (id, filters) => {
  return API.patch(`/mp-admin/categories/${id}/filters/bulk`, filters)
}

export const deleteCategoryById = (id) => {
  return API.delete(`/mp-admin/categories/${id}`)
}

export const deleteFilterById = (categoryId, filterId) => {
  return API.delete(`/mp-admin/categories/${categoryId}/filters/${filterId}`)
}

export const calculateCustomRangeOffers = (category_pk, id) => {
  return API.get(`/mp-admin/categories/${category_pk}/filters/${id}/values/count`)
}

export const fetchCustomRangeFilterBaseUnit = (value) => {
  return fetchAttributes({ queryParams: { public_name: value, value_type: "NUMERIC" } })
}
