<template>
  <div class="general-settings">
    <PageHeader :title="$t('generalSettings')" />

    <a-collapse
      :bordered="false"
      style="background-color: transparent"
    >
      <a-collapse-panel
        key="labels"
        class="collapse-panel"
        :header="$t('labelSettings')"
      >
        <Labels />
      </a-collapse-panel>

      <a-collapse-panel
        key="offer-labels"
        class="collapse-panel"
        :header="$t('offersLabelSettings')"
      >
        <OfferLabels />
      </a-collapse-panel>

      <a-collapse-panel
        key="cancelReasons"
        class="collapse-panel"
        :header="$t('reasonRefuse')"
      >
        <CancellationReasons />
      </a-collapse-panel>

      <a-collapse-panel
        key="deliveryMethods"
        class="collapse-panel"
        :header="$t('deliveryMethods')"
      >
        <DeliveryMethods />
      </a-collapse-panel>

      <a-collapse-panel
        key="deliveryServices"
        class="collapse-panel"
        :header="$t('deliveryService')"
      >
        <DeliveryServices />
      </a-collapse-panel>

      <a-collapse-panel
        key="paymentMethods"
        class="collapse-panel"
        :header="$t('paymentMethods')"
      >
        <PaymentMethods />
      </a-collapse-panel>

      <a-collapse-panel
        key="returnReasons"
        class="collapse-panel"
        :header="$t('returnReasons')"
      >
        <ReturnsReasons />
      </a-collapse-panel>

      <a-collapse-panel
        key="billing"
        class="collapse-panel"
        :header="$t('tariffPrices')"
      >
        <Tariffs />
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script setup>
import PageHeader from "@/ant-components/PageHeader/index.js"

import Labels from "./Labels/index.js"
import OfferLabels from "./OfferLabels/index.js"
import DeliveryMethods from "./DeliveryMethods/index.js"
import DeliveryServices from "./DeliveryServices/index.js"
import PaymentMethods from "./PaymentMethods/index.js"
import ReturnsReasons from "./ReturnsReasons/index.js"
import Tariffs from "./Tariffs/index.js"
import CancellationReasons from "./CancellationReasons/index.js"
</script>

<style lang="scss" scoped>
.collapse-panel {
  border: none;
  margin-bottom: 8px;
  font-size: 20px;
}
</style>

<style lang="scss">
.collapse-panel {
  & .ant-collapse-header {
    padding-left: 24px !important;
  }

  & .ant-collapse-arrow {
    left: 0 !important;
  }

  & .ant-collapse-content-box {
    padding: 0;
  }
}
</style>
