import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const columns = computed(() => [
    {
      title: "",
      dataIndex: "handle",
      scopedSlots: {
        customRender: "handleRenderer"
      },
      width: 55
    },
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("attributeCode"),
      width: 220
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("attributeName")
    },
    {
      key: "value_count",
      dataIndex: "value_count",
      title: i18n.t("valuesCountInAttribute")
    },
    {
      key: "is_main",
      dataIndex: "is_main",
      scopedSlots: {
        title: "isMainTitle",
        customRender: "isMainRenderer"
      },
      align: "center",
      width: 160
    },
    {
      key: "is_required",
      dataIndex: "is_required",
      scopedSlots: {
        title: "isRequiredTitle",
        customRender: "isRequiredRenderer"
      },
      align: "center",
      width: 160
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      width: 110
    }
  ])

  return { columns }
}
