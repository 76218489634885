<template>
  <div class="login-wrapper">
    <a-card class="login-wrapper__card">
      <template #title>
        <div class="login-wrapper__card__title">
          <h1>{{ $t("synthetic") }}</h1>
          <h2>{{ $t("authorization") }}</h2>
        </div>
      </template>

      <a-form-model
        ref="formRef"
        :model="formModel"
        :rules="loginRules"
        hideRequiredMark
        @submit.prevent="handleLogin()"
      >
        <div>
          <a-form-model-item
            prop="username"
            :label="$t('login')"
          >
            <a-input
              v-model="formModel.username"
              size="large"
            />
          </a-form-model-item>

          <a-form-model-item
            prop="password"
            :label="$t('password')"
          >
            <a-input-password
              v-model="formModel.password"
              size="large"
            />
          </a-form-model-item>

          <a-form-item>
            <a-button
              type="primary"
              size="large"
              block
              :loading="loginRequest || isSubmiting"
              htmlType="submit"
            >
              {{ $t("signIn") }}
            </a-button>
          </a-form-item>
        </div>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import i18n from "@/i18n"
import { computed, reactive, ref } from "vue"
import { mapActions, mapState } from "vuex"

export default {
  name: "Login",
  setup() {
    const formRef = ref()

    const formModel = reactive({
      username: "",
      password: ""
    })
    const loginRules = {
      username: [{ required: true, message: i18n.t("emptyError") }],
      password: [{ required: true, message: i18n.t("emptyError") }]
    }

    const isSubmiting = ref(false)

    const isValid = computed(() => {
      const { username, password } = formModel
      return username.length > 0 && password.length > 0
    })

    return {
      formRef,
      formModel,
      loginRules,

      isSubmiting,

      isValid
    }
  },

  computed: {
    ...mapState("Auth", {
      isLogged: (state) => state.isLogged,
      loginRequest: (state) => state.loginRequest
    })
  },

  mounted() {
    if (this.isLogged) {
      this.$router.push({ path: "/mp-admin/categories/" })
    }
  },

  methods: {
    ...mapActions("Auth", ["login"]),

    async handleLogin() {
      this.isSubmiting = true
      try {
        await this.formRef.validate()

        await this.login(this.formModel)
      } catch (error) {
        this.$message.error(this.$t("wrongLoginOrPassword"))
      } finally {
        this.isSubmiting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrapper {
  height: calc(100vh - 64px - 30px - 40px);
  display: flex;
  justify-content: center;
  align-items: center;

  &__card {
    width: 100%;
    max-width: 400px;
    height: fit-content;
    box-shadow: 4px 4px 16px $border-color;

    &__title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
    }
  }
}
</style>
